export const translationsFrench = {
    all: 'Tous',
    ago: "depuis",
    now: "À présent",
    start: "Début",
    account_blocked: "Compte bloqué",
    account_blocked_alert: "Ce compte a été bloqué pour violation de nos conditions générales",
    sign_out: "se déconnecter",
    short_answer: "Réponse courte",
    long_answer: "Longue réponse",
    email: "Courriel",
    number: "Numéro",
    file: "fichier",
    files: "Fichiers",
    faq_videos: "FAQ Vidéos",
    file_upload: "Téléverser un fichier",
    question_error: "La question ne peut pas être ajoutée",
    storage: "Stockage",
    form: "Forme",
    quiz: "Questionnaire",
    fup_desc: "Vous pouvez désormais autoriser les répondants à vos formulaires et questionnaires à télécharger des fichiers !\n\nSélectionnez le type de question de téléchargement de fichier pour commencer.",
    new_fup_feature_title: "Nouvelle fonctionnalité de téléchargement de fichiers !",
    multiple_choice: "Choix multiple",
    date_text: "Date",
    time_text: "Heure",
    uploading: "Téléchargement…",
    add_question: "Ajouter une question",
    add_question_alert: "Veuillez ajouter au moins une question avec tous les éléments requis.",
    add_question_alert_quiz: "Veuillez ajouter au moins une question avant de soumettre le quiz",
    ok: "D\'accord",
    title: "Titre",
    title_required: "Le titre est requis",
    form_name_alert: "Veuillez nommer le formulaire avant de le publier.",
    quiz_name_alert: "Veuillez nommer le quiz avant de le publier",
    no_connection: "Pas de connexion",
    check_connection: "Veuillez vérifier votre connexion réseau.",
    finish_incomplete_question: "Veuillez terminer les questions incomplètes dans le formulaire",
    draft: "Brouillon",
    save_to_draft_alert: "Voulez-vous sauvegarder ce formulaire en tant que brouillon ?",
    save_to_draft_quiz_alert: "Souhaitez-vous enregistrer ce quiz en tant que brouillon pour éviter de perdre vos modifications?",
    yes: "Oui",
    no: "Non",
    cancel: "Annuler",
    live_preview: "Prévisualisation en direct",
    themes: "Thèmes",
    tap_to_set_theme: "Appuyez ici pour définir le thème du formulaire.",
    select_image: "Sélectionnez l\'image",
    created: "Créé",
    form_created_successfully: "Formulaire créé avec succès",
    warning: "Avertissement",
    form_edit_alert: "Ce formulaire a déjà été publié et des réponses ont peut-être été recueillies. La modification du formulaire peut affecter les réponses déjà recueillies.",
    quiz_edit_alert: 'Ce quiz a déjà été soumis et les réponses ont été collectées. La modification du quiz peut affecter les réponses précédentes',
    edit: "Modifier",
    download_as: "Télécharger",
    delete_form: "Supprimer le formulaire",
    delete_form_alert: "Voulez-vous supprimer ce formulaire ? Cette action ne peut pas être annulée.",
    delete_quizzes_alert: "Voulez-vous supprimer ces quiz ? Cette action ne peut pas être annulée.",
    delete_forms_alert: "Voulez-vous supprimer ces formulaires? Cette action ne peut pas être annulée.",
    delete_quiz_alert: "Voulez-vous supprimer ce quiz? Cette action ne peut pas être annulée.",
    delete_answers_alert: "Voulez-vous supprimer ces réponses ?",
    delete_answer_alert: "Voulez-vous supprimer cette réponse ?",
    delete: "Supprimer",
    delete_all: "Supprimer tout",
    section: "section",
    worst: "Pire",
    server_error_alert: "Le serveur est introuvable. Veuillez réessayer après un certain temps !!",
    signed_in_as: "Connecté en tant que",
    signing_out: "Déconnecter…",
    create_form: "Créer un formulaire",
    create_blank_form: "Créer un formulaire vierge",
    create_blank_quiz: "Créer un quiz vierge",
    close: "Fermer",
    response_collection_exit_alert: "Voulez-vous quitter la collecte des réponses ?",
    form_builder: "Générateur de formulaire",
    form_builder_promo: "Prévisualisation instantanée, modèles et thèmes pour vos enquêtes",
    results: "Résultats",
    result_feature_promo: "Analysez et exportez les résultats de votre enquête",
    notification: "Notification",
    delete_option_alert: "Voulez-vous supprimer cette option ?  [Remarque : cette action ne peut pas être annulée]",
    question_type: "Type de question",
    change_question_type_alert: "Appuyez ici pour modifier le type de question",
    preview_not_available: "Prévisualisation non disponible",
    delete_question: "Supprimer la question",
    delete_response_collected_question_alert: "La suppression de cette question affectera sûrement les réponses recueillies.\n\nVoulez-vous vraiment supprimer cette question ?",
    delete_question_alert: "Voulez-vous vraiment supprimer cette question ?",
    options: "Options",
    option: 'Option',
    no_answer: "Pas de réponse",
    no_response: "Pas de réponse",
    responses: "réponses",
    response: "réponse",
    serial_number: "S.NO",
    submit_time: "Soumettre l\'heure",
    general_form_type_description: "Créer un nouveau formulaire / sondage",
    quiz_type_description: "Créer un nouveau quiz",
    sheet_disconnected: 'Feuille Google déconnectée',
    sheet_not_connected: 'Feuille non connectée',
    sheet_not_connected_desc: 'Veuillez contacter le propriétaire pour connecter la feuille Google.',
    export_success: "Exporté avec succès",
    answer: "Répondre",
    answer_with_colan: "Répondre :",
    others: "Autres",
    delete_response: "Supprimer la réponse",
    deleted: "Effacé",
    my_themes: "Mes thèmes",
    limit_reached: "Limite atteinte",
    limit_reached_description: "Vous avez atteint la limite de téléchargement d'images.",
    modified: "Modifié",
    delete_image: "Supprimer l\'image",
    delete_image_alert: "Voulez-vous vraiment supprimer cette image ?",
    delete_theme_image_desc: "Voulez-vous supprimer cette image ? Si vous continuez, cette image ne sera pas disponible pour les formulaires ou les questionnaires qui l'utilisent. Cette action ne peut pas être annulée.",
    section_title: "Titre de la section",
    section_description: "Description de la section",
    show_scale: 'Afficher l\'échelle',
    show_options: "Afficher les options",
    options_required: "Options requises",
    link_copied: "Lien copié",
    app_not_found: "Application introuvable",
    default_text: "Par défaut",
    dark_mode: "Mode sombre",
    done: "Terminé",
    upload_image: "Télécharger l\'image",
    question: "Question",
    required: "Requis",
    share: "Partager",
    form_link: "Lien de formulaire",
    copy_link: "Copier le lien",
    view_form: "Afficher le formulaire",
    menu: "Menu",
    view_all: "Tout",
    settings: "Paramètres",
    submit: "Soumettre",
    change_theme: "Changer le thème",
    tap_here_to_choose_theme: "Appuyer ici pour choisir le thème",
    show_logo: "Afficher le logo",
    show_logo_alert: "Voulez-vous afficher le logo dans le formulaire ?",
    welcome_page: "Page d\'accueil",
    show_welcome_page_alert: "Voulez-vous afficher la page d\'accueil ?",
    start_button: "Bouton Start",
    allow_multiple_responses: "Autoriser les réponses multiples",
    allow_more_response_alert: "Voulez-vous permettre à l\'utilisateur de répondre plus d\'une fois ?",
    allow_more_quiz_attempt_alert: "Voulez-vous autoriser l\'utilisateur à essayer plusieurs fois?",
    show_summary: "Afficher le résumé",
    show_results: "Montrer les résultats",
    allow_responders_view_summary_alert: "Voulez-vous permettre aux répondants de voir le résumé de toutes les réponses après la soumission ?",
    allow_result_view_quiz_alert: "Voulez-vous autoriser l\'utilisateur à afficher les résultats?",
    show_question_number: "Afficher le numéro de la question",
    show_question_number_in_form: "Voulez-vous afficher les numéros des questions sur le formulaire?",
    show_question_number_in_quiz: "Voulez-vous afficher les numéros des questions sur le quiz?",
    shuffle_questions: "Mélanger les questions",
    shuffle_questions_alert: "Voulez-vous mélanger les questions du formulaire ?",
    shuffle_quiz_questions_alert: "Voulez-vous mélanger les questions du quiz?",
    description: "La description",
    builder: "Générateur",
    preview: "Prévisualisation",
    form_status: "État du formulaire",
    active: "Actif",
    inactive: "Inactif",
    import_questions: "Questions d'importation",
    search_by_question_title_from_forms: 'Rechercher par titre de question à partir de formulaires existants',
    search_by_question_title_from_quizzes: 'Rechercher par titre de question à partir de quiz existants',
    view_responses: "Afficher les réponses",
    edit_form: "Modifier le formulaire",
    duplicate_form: "Formulaire en double",
    drafts: "Brouillons",
    no_form_available: "Aucun formulaire disponible",
    notifications: "Notifications",
    feedback: "Retour d\'information",
    privacy_policy: "Politique de confidentialité",
    open: 'Ouvert',
    open_individual_responses: "Réponses individuelles ouvertes",
    bar_chart: "GRAPHIQUE À BARRES",
    pie_chart: "GRAPHIQUE CIRCULAIRE",
    summary: "Résumé",
    invalidated_form_cannot_be_selected: "Un formulaire invalidé ne peut pas être sélectionné.",
    invalidated_quiz_cannot_be_selected: "Un quiz invalidé ne peut pas être sélectionné.",
    individual: "Individuel",
    search: "chercher…",
    image: "Image",
    video: "Vidéo",
    website: "Site Web",
    exit: "Sortie",
    response_limit_reached: "Limite de réponse atteinte",
    response_limit_reached_alert: "Ce formulaire a dépassé le seuil autorisé pour les données de réponse; par conséquent, nous avons empêché ce formulaire d\'accepter d\'autres réponses.\n\nSi vous avez vraiment besoin de poursuivre l\'enquête existante, nous vous suggérons de la dupliquer et de partager le lien.",
    unapproved_form: "Formulaire non approuvé",
    unapproved_form_alert: "Ce formulaire utilise des mots qui peuvent enfreindre nos politiques. \n\nSi vous pensez que ce formulaire a été bloqué par erreur, veuillez ouvrir le lien du formulaire ci-dessous et fournir les informations demandées dans le formulaire.",
    choose_your_language: "Choisissez votre langue",
    provide_explanation: "Fournir une explication",
    selected: "choisi",
    review_button: "La revue",
    not_specified: "Non précisé",
    quiz_control: "Contrôle du quiz",
    allow_multiple_attempts: "Autoriser plusieurs tentatives",
    user_info: "informations utilisateur",
    name_s: "Nom",
    duration: "Durée",
    set_duration_quiz_alert: "Voulez-vous définir une durée pour le quiz?",
    user_information_description: "Voulez-vous obtenir des informations sur les participants?",
    set_duration: "Définir la durée (heures: minutes)",
    minutes: "Minutes",
    hours: "Heures",
    minute: "Minute",
    hour: "Heure",
    correct: "Correct",
    wrong: "Faux",
    skipped: "Ignoré",
    score: "score",
    forms: "Formes",
    quizzes: "Quiz",
    more: "Plus",
    quiz_status: "Statut du quiz",
    view_answers: "Voir les réponses",
    view_quiz: "Voir le quiz",
    duplicate_quiz: "Dupliquer le quiz",
    no_quiz_available: "Aucun questionnaire disponible!",
    quiz_link: "Lien du quiz",
    unapproved_quiz: "Quiz non approuvé",
    unapproved_quiz_alert: "Ce quiz utilise des mots qui peuvent enfreindre nos politiques. Si vous pensez que ce quiz a été bloqué par erreur, veuillez ouvrir le lien du formulaire ci-dessous et fournir les informations demandées dans le formulaire.",
    do_you_want_to_show_all_correct_answers_to_users: "Voulez-vous montrer la bonne réponse aux utilisateurs?",
    disconnect: 'DÉCONNECTER',
    results_publish_date: "Date de publication des résultats",
    do_you_want_to_set_date_to_publish_results: "Voulez-vous définir la date de publication des résultats",
    quiz_result: "Résultat du quiz",
    enter_answer: "Répondre",
    manage: "Gérer",
    manage_storage: 'Gérer un espace de rangement',
    select_all: 'Tout sélectionner',
    shuffle_options: 'Options de lecture aléatoire',
    shuffle_options_enabled: 'Options de lecture aléatoire activées',
    shuffle_options_disabled: 'Options de lecture aléatoire désactivées',
    add_options_to_enable_this_feature: 'Ajouter des options pour activer cette fonctionnalité',
    attach: 'Attacher',
    shuffle: 'Mélanger',
    marks: "Marques",
    mark: "Marquer",
    correction_status: "Statut:",
    status_validated: "Validé",
    pending: "En attente",
    results_publish: "Publier",
    publish: "Publier",
    publish_description: "Voulez-vous publier les résultats du quiz?",
    publish_description_note: "(Les marques seront publiées dans la même URL)",
    published_successfully: "Publié avec succès",
    choice_type_validation_note: "*Choix multiple validé automatiquement",
    short_answer_type_validation_note: "*Réponse courte validée automatiquement",
    long_answer_type_validation_note: "*Réponse longue NON validée automatiquement",
    fileupload_answer_type_validation_note: "*Téléchargement de fichier NON validé automatiquement",
    dropdown_answer_type_validation_note: "*Choix du menu déroulant validé automatiquement",
    save: "enregistrer",
    questions: "Des questions",
    show_correct_answer: "Afficher la bonne réponse",
    show_answer: "Montrer la réponse",
    hide_answer: "Masquer la réponse",
    no_marks: "Pas de marque",
    question_is_required: "Le titre de la question est requis",
    marks_is_required: "Des marques sont requises",
    options_are_required: "Des options sont requises",
    options_limit_reached: "limite d'options atteinte",
    options_limit_reached_description: "Vous avez atteint la limite du nombre d'options autorisées pour une question.",
    correct_answer_required: "Une réponse correcte est requise",
    enter_valid_mark: "Entrez une marque valide",
    evaluated: "Évalué",
    saved: "Enregistré",
    pending_evaluation: "Évaluation en attente",
    alphabet: "Alphabet",
    not_evaluated: "Non évalué",
    percentage_ascending: "Pourcentage - Croissant",
    percentage_descending: "Pourcentage - Décroissant",
    pending_evaluation_alert_message: "Vous devez terminer l\'évaluation de toutes les réponses avant de publier.",
    show_unevaluated: "Afficher non évalué",
    publish_success_alert_message: "Les répondants peuvent voir leurs résultats dans leurs feuilles de réponses correspondantes.",
    publishing: "Édition",
    quiz_published: "Quiz publié",
    quiz_published_alert_before_edit_message: "Ce quiz a été publié, si vous devez apporter des modifications, vous devez dupliquer le quiz et le partager à nouveau.",
    quiz_has_answers: "Le quiz a des réponses",
    quiz_has_answer_alert_message: "Pour modifier ce quiz, vous devez supprimer toutes les réponses existantes.",
    unavailable_features_description: "Ce formulaire comporte des fonctionnalités qui ne sont pas encore prises en charge dans l'application Web.",
    unavailable_quiz_features_description: "Ce quiz comporte des fonctionnalités qui ne sont pas encore prises en charge dans la Web App",
    unavailable: 'Indisponible',
    unpublished: "Non publié",
    published: "Publié",
    setting_updated: "Paramètre mis à jour",
    account: "Compte",
    on: "SUR",
    off: "DE",
    scan_qr_code: "Scanner le code QR",
    answers: "réponses",
    answer_s: "répondre",
    question_limit_reached: "Limite de questions atteinte",
    reached_limit_for_number_of_questions: "Vous avez atteint la limite du nombre de questions autorisées sur un formulaire. Par conséquent, aucune nouvelle question ne peut être ajoutée",
    life_time: "Durée de vie",
    last_year: "L\'année dernière",
    last_30_days: "Les 30 derniers jours",
    last_7_days: "Les 7 derniers jours",
    last_24_hours: "Dernières 24 heures",
    custom_date: "Date personnalisée",
    filter_by_date: "Filtrer par date",
    filter_by_question: "Filtrer par question",
    filter_text: "Filtrer le texte",
    add_filter: "Ajouter un filtre",
    apply: "Appliquer",
    from_date: "Partir de la date",
    today: "Aujourd'hui",
    to_date: "À ce jour",
    contains: "Contient",
    equal_to: "Égal à",
    not_equal_to: "Pas égal à",
    from_date_required: "Du Date Obligatoire",
    to_date_required: "À ce jour Obligatoire",
    webapp_promo_text: "Accédez à vos formulaires, sondages, sondages et quiz en ligne !",
    question_title: "Question",
    invalid_date: "Date invalide",
    invalid_url: "URL invalide",
    invalid_url_description: "L'URL ne semble pas valide.",
    delete_response_alert: "Voulez-vous vraiment supprimer cette réponse ?",
    delete_responses_alert: "Voulez-vous supprimer ces réponses ?",
    overview: "Aperçu",
    no_results_found: "Aucun résultat trouvé",
    date_format: "dd-mm-yyyy",
    title_dashboard: "Tableau de bord",
    title_notifications: "Notifications",
    select_one_response: "Veuillez sélectionner au moins une réponse à supprimer",
    your_account: "Votre compte",
    app_name: "SurveyHeart",
    favourites: "Favoris",
    quizzory: "Quizzory",
    powered_by_surveyheart: "Powered by 'SurveyHeart'",
    shared: "partagé",
    hide: "Cacher",
    use_templates: "Utiliser des modèles",
    quick_settings: "Réglages rapides",
    leave_collaboration: "Quitter la collaboration",
    templates: "Modèles",
    collapse: "Effondrement",
    start_scale_from_zero: 'Échelle de départ à partir de 0',
    center_label_optional: 'Étiquette centrale (facultatif)',
    left_label_optional: 'Étiquette de gauche (facultatif)',
    right_label_optional: 'Étiquette de droite (facultatif)',
    duplicate: "Dupliquer",
    row: "ligne",
    column: "Colonne",
    rows: "Lignes",
    add_column: 'Ajouter une Colonne',
    add_row: 'Ajouter une ligne',
    user_info_for_file_upload: "Informations utilisateur pour les téléchargements de fichiers",
    user_info_for_file_upload_desc1: "Depuis que vous avez ajouté une question de téléchargement de fichier, l'option de collecte d'informations utilisateur a été activée. Cela facilitera l’identification des fichiers téléchargés. Les répondants verront une boîte de dialogue pour saisir leurs informations.",
    user_info_for_file_upload_desc2: "Vous pouvez modifier cette option dans Paramètres si vous ne souhaitez pas que la boîte de dialogue s'affiche.",
    show_rows_and_columns: "Afficher les lignes & Colonne",
    scale_down: 'Réduire',
    scale_up: 'Mise à l’échelle',
    dislike: 'Détester',
    like: 'Comme',
    best: 'Meilleure',
    remove_favourite: "Supprimer le favori",
    add_favourite: "Ajouter un favori",
    collaborator: "Collaborateur",
    collaborators: "Collaborateurs",
    show_more: "Montre plus",
    create_quiz: "Créer un questionnaire",
    collaborate: "Collaborer",
    share_QR_code: "Partager le code QR",
    add_image: "Ajouter une image",
    invalid_file_type: "Type de fichier invalide",
    drag_drop: "Glisser-déposer",
    here: "Ici",
    choose: "Choisir",
    add_account: 'Ajouter un compte',
    choose_account: 'Choisissez un compte',
    maximum_allowed_file_size: "La taille de fichier maximale autorisée est ",
    or: "OU",
    next: "Prochain",
    change_image: "Changer l'image",
    upload: "Télécharger",
    view: "Voir",
    collaboration: "Collaboration",
    share_collaboration_form: "Voulez-vous partager ce formulaire avec d'autres?",
    share_collaboration_quiz: "Voulez-vous partager ce quiz avec d'autres?",
    multiple_responses_summary: "Réponses multiples, résumé",
    question_number_shuffle: "Numéro de question, Point d'interrogation, Aléatoire",
    general: "Général",
    browse: "Parcourir",
    owner: "Propriétaire",
    something_went_wrong: "Quelque chose s'est mal passé !",
    forget_your_password: "Mot de passe oublié?",
    sign_in: "S'identifier",
    sign_up: "S'inscrire",
    using_email: "en utilisant e-mail",
    dont_have_account: "Vous n'avez pas de compte?",
    on_signing_up_agree_to_surveyhearts: "En vous inscrivant, vous acceptez de SurveyHeart's",
    and: "et",
    terms_of_use: "Conditions d'utilisation",
    already_have_an_account: "Vous avez déjà un compte?",
    signed_in_previously_as_a_google_user: "Vous vous êtes déjà connecté en tant qu'utilisateur Google",
    sign_in_google_to_continue: "Veuillez vous connecter via Google pour continuer.",
    no_account_found_email_address: "Aucun compte trouvé avec cette adresse e-mail",
    account_already_exist_email: "Le compte existe déjà pour cet e-mail.",
    install_surveyheart_app: " Installer SurveyHeart Application",
    easy_login_via_app: "Connexion facile via l'application",
    get_notification_instantly: "Recevez une notification instantanément",
    create_form_quiz_on_the_go: "Créer un formulaire/quiz en déplacement",
    check_your_inbox_to_activate_your_account: "Veuillez vérifier votre boîte de réception pour activer votre compte",
    select_questions: "Sélectionnez les questions",
    percentage: "Pourcentage",
    free_App_to_create_forms: "Obtenez l'application gratuite pour créer des formulaires",
    we_cant_find_the_page_that_you_are_looking_for: "Nous ne trouvons pas la page que vous recherchez",
    answered: "Répondu",
    completing_this_quiz: "Merci d'avoir répondu à ce quiz",
    connected_successfully: 'Connexion réussie',
    seconds: "Seconds",
    enter_your_details: "Entrez vos détails", //
    enter_all_required_information: "Por favor introduce toda la información requerida",
    answer_data_not_found: "Datos de respuesta no encontrados",
    results_published_soon: "Los resultados se publicarán pronto",
    previous: 'précédente',
    save_link_for_future_reference: "Guarde este enlace para referencia futura",
    choice_questions_only: "Solo preguntas de elección",
    score_is_not_final: "Nota: Esta puntuación no es definitiva",
    tabular: "Tabulaire",
    text: "texto",
    answer_response_alert: "Esta pregunta requiere una respuesta",
    invalid_email_address: "Dirección de correo electrónico no válida",
    answer_not_available: "Respuesta no disponible",
    validated_automatically: "* validado automáticamente",
    answer_link: "Enlace de respuesta",
    embed_code: 'Code intégré',
    copy_code: 'Copier le code',
    code_copied: 'Code copié',
    share_via: "Compartir via",
    print: "Impresión",
    columns: 'Columnas',
    columns_all: "Columnas(Todos)",
    submitted_time: "Hora de envío",
    unable_to_submit: "Impossible de soumettre",
    unable_to_submit_alert_line1: "Étant donné que ce quiz a reçu des réponses, vous disposez d'un accès limité pour apporter des modifications.",
    unable_to_submit_alert_line2: "Afin d'obtenir un accès complet pour modifier ce quiz, vous devez supprimer toutes les réponses existantes.",
    action_blocked: 'Action bloquée',
    action_blocked_alert: 'Action bloquée en raison de réponses existantes à ce quiz. Autoriser cela pourrait avoir un impact sur les réponses précédentes.',
    quiz_edit_submit_alert: 'La modification du quiz peut affecter les notes, le pourcentage et nécessiter une réévaluation de certaines questions.',
    continue: 'Continuer',
    submitted: "Presentada",
    submitting: "Sumisión",
    filter: "Filtrar",
    exit_screen: "Pantalla de salida",
    full_screen: "Pantalla completa",
    tabular_view: "Vista tabular",
    clear_all: "Limpiar todo",
    you_are_all_caught_up: "¡Estáis todos atrapados!",
    check_back_later_for_new_notifications: "Vuelve más tarde para ver nuevas notificaciones.",
    requires_answer: "requiere respuesta",
    enter_member_id: "Ingrese la identificación del miembro",
    viewers_can_read_form_responses: "Los espectadores pueden leer el formulario y ver las respuestas.",
    editors_edit_form_view_responses: "Los editores pueden editar el formulario y ver las respuestas.",
    admin_edit_form_add_collaborator: "L'administrateur peut modifier le formulaire et ajouter un collaborateur",
    invalid_member_id: "ID invalide",
    already_member_added: "Déjà ce membre ajouté",
    add_collaborator: "Ajouter un collaborateur",
    admin: "Administrateur",
    editor: "Éditeur",
    viewer: "Téléspectateur",
    searched_form_not_found: "Formulaire recherché introuvable!",
    searched_quiz_not_found: "Quiz recherché introuvable !",
    no_shared_data_available: "Aucune donnée partagée disponible!",
    forms_server_is_not_available: "Le serveur de formulaires n'est pas disponible",
    quiz_server_is_not_available: "Le serveur de quiz n'est pas disponible", //
    leave: 'Pars',
    password: 'Mot de passe',
    new_password: 'nouveau mot de passe',
    retype_password: 'retaper le mot de passe',
    create_password: 'Créer un mot de passe',
    view_results: 'Voir les résultats',
    control: 'Contrôler',
    q_control: 'Q.Control',  //
    q_result: 'Q.Result',  //
    no_matches_found: "Aucun résultat",
    dropdown: "Menu déroulant",
    checkboxes: "Cases à cocher",
    star_rating: 'Évaluation étoilée',
    linear_scale: 'Échelle linéaire',
    multiple_choice_grid: 'Grille à choix multiples',
    checkboxes_grid: 'Grille de cases à cocher',
    smile: 'Sourire',
    info_1: "Infos 1",
    info_2: "Infos 2",
    info_3: "Infos 3",
    new: 'Nouveau',
    download_all: 'Tout télécharger',
    download: 'Télécharger',
    sort_by: 'Trier par',
    sorting: 'Tri',
    refresh: 'rafraîchir',
    add: 'ajouter',
    refresh_preview_description: "Cliquez sur le bouton « Actualiser » pour mettre à jour l'aperçu",
    enter_users_email_address: 'Entrer l\'adresse e-mail',
    do_you_want_to_delete_these: 'Voulez-vous supprimer ces',
    do_you_want_to_delete_this_form: 'Voulez-vous supprimer ce formulaire',
    all_responses_will_also_be_deleted: 'Toutes les réponses seront également supprimées',
    this_action_cannot_be_undone: 'Cette action ne peut pas être annulée.',
    do_you_want_to_delete_this_quiz: 'Voulez-vous supprimer ce questionnaire',
    all_answer_sheets_will_also_be_unavailable: 'Toutes les feuilles de réponses seront également indisponibles',
    change: 'Changer',
    roles_rights: 'Rôles et droits',
    learn_more: 'Apprendre encore plus',
    viewers_can_read_quiz_and_view_answers: 'Les téléspectateurs peuvent lire le quiz et voir les réponses',
    editors_can_edit_quiz_view_answers: 'Les éditeurs peuvent modifier le quiz et afficher les réponses',
    admin_can_edit_quiz_add_collaborator: ' L\'administrateur peut modifier le quiz et ajouter un collaborateur',
    change_roles: 'Changer les rôles',
    remove: 'retirer',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: 'Vous n\'avez pas soumis vos modifications. Est-il acceptable de partir sans soumettre ?',
    select_language: 'Choisir la langue',
    are_you_sure_you_want_delete_this_collaborator: 'Voulez-vous vraiment supprimer ce collaborateur ?',
    are_you_sure_you_want_to_delete_selected_files: 'Êtes-vous sûr de vouloir supprimer définitivement le fichier sélectionné ?',
    please_download_before_deleting: 'Veuillez télécharger avant de supprimer. Les fichiers une fois supprimés ne peuvent pas être récupérés !',
    role_has_been_changed_successfully: 'le rôle a été changé avec succès',
    do_you_want_to_leave_collaboration: 'Voulez-vous quitter la collaboration?',
    form_builder_access_denied: 'Accès au générateur de formulaires refusé',
    quiz_builder_access_denied: 'Accès à Quiz Builder refusé',
    you_only_have_viewer_rights_for_quiz: 'Vous n\'avez que les droits de visionnage pour ce quiz.',
    you_only_have_viewer_rights_for_form: 'Vous n\'avez que des droits de visualisation pour ce formulaire.',
    kindly_contact_the_form_owner_for_more_info: 'Veuillez contacter le propriétaire du formulaire pour plus d\'informations.',
    kindly_contact_the_quiz_owner_for_more_info: 'Veuillez contacter le propriétaire du quiz pour plus d\'informations.',
    incomplete_questions: 'Questions incomplètes :',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'Vous avez des modifications non enregistrées - êtes-vous sûr de vouloir quitter cette page?',
    languages: 'Langages',
    copy: "copie",
    not_good: 'pas bon',
    not_allowed: 'interdit',
    not_allowed_description: 'Le type de question à choix ne peut pas être modifié après la collecte des réponses.',
    neutral: 'neutre',
    good: 'bien',
    very_good: 'très bien',
    edit_rights_removed: 'Modifier les droits supprimés',
    your_rights_to_edit_this_form_have_been_removed: "Vos droits de modifier ce formulaire ont été supprimés.",
    your_rights_to_edit_this_quiz_have_been_removed: "Vos droits de modification de ce quiz ont été supprimés.",
    please_contact_quiz_owner_for_more_info: "Veuillez contacter le propriétaire du formulaire pour plus d'informations.",
    please_contact_form_owner_for_more_info: "Veuillez contacter le propriétaire du quiz pour plus d'informations.",
    last_edited_on: 'Dernière modification le',
    last_edited: 'Dernière modification',
    by: 'par',
    you: "Tu",
    add_option: 'Ajouter une option',
    add_other_option: "Ajouter l'option \"Autre\"",
    estimated_time: "temps estimé ",
    do_you_want_to_show_estimated_time_taking_quiz: "Voulez-vous afficher le temps estimé pour répondre au quiz ?",
    do_you_want_to_show_estimated_time_taking_form: "Voulez-vous afficher le temps estimé pour remplir le formulaire ?",
    show_question_count: " Afficher le nombre de questions",
    do_you_want_to_show_total_number_questions_quiz: "Voulez-vous afficher le nombre total de questions dans le quiz ?",
    do_you_want_to_show_total_number_questions_form: "Voulez-vous afficher le nombre total de questions dans le formulaire ?",
    show_question_marks: "Afficher les points d'interrogation",
    show_question_marks_quiz: "Afficher les points d'interrogation dans le quiz",
    you_dont_have_add_collaborator_permission: "Vous n'avez pas l'autorisation d'ajouter un collaborateur",
    modified_date: "Date modifiée",
    created_date: "Date de création",
    question_cant_be_added: "LA QUESTION NE PEUT PAS ÊTRE AJOUTÉE",
    another_file_upload_question_cant_added: "UNE AUTRE QUESTION DE TÉLÉCHARGEMENT DE FICHIER NE PEUT PAS ÊTRE AJOUTÉE",
    you_can_have_only: "Vous ne pouvez avoir que",
    file_upload_questions_in_form: "Importer des questions dans un formulaire",
    file_upload_questions_in_quiz: "Questions de téléchargement de fichiers dans un quiz",
    storage_capacity_exceeded: "Capacité de stockage dépassée",
    kindly_inform_form_builder_that: "Veuillez informer le Form Builder que",
    kindly_inform_quiz_builder_that: "Veuillez informer le Quiz Builder que",
    their_storage_capacity_file_uploads: "leur capacité de stockage pour les téléchargements de fichiers",
    has_been_exceeded: "a été dépassé",
    view_details: "VOIR LES DÉTAILS",
    paste_your_video_link_here: "Collez votre lien vidéo ici",
    paste_your_website_link_here: "Collez le lien de votre site Web ici",
    view_answer_sheet: "Afficher la feuille de réponses",
    response_count: "Nombre de réponses",
    count: "compter",
    password_least_8_characters: "Mot de passe doit être d'au moins 8 - 50 caractères",
    password_contain_least_1_number: "Le mot de passe doit contenir au moins 1 chiffre",
    password_contain_least_1_capital_letter: "Le mot de passe doit contenir au moins 1 lettre majuscule",
    password_contain_least_1_special_character: "Le mot de passe doit contenir au moins 1 caractère spécial",
    please_fill_all_required_fields: "Veuillez remplir tous les champs obligatoires",
    passwords_dont_match: "Les mots de passe ne correspondent pas",
    incorrect_password: "Mot de passe incorrect",
    click_or_change_current_theme: "Cliquez sur le thème actuel ou sur \"Modifier\" ci-dessous pour choisir un thème différent",
    select: "Sélectionner",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "Voulez-vous supprimer cette image ? Si vous traitez, cette image ne sera pas disponible avec les formulaires ou les questionnaires qui l'utilisent. Cette action ne peut pas être undani",
    url_does_not_seem_to_valid: "L'URL ne semble pas valide.",
    you_have_reached_image_uploaded_limit: "Vous avez atteint la limite de téléchargement d'images.",
    feature_spotlight: "Focus sur les fonctionnalités",
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: "Note : Pour revoir ces informations, cliquez sur 'Plus' dans le menu situé sur le côté gauche.",
    undo: "annuler",
    response_deleted: "Réponse supprimée",
    question_deleted: "Question supprimée",
    answer_deleted: "Réponse supprimée",
    responses_deleted: "Réponses supprimées",
    questions_deleted: "Questions supprimées",
    answers_deleted: "Réponses supprimées",
    collect_email_header: "RECUEILLIR L'E-MAIL",
    collect_email_alert_message: "L'activation de cette fonctionnalité forcera le répondant à se connecter avec son adresse Gmail pour remplir le formulaire.",
    collect_email_alert_note: "REMARQUE : seul le compte Google est pris en charge",
    allow_response_edit: "Autoriser la modification des réponses",
    allow_response_edit_description: "Voulez-vous autoriser les répondants à modifier les réponses après leur soumission ?",
    enter_desc_here: "Entrez la description ici",
    passcode_protection: "Protection par mot de passe",
    passcode_protection_description_quiz: "Voulez-vous que votre quiz soit protégé par mot de passe ?",
    passcode_protection_description: "Voulez-vous que votre formulaire soit protégé par mot de passe ?",
    crop_image: "Recadrer l'image",
    crop: "Recadrer",
    original: "Original",
    collect_email: "Collecter l'adresse e-mail",
    collect_email_description_quiz: "L'activation de cette fonctionnalité obligera les répondants à se connecter avec leur adresse Gmail pour remplir le questionnaire.",
    collect_email_description_form: "L'activation de cette fonctionnalité obligera les répondants à se connecter avec leur adresse Gmail pour remplir le formulaire.",
    collect_email_description: "Souhaitez-vous recueillir les adresses e-mail des répondants pour une éventuelle référence ou utilisation future ?",
    opening_date: "Date d'ouverture",
    closing_date: "Date de clôture",
    schedule_form_status: "Statut du formulaire de planification",
    schedule_quiz_status: "Programmer le statut du quiz",
    schedule_opening_date_and_time: "Programmer la date et l'heure d'ouverture",
    schedule_closing_date_and_time: "Programmer la date et l'heure de clôture",
    schedule_opening_date_and_time_description: "Activez le formulaire à la date et à l'heure mentionnées",
    schedule_opening_date_and_time_description_quiz: "Activez le quiz à la date et à l'heure mentionnées",
    schedule_closing_date_and_time_description: "Désactiver le formulaire à la date et à l'heure mentionnées",
    schedule_closing_date_and_time_description_quiz: "Désactiver le quiz à la date et à l'heure mentionnées",
    upgrade: "Mise à niveau",
    gold_plan: "Forfait Or",
    view_plans: "Voir les forfaits",
    premium_plans: "Forfaits premium",
    plan_duration: "/ Mois",
    plan_logic_jump: "Saut logique",
    plan_remove_ads: "Supprimez la pub",
    cancel_subscription: "Annuler l'abonnement",
    quiz_has_answer_edit_message: "Ce quiz a déjà été soumis et les réponses ont été collectées. La modification du quiz peut affecter les réponses précédentes.",
    quiz_published_alert_edit_message: "Ce quiz a été publié. Si vous le modifiez, ce quiz ne sera pas publié.",
    quiz_submit_after_edit_message: "La modification du quiz peut affecter les notes, le pourcentage et nécessiter une réévaluation de certaines questions.",
    title_activity_privacy_policy: "Politique de confidentialitéActivité",
    action_blocked_message: "Action bloquée en raison de réponses existantes à ce quiz. L'autoriser pourrait avoir un impact sur les réponses précédentes.",
    email_addresses: "Adresses mail",
    confirmation_message: "Message de confirmation",
    confirmation_message_description: "Voulez-vous afficher un message de confirmation après avoir soumis le formulaire ?",
    confirmation_message_description_quiz: "Voulez-vous afficher un message de confirmation après avoir répondu au quiz ?",
    default_custom_thank_message: "Merci d'avoir rempli ce formulaire.",
    default_custom_thank_message_quiz: "Merci d'avoir répondu à ce quiz.",
    upgrade_your_plan: "Améliorez votre forfait !",
    upgrade_your_plan_1: "Mettez à niveau votre forfait ",
    upgrade_your_plan_content: "Veuillez mettre à niveau la version premium pour accéder à cette fonctionnalité et augmenter la taille de stockage, etc.",
    alert_icon: "Icône d'alerte",
    usage_details: "Détails d'utilisation",
    current_plan: "Plan actuel",
    total_submission: "Soumission totale",
    image_attachments: "Pièces jointes d'images",
    custom_themes: "Thèmes personnalisés",
    free_limit_reached: "Vous avez atteint la limite gratuite -",
    payment_done_but_server_error: "Votre paiement a été déduit avec succès. Veuillez ouvrir l'application après un certain temps pour nous permettre de vérifier votre paiement.",
    response_quota_reached: "Vous avez atteint la limite gratuite, veuillez mettre à niveau votre forfait pour accéder à toutes les réponses.",
    answers_quota_reached: "Vous avez atteint la limite gratuite, veuillez mettre à niveau votre forfait pour accéder à toutes les réponses.",
    logic_jump_enabled: "Saut logique activé.",
    logic_jump_disabled: "Saut logique désactivé.",
    cancel_subscription_message: "Etes-vous sûr de vouloir annuler votre abonnement ? L'annulation de l'abonnement entraînera la perte de l'accès à toutes les fonctionnalités premium.",
    plan_expired: "Votre forfait a expiré. Veuillez mettre à niveau votre forfait pour utiliser ce brouillon.",
    plan_expired_edit_form: "Votre forfait a expiré. Veuillez mettre à niveau votre forfait pour modifier ce formulaire",
    plan_expired_edit_quiz: "Votre forfait a expiré. Veuillez mettre à niveau votre forfait pour modifier ce quiz",
    plan_expired_duplicate_form: "Votre forfait a expiré. Veuillez mettre à niveau votre forfait pour dupliquer ce formulaire",
    plan_expired_duplicate_quiz: "Votre forfait a expiré. Veuillez mettre à niveau votre forfait pour dupliquer ce quiz",
    premium_features: "Fonctionnalité Premium",
    plan_file_upload: "Images de thème personnalisées illimitées",
    plan_custom_theme: "Images de thème personnalisées illimitées",
    plan_call_support: "Activation haute priorité - Appelez l'assistance",
    plan_email_support: "Assistance par e-mail hautement prioritaire",
    plan_custom_domain: "Domaine personnalisé",
    subscribe: "S'abonner",
    plan_remove_branding: "Supprimer la marque",
    plan_on_call_support: "Assistance sur appel",
    plan_image_attachment: "Pièces jointes d'images illimitées",
    plan_custom_thank_you: "Pages de remerciement personnalisables",
    plan_allowed_responses: "Réponses autorisées : 50 000 ",
    trusted_by_number_of_user: "Approuvé par plus de 5 millions d'utilisateurs",
    email_support: "Assistance par e-mail",
    call_support: "Appeler l'assistance",
    keyboard_shortcuts: "Raccourcis clavier",
    premium_limit_reached: "Vous avez atteint la limite du forfait. Veuillez mettre à niveau votre forfait pour accéder à toutes les fonctionnalités premium.",
    premium_limit_reached_mobile: "Vous avez atteint la limite du forfait.",
    unlimited: "Illimité",
    expired_on_next_renewal: "Il expirera à votre prochaine date de renouvellement",
    upgrade_plan: "Plan de mise à niveau",
    downgrade_plan: "Plan de rétrogradation",
    file_upload_questions: "Questions sur le téléchargement de fichiers",
    plan_response_edit: "Réponse Modifier",
    cant_process: "Impossible de traiter",
    you_have_subscribed_through_android: "Vous vous êtes abonné via notre application Android. Veuillez utiliser l'application SurveyHeart pour toute mise à jour relative au paiement.",
    pay_now: "Payez maintenant",
    update_payment_details: "Mettre à jour les détails du paiement",
    limit_reached_alert_message: "Vous avez atteint la limite gratuite, veuillez mettre à niveau votre forfait pour accéder à toutes les réponses.",
    limit_reached_alert_message_quiz: "Vous avez atteint la limite gratuite, veuillez mettre à niveau votre forfait pour accéder à toutes les réponses.",
    limit_reached_UI_message: "Les réponses ne seront pas disponibles pour votre forfait actuel. Veuillez mettre à niveau votre forfait pour voir toutes les réponses.",
    limit_reached_UI_message_quiz: "Les réponses ne seront pas disponibles pour votre forfait actuel. Veuillez mettre à niveau votre forfait pour voir toutes les réponses.",
    owner_is_not_premium_user: "Le propriétaire n'est pas un utilisateur premium",
    cancel_subscription_description: "Etes-vous sûr de vouloir annuler votre abonnement ? L'annulation entraînera la perte de l'accès à toutes les fonctionnalités premium.",
    starter_storage_description: "Obtenez 5 Go de stockage pour une gestion des données sans effort.",
    bronze_storage_description: "Obtenez 25 Go de stockage pour une gestion des données sans effort.",
    silver_storage_description: "Obtenez 50 Go de stockage pour une gestion des données sans effort.",
    gold_storage_description: "Obtenez 150 Go de stockage pour une gestion des données sans effort.",
    starter_responses_description: "Utilisez 10 000 réponses pour obtenir des informations d'enquête puissantes.",
    bronze_responses_description: "Utilisez 35 000 réponses pour obtenir des informations d'enquête puissantes.",
    silver_responses_description: "Utilisez 75 000 réponses pour obtenir des informations d'enquête puissantes.",
    gold_responses_description: "Utilisez 1 50 000 réponses pour obtenir des informations d'enquête puissantes.",
    starter_image_description: "Améliorez vos enquêtes avec jusqu'à 100 images pour un attrait visuel.",
    bronze_image_description: "Améliorez vos enquêtes avec jusqu'à 200 images pour un attrait visuel.",
    silver_image_description: "Améliorez vos enquêtes avec jusqu'à 500 images pour un attrait visuel.",
    gold_image_description: "Améliorez vos enquêtes avec des images illimitées pour un attrait visuel appeal.",
    starter_theme_description: "Ajoutez 100 images personnalisées pour personnaliser vos enquêtes pour votre marque.",
    bronze_theme_description: "Ajoutez 200 images personnalisées pour personnaliser vos enquêtes pour votre marque.",
    silver_theme_description: "Ajoutez 500 images personnalisées pour personnaliser vos enquêtes pour votre marque.",
    gold_theme_description: "Ajoutez des images personnalisées illimitées pour personnaliser vos enquêtes pour votre marque.",
    custom_theme_image: "Image de thème personnalisée",
    starter_upload_description: "Intégrez de manière transparente 4 questions de téléchargement de fichiers",
    file_upload_description: "Intégrez de manière transparente des questions de téléchargement de fichiers illimitées.",
    logic_jump_description: "Utilisez des sauts logiques pour personnaliser le flux d'enquête pour l'expérience de chaque répondant",
    thank_you_message_description: "Personnalisez les pages de remerciement pour impressionner les répondants.",
    response_edit_description: "Affinez facilement les réponses à l'aide de la fonction d'édition de réponse.",
    remove_branding_description: "Améliorez la présence de votre marque en supprimant la marque SurveyHeart.",
    bronze_remove_ads_description: "Éliminez les publicités avec notre plan Bronze et concentrez-vous sur vos enquêtes.",
    silver_remove_ads_description: "Avec notre forfait Silver, évitez les publicités et concentrez-vous sur vos enquêtes.",
    gold_remove_ads_description: "Avec notre forfait Gold, évitez les publicités et concentrez-vous sur vos enquêtes.",
    bronze_email_support_description: "Accédez à l'assistance par e-mail pour les demandes de faible priorité, afin de garantir une assistance rapide.",
    silver_email_support_description: "Accédez à l'assistance par e-mail pour les demandes de priorité moyenne, afin de garantir une assistance rapide.",
    gold_email_support_description: "Accédez à l'assistance par e-mail pour les demandes de haute priorité, afin de garantir une assistance rapide.",
    bronze_email_support_title: "Assistance par e-mail de faible priorité",
    silver_email_support_title: "Assistance par e-mail de priorité moyenne",
    gold_email_support_title: "Assistance par e-mail de haute priorité",
    update_your_payment_details_mobile: "Veuillez mettre à jour vos informations de paiement.",
    update_your_payment_details_pc: "Nous rencontrons des problèmes lors du renouvellement de ce compte. Veuillez mettre à jour vos informations de paiement.",
    card_details_updated_successfully: "Les informations de la carte ont été mises à jour avec succès",
    card_details_updated_successfully_description: "Les informations de votre carte ont été mises à jour avec succès. Vous pouvez désormais continuer à utiliser vos informations de paiement mises à jour.",
    payment_successful: "Paiement réussi",
    payment_successful_description: "Votre paiement a réussi. Vous êtes désormais abonné au plan premium de SurveyHeart. Installez-vous confortablement et profitez de toutes les fonctionnalités étonnantes qui vous attendent.",
    payment_failed: "Paiement échoué !",
    payment_failed_description: "Malheureusement. Nous n'avons pas pu traiter votre abonnement au plan premium de SurveyHeart. Veuillez réessayer.",
    note_for_five_rupees: "Remarque : des frais de 5 Rs sont facturés pour la mise à jour des informations de votre carte, qui seront remboursés dans les 3 à 4 jours ouvrables sur le même compte.",
    logic: "Logique",
    re_generate_code: "Régénérer le code",
    plan_change_restrictions: "Restrictions de changement de forfait",
    plan_change_restriction_desc_1: "Vous ne pouvez pas modifier votre forfait à l'aide d'UPI.",
    plan_change_restriction_desc_2: "Passez à une carte pour les mises à niveau et les rétrogradations, ou annulez et réabonnez-vous au forfait souhaité.",
    update_card_details: "Mettre à jour les détails de la carte",
    features_and_plans: "Fonctionnalités et forfaits",
    cancel_plan: "Annuler le forfait",
    cancel_plan_desc: "Vous avez annulé le forfait. Vous aurez toujours accès aux fonctionnalités payantes jusqu'à votre date de renouvellement.",
    explore_text: "Découvrez et débloquez dès aujourd'hui des fonctionnalités exceptionnelles dans l'un de nos forfaits d'abonnement premium",
    cant_edit_this_form: "Impossible de modifier ce formulaire",
    cant_edit_this_quiz: "Impossible de modifier ce questionnaire",
    cant_duplicate_this_form: "Impossible de dupliquer ce formulaire",
    cant_duplicate_this_quiz: "Impossible de dupliquer ce quiz",
    kindly_upgrade_the_premium: "Veuillez mettre à jour la version premium pour accéder à cette fonctionnalité.",
    check_box: 'case à cocher',
    continue_to_next_question: "Continuer à la question suivante",
    add_logic: "Ajouter une logique",
    submit_form: "Soumettre le formulaire",
    save_logic: "Enregistrer la logique",
    remove_logic: "Supprimer la logique",
    responses_add_ons: "Module complémentaire Réponses",
    additional_submissions: "Soumissions supplémentaires",
    responses_add_available: "Module complémentaire Réponses disponible",
    validity: "Validité",
    up_to_your_current_plan: "Jusqu'à votre forfait actuel",
    responses_add_ons_description: "Ce nombre de réponses sera ajouté à votre forfait existant.",
    redirect_url_title: "Rediriger vers le lien souhaité",
    redirect_url_description: "Voulez-vous rediriger vos utilisateurs vers une page Web externe ?",
    enter_weblink: "Entrez le lien Web",
    get: "Obtenir",
    jump_to: "Passer à",
    action_cannot_be_performed: "L'action ne peut pas être effectuée",
    upgrade_plan_desc1: "Voulez-vous mettre à niveau votre forfait vers",
    downgrade_plan_desc1: "Voulez-vous rétrograder votre forfait vers",
    downgrade_plan_desc2: "Ce changement prendra effet à votre prochaine date de renouvellement.",
    upgraded_plan: "Forfait mis à niveau",
    downgraded_plan: "Forfait rétrogradé",
    upgraded_plan_desc: "Vous avez mis à niveau votre forfait. Ce changement prendra effet à votre prochaine date de renouvellement.",
    downgraded_plan_desc: "Vous avez rétrogradé votre forfait. Ce changement prendra effet à votre prochaine date de renouvellement.",
    buy_responses: "Acheter des réponses",
    passcode: "Code d'accès",
    share_link_with_passcode: "Partager le lien avec le code d'accès",
    contact_us: "Contactez-nous",
    premium_limit_reached_for_gold: "Vous avez atteint la limite du forfait. Veuillez nous contacter pour accéder à toutes les fonctionnalités premium.",
    follow_us: "Suivez-nous",
    billing_history: "Historique de facturation",
    your_current_plan: "Votre forfait actuel",
    next_billing_date: "Prochaine date de facturation",
    transaction_id: "ID de transaction",
    plan: "Forfait",
    price: "Prix",
    status: "Statut",
    follow_us_desc: "Restez à jour, connecté et engagez-vous avec nous",
    ranking_choice: 'Choix de classement',
    ranking: 'Classement',
    picture_choice: "Choix d'image",
    agreement: 'Accord',
    signature: "Signature",
    slider: 'Curseur',
    min: 'Min',
    max: 'Max',
    step_value: "Valeur de l'étape",
    scale_range_error: "La plage d'échelle doit être une valeur comprise entre -10 000 et 10 000.",
    scale_range_error2: "La valeur de l'échelle maximale est inférieure à la valeur acceptable.",
    max_min_error: "La valeur de l'échelle minimale doit être inférieure à l'échelle maximale.",
    step_error: "La taille de l'étape doit être une valeur comprise entre",
    add_picture: 'Ajouter une image',
    terms_and_conditions: "Conditions générales",
    terms_and_conditions_required: "Conditions générales requises",
    i_agree: "Je suis d'accord",
    i_disagree: "Je ne suis pas d'accord",
    yearly: "Annuel",
    monthly: "Mensuel",
    save_upto_15: "Économisez jusqu'à 15 % avec les forfaits annuels",
    paid: "Payé",
    cancelled: "Annulé",
}