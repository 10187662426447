import {translationKeys} from "../../localizations/translationKeys-localization";
import i18next, {t} from "i18next";
import {translationsEnglish} from "../../localizations/en-localization";
import {translationSpanish} from "../../localizations/es-localization";
import {translationsPortuguese} from "../../localizations/pt-localization";
import {translationsFrench} from "../../localizations/fr-localization";
import {translationsHindi} from "../../localizations/hi-localization";
import {translationsTamil} from "../../localizations/ta-localization";
import {translationsIndonesia} from "../../localizations/in-localization";
import {translationsMarathi} from "../../localizations/mr-localization";
import {translationsPersian} from "../../localizations/fa-localization";
import {translationsMalayalam} from "../../localizations/ml-localization";
import {translationsTelugu} from "../../localizations/te-localization";
import {translationsArabic} from "../../localizations/ar-localization";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {initReactI18next} from "react-i18next";
import {translationsPunjabi} from "../../localizations/pa-localization";
import {PremiumJSON} from "./helper";

export const resources = {
    en: {translation: translationsEnglish},
    es: {translation: translationSpanish},
    pt: {translation: translationsPortuguese},
    fr: {translation: translationsFrench},
    hi: {translation: translationsHindi},
    pa: {translation: translationsPunjabi},
    ta: {translation: translationsTamil},
    in: {translation: translationsIndonesia},
    mr: {translation: translationsMarathi},
    fa: {translation: translationsPersian},
    ml: {translation: translationsMalayalam},
    te: {translation: translationsTelugu},
    ar: {translation: translationsArabic}
}
// this is the reason why localization is not working i.e i18next is not initialized
export const initializeI18next = () => {
    const fallbackLng = ["en"];
    i18next.use(Backend).use(LanguageDetector).use(initReactI18next)
        .init({
            resources, fallbackLng, detection: {
                checkWhitelist: true
            }, interpolation: {
                escapeValue: false
            }
        });
}
initializeI18next()

export const QUESTION_TYPE = {
    SHORT_TEXT:
        {
            key: "SHORT_TEXT",
            displayText: t(translationKeys.short_answer)
        },
    LONG_TEXT:
        {
            key: "LONG_TEXT",
            displayText: t(translationKeys.long_answer)
        },
    EMAIL:
        {
            key: "EMAIL",
            displayText: t(translationKeys.email)
        },
    MULTIPLE_CHOICE:
        {
            key: "MULTIPLE_CHOICE",
            displayText: t(translationKeys.multiple_choice)
        },
    RANKING_CHOICE:
        {
            key: "RANKING_CHOICE",
            displayText: t(translationKeys.ranking)
        },
    PICTURE_QUESTION:
        {
            key: "PICTURE_QUESTION",
            displayText: t(translationKeys.picture_choice)
        },
    AGREEMENT:
        {
            key: "AGREEMENT",
            displayText: t(translationKeys.agreement)
        },
    MULTIPLE_CHOICE_GRID:
        {
            key: "MULTIPLE_CHOICE_GRID",
            displayText: t(translationKeys.multiple_choice_grid)
        },
    CHECKBOX_GRID:
        {
            key: "CHECKBOX_GRID",
            displayText: t(translationKeys.checkboxes_grid)
        },
    CHECKBOX_CHOICE:
        {
            key: "CHECKBOX_CHOICE",
            displayText: t(translationKeys.checkboxes)
        },
    DROPDOWN_CHOICE:
        {
            key: "DROPDOWN_CHOICE",
            displayText: t(translationKeys.dropdown)
        },
    STAR_RATING_SCALE:
        {
            key: "STAR_RATING_SCALE",
            displayText: t(translationKeys.star_rating)
        },
    DATE:
        {
            key: "DATE",
            displayText: t(translationKeys.date_text)
        },
    TIME:
        {
            key: "TIME",
            displayText: t(translationKeys.time_text)
        },
    SECTION:
        {
            key: "SECTION",
            displayText: t(translationKeys.section)
        },
    NUMBER:
        {
            key: "NUMBER",
            displayText: t(translationKeys.number)
        },
    FILE_UPLOAD:
        {
            key: "FILE_UPLOAD",
            displayText: t(translationKeys.file_upload)
        },
    SIGNATURE_QUESTION:
        {
            key: "SIGNATURE_QUESTION",
            displayText: t(translationKeys.signature)
        },
    WEB_LINK:
        {
            key: "WEBLINK"
        },
    LINEAR_SCALE: {
        key: "LINEAR_SCALE",
        displayText: t(translationKeys.linear_scale)
    },
    SMILEY: {
        key: "SMILEY",
        displayText: t(translationKeys.smile)
    },
    SLIDER: {
        key: "SLIDER",
        displayText: t(translationKeys.slider)
    }
}
export const QUIZ_QUESTION_TYPE = {
    SHORT_TEXT:
        {
            key: "SHORT_TEXT",
            displayText: t(translationKeys.short_answer)
        },
    LONG_TEXT:
        {
            key: "LONG_TEXT",
            displayText: t(translationKeys.long_answer)
        },
    MULTIPLE_CHOICE:
        {
            key: "MULTIPLE_CHOICE",
            displayText: t(translationKeys.multiple_choice)
        },
    PICTURE_QUESTION:
        {
            key: "PICTURE_QUESTION",
            displayText: t(translationKeys.picture_choice)
        },
    DROPDOWN_CHOICE:
        {
            key: "DROPDOWN_CHOICE",
            displayText: t(translationKeys.dropdown)
        },
    FILE_UPLOAD:
        {
            key: "FILE_UPLOAD",
            displayText: t(translationKeys.file_upload)
        },
    SECTION:
        {
            key: "SECTION",
            displayText: t(translationKeys.section)
        }
}

export const QUESTION_CATEGORY = {
    CHOICE_TYPE: 0,
    TEXT_TYPE: 1,
    FILE_UPLOAD_TYPE: 2,
    GRID_TYPE: 3,
    SIGNATURE_TYPE: 4,
    SLIDER_TYPE: 5
}
export const QUESTION_GROUPS = {
    TEXT: 'TEXT',
    CHOICE: 'CHOICE',
    GRID: 'GRID',
    SIGNATURE: "SIGNATURE",
    FILE_UPLOAD: 'FILE_UPLOAD',
    LINEAR_SCALE: 'LINEAR_SCALE',
    STAR: 'STAR',
    SMILE: 'SMILE',
    SECTION: 'SECTION',
    SLIDER: "SLIDER",
    RANKING: "RANKING",
    AGREEMENT: "AGREEMENT",
    PICTURE: "PICTURE"
}

/**
 *
 * @param {QUESTION_TYPE} questionType - The question type that will be used to look up the question group
 */
export function getQuestionGroupV2(questionType) {
    let questionGroup

    switch (questionType) {
        case QUESTION_TYPE.DATE:
        case QUESTION_TYPE.EMAIL:
        case QUESTION_TYPE.LONG_TEXT:
        case QUESTION_TYPE.NUMBER:
        case QUESTION_TYPE.SHORT_TEXT:
        case QUESTION_TYPE.TIME:
            questionGroup = QUESTION_GROUPS.TEXT
            break
        case QUESTION_TYPE.CHECKBOX_CHOICE:
        case QUESTION_TYPE.DROPDOWN_CHOICE:
        case QUESTION_TYPE.MULTIPLE_CHOICE:
            questionGroup = QUESTION_GROUPS.CHOICE
            break
        case QUESTION_TYPE.FILE_UPLOAD:
            questionGroup = QUESTION_GROUPS.FILE_UPLOAD
            break
        case QUESTION_TYPE.SECTION:
            questionGroup = QUESTION_GROUPS.SECTION
            break
        case QUESTION_TYPE.CHECKBOX_GRID:
        case QUESTION_TYPE.MULTIPLE_CHOICE_GRID:
            questionGroup = QUESTION_GROUPS.GRID
            break
        case QUESTION_TYPE.SMILEY:
            questionGroup = QUESTION_GROUPS.SMILE
            break
        case QUESTION_TYPE.LINEAR_SCALE:
            questionGroup = QUESTION_GROUPS.LINEAR_SCALE
            break
        case QUESTION_TYPE.STAR_RATING_SCALE:
            questionGroup = QUESTION_GROUPS.STAR
            break
        case QUESTION_TYPE.SLIDER:
            questionGroup = QUESTION_GROUPS.SLIDER
            break
        case QUESTION_TYPE.AGREEMENT:
            questionGroup = QUESTION_GROUPS.AGREEMENT
            break
        case QUESTION_TYPE.PICTURE_QUESTION:
            questionGroup = QUESTION_GROUPS.PICTURE
            break
        case QUESTION_TYPE.RANKING_CHOICE:
            questionGroup = QUESTION_GROUPS.RANKING
            break
        case QUESTION_TYPE.SIGNATURE_QUESTION:
            questionGroup = QUESTION_GROUPS.SIGNATURE
            break
    }

    return questionGroup
}

/**
 *
 * @param {QUIZ_QUESTION_TYPE} quizQuestionType - The Quiz question type that will be used to look up the question group
 */
export function getQuizQuestionGroup(quizQuestionType) {
    let quizQuestionGroup

    switch (quizQuestionType) {
        case QUIZ_QUESTION_TYPE.LONG_TEXT:
        case QUIZ_QUESTION_TYPE.SHORT_TEXT:
            quizQuestionGroup = QUESTION_GROUPS.TEXT
            break
        case QUIZ_QUESTION_TYPE.DROPDOWN_CHOICE:
        case QUIZ_QUESTION_TYPE.MULTIPLE_CHOICE:
            quizQuestionGroup = QUESTION_GROUPS.CHOICE
            break
        case QUIZ_QUESTION_TYPE.FILE_UPLOAD:
            quizQuestionGroup = QUESTION_GROUPS.FILE_UPLOAD
            break
        case QUIZ_QUESTION_TYPE.SECTION:
            quizQuestionGroup = QUESTION_GROUPS.SECTION
            break
        case QUIZ_QUESTION_TYPE.PICTURE_QUESTION:
            quizQuestionGroup = QUESTION_GROUPS.PICTURE
            break
    }

    return quizQuestionGroup
}

export function getQuestionTypes(questionGroup) {
    let questionTypes = []

    switch (questionGroup) {
        case QUESTION_GROUPS.CHOICE:
            questionTypes.push(QUESTION_TYPE.CHECKBOX_CHOICE, QUESTION_TYPE.DROPDOWN_CHOICE, QUESTION_TYPE.MULTIPLE_CHOICE)
            break
        case QUESTION_GROUPS.GRID:
            questionTypes.push(QUESTION_TYPE.CHECKBOX_GRID, QUESTION_TYPE.MULTIPLE_CHOICE_GRID)
            break
        case QUESTION_GROUPS.SECTION:
            questionTypes.push(QUESTION_TYPE.SECTION)
            break
        case QUESTION_GROUPS.FILE_UPLOAD:
            questionTypes.push(QUESTION_TYPE.FILE_UPLOAD)
            break
        case QUESTION_GROUPS.SLIDER:
            questionTypes.push(QUESTION_TYPE.SLIDER)
            break
        case QUESTION_GROUPS.AGREEMENT:
            questionTypes.push(QUESTION_TYPE.AGREEMENT)
            break
        case QUESTION_GROUPS.PICTURE:
            questionTypes.push(QUESTION_TYPE.PICTURE_QUESTION)
            break
        case QUESTION_GROUPS.STAR:
            questionTypes.push(QUESTION_TYPE.STAR_RATING_SCALE)
            break
        case QUESTION_GROUPS.LINEAR_SCALE:
            questionTypes.push(QUESTION_TYPE.LINEAR_SCALE)
            break
        case QUESTION_GROUPS.TEXT:
            questionTypes.push(QUESTION_TYPE.SHORT_TEXT, QUESTION_TYPE.LONG_TEXT, QUESTION_TYPE.EMAIL, QUESTION_TYPE.NUMBER, QUESTION_TYPE.DATE, QUESTION_TYPE.TIME)
            break
        case QUESTION_GROUPS.SMILE:
            questionTypes.push(QUESTION_TYPE.SMILEY)
            break
        case QUESTION_GROUPS.RANKING:
            questionTypes.push(QUESTION_TYPE.RANKING_CHOICE)
            break
        case QUESTION_GROUPS.SIGNATURE:
            questionTypes.push(QUESTION_TYPE.SIGNATURE_QUESTION)
            break
    }
    return questionTypes
}

export function getQuizQuestionTypes(questionGroup) {
    let quizQuestionTypes = []

    switch (questionGroup) {
        case QUESTION_GROUPS.CHOICE:
            quizQuestionTypes.push(QUIZ_QUESTION_TYPE.DROPDOWN_CHOICE, QUIZ_QUESTION_TYPE.MULTIPLE_CHOICE)
            break
        case QUESTION_GROUPS.SECTION:
            quizQuestionTypes.push(QUIZ_QUESTION_TYPE.SECTION)
            break
        case QUESTION_GROUPS.FILE_UPLOAD:
            quizQuestionTypes.push(QUESTION_TYPE.FILE_UPLOAD)
            break
        case QUESTION_GROUPS.TEXT:
            quizQuestionTypes.push(QUIZ_QUESTION_TYPE.LONG_TEXT, QUIZ_QUESTION_TYPE.SHORT_TEXT)
            break
        case QUESTION_GROUPS.PICTURE:
            quizQuestionTypes.push(QUIZ_QUESTION_TYPE.PICTURE_QUESTION)
            break
    }
    return quizQuestionTypes
}

export function getQuestionCategory(questionType) {
    if (questionType === QUESTION_TYPE.MULTIPLE_CHOICE.key ||
        questionType === QUESTION_TYPE.RANKING_CHOICE.key ||
        questionType === QUESTION_TYPE.PICTURE_QUESTION.key ||
        questionType === QUESTION_TYPE.DROPDOWN_CHOICE.key ||
        questionType === QUESTION_TYPE.CHECKBOX_CHOICE.key ||
        questionType === QUESTION_TYPE.AGREEMENT.key ||
        questionType === QUESTION_TYPE.LINEAR_SCALE.key ||
        questionType === QUESTION_TYPE.STAR_RATING_SCALE.key ||
        questionType === QUESTION_TYPE.SMILEY.key) {
        return QUESTION_CATEGORY.CHOICE_TYPE
    } else if (questionType === QUESTION_TYPE.SHORT_TEXT.key ||
        questionType === QUESTION_TYPE.LONG_TEXT.key ||
        questionType === QUESTION_TYPE.DATE.key ||
        questionType === QUESTION_TYPE.TIME.key ||
        questionType === QUESTION_TYPE.NUMBER.key ||
        questionType === QUESTION_TYPE.EMAIL.key) {
        return QUESTION_CATEGORY.TEXT_TYPE
    } else if (questionType === QUESTION_TYPE.FILE_UPLOAD.key) {
        return QUESTION_CATEGORY.FILE_UPLOAD_TYPE
    } else if (questionType === QUESTION_TYPE.SIGNATURE_QUESTION.key) {
        return QUESTION_CATEGORY.SIGNATURE_TYPE
    } else if (questionType === QUESTION_TYPE.CHECKBOX_GRID.key ||
        questionType === QUESTION_TYPE.MULTIPLE_CHOICE_GRID.key) {
        return QUESTION_CATEGORY.GRID_TYPE
    } else if (questionType === QUESTION_TYPE.SLIDER.key) {
        return QUESTION_CATEGORY.SLIDER_TYPE
    }
}

export const getQuestionTypeText = (type) => {
    return Object.values(QUESTION_TYPE).filter((value) => value.key === type)[0].displayText
}

export const ATTACHMENT_TYPE = {
    IMAGE:
        {
            key: "IMAGE",
            displayText: t(translationKeys.image)
        },
    VIDEO:
        {
            key: "VIDEO",
            displayText: t(translationKeys.video)
        },
    WEBSITE:
        {
            key: "WEBSITE",
            displayText: t(translationKeys.website)
        }
}

// ItemTypes are the draggable item types used for drag and drop.
// Note that there is no 'key' and 'displayText' as ItemTypes will not be displayed.
export const DRAG_ITEM_TYPES = {
    QUESTION: 'question'
}

export const DRAG_TARGET_TYPES = {
    NONE: 'none',
    TOP: 'top',
    BOTTOM: 'bottom'
}

export const CURRENTLY_SELECTED_MENU = {
    BUILDER: 'BUILDER',
    PREVIEW: 'PREVIEW',
    SETTINGS: 'SETTINGS'
}

export const SUBMISSION_ERRORS = {
    NONE: 'NONE',
    MISSING_TITLE: 'MISSING_TITLE',
    MISSING_QUESTIONS: 'MISSING_QUESTIONS',
    INCOMPLETE_QUESTIONS: 'INCOMPLETE_QUESTIONS'
}

export const PAGE_STATUS = {
    BLOCKED: 'blocked',
    RESPONDED: 'responded',
    PENDING: 'pending',
    INACTIVE: 'inactive',
    ERROR: 'error',
    ANSWERED: 'answered',
    HIDDEN: 'hidden',
    EXPIRED: 'expired',
    LIMIT_EXCEEDED: 'limit-exceeded',
    OUT_OF_SPACE: "out-of-space"
}

export const EMAIL_SUGGESTIONS = {
    LOCAL_STORAGE_EMAIL_TEXT: "email-suggestions"
}

export const BUILDER_TYPE = {
    FORM:
        {
            key: "FORM",
            displayText: "Form"
        },
    QUIZ:
        {
            key: "QUIZ",
            displayText: "Quiz"
        }
}

export const BUILDER_SUGGESTION_TYPES_FOR_LOCAL_STORAGE = {
    FORM_QUESTION:
        {
            key: "form-question-suggestions"
        },
    QUIZ_QUESTION:
        {
            key: "quiz-question-suggestions"
        }
}

export const PREVIEW_TYPE = {
    PHONE: "phone",
    TABLET: "tablet",
    DESKTOP: "desktop",
}

export const WEB_APP = 'web-app'

// Used to check for features that have been implemented in the Web Version of SurveyHeart but are not in the SurveyHeart Android app yet.
export const WEB_APP_VERSION = 1

export const HIGHLIGHT_TEXT = 'highlightText'

export const SET_FOCUS = 'setFocus'

export const REFRESH_OPTIONS = 'refreshOptions'

export const SHOW_MENU = 'showMenu'

export const BUILDER_CREATION_SOURCE = {
    DUPLICATE: 'duplicate',
    NEW: 'new',
    SAVED: 'saved',
    TEMPLATE: 'template'
}

export const UPLOAD_TYPE = {
    IMAGE: 'image',
    FILE: 'file'
}

export const FORM_QUIZ_RESPONSE_USER_STORAGE_LOAD_ACTION = {
    NONE: 'none',
    CHECK_USER_STORAGE_LEVEL: 'checkUserStorageLevel',
    PENDING: 'pending'
}

export const ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL = {
    NONE: 'none',
    SHOW_OUT_OF_SPACE_PAGE: 'showOutOfSpacePage',
    ALLOW_RESPONSES_TO_NON_FILE_UPLOAD_QUESTIONS: 'allowResponsesToNonFileUploadQuestions',
    PENDING: 'pending'
}

export const MAX_FILE_UPLOAD_QUESTION_TYPES = 4
export const MAX_USER_FILE_STORAGE = 1e9 * 1.024

export const maxUserFileStorageBasedOnUserPlan = (userPlan) => {
    return PremiumJSON[userPlan]?.storage
}

export const LABEL_TYPES = {
    START: 'start',
    CENTER: 'center',
    END: 'end'
}