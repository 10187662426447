import axios from "axios";
import {REQUEST_METHOD} from "./helper"
import {getSASHWebToken} from "../../app/utils/jwt";

export const FORM_BASE_URL = process.env.REACT_APP_ENV_FORM_SERVER_URL || "https://surveyheart.com";

export const getZohoDeskTickets = (successFunction, failureFunction) => {
    makeAuthorizedServerRequest("/getopenzohodesktickets", REQUEST_METHOD.GET, {}, successFunction, failureFunction)
}

export const generatePDF = (id, successFunction, failureFunction) => {
    const timeZoneOffset = new Date().getTimezoneOffset()
    const requestUrl = "/generatepdf?id=" + id + "&time_zone_offset=" + timeZoneOffset
    makeAuthorizedServerRequest(requestUrl, REQUEST_METHOD.GET, {}, successFunction, failureFunction)
}

export const createZohoDeskTicket = (subject, phone, description, category, attachment, phoneNumber, successFunction, failureFunction) => {
    const formdata = new FormData();
    formdata.append("subject", subject);
    formdata.append("description", description);
    formdata.append("category", category);
    formdata.append("channel", phone ? "Phone" : "Email");
    formdata.append("phone_number", phoneNumber);
    if (attachment) {
        formdata.append("attachment", "true");
        formdata.append("file", attachment, attachment?.filename);
    }

    makeAuthorizedServerRequest("/createzohodeskticket", REQUEST_METHOD.POST, formdata, successFunction, failureFunction)
}

export const createSubscriptionId = (subscriptionPlan, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        subscription_plan: subscriptionPlan
    })
    makeAuthorizedServerRequest("/createrazorpaysubscription", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const getBillingHistory = (pageIndex, limit, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        page_index: pageIndex,
        limit: limit
    })
    makeAuthorizedServerRequest("/getbillinghistory", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const getSubscriptionId = (successFunction, failureFunction) => {
    makeAuthorizedServerRequest("/getrazorpaysubscriptionid", REQUEST_METHOD.GET, {}, successFunction, failureFunction)
}

export const verifyPayment = (subscriptionId, paymentId, razorpaySignature, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        subscription_id: subscriptionId,
        payment_id: paymentId,
        razorpay_signature: razorpaySignature
    })
    makeAuthorizedServerRequest("/verifyrazorpaypayment", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const createOrderForAddOn = (addOnPlan, successFunction, failureFunction, authToken) => {
    const requestBody = JSON.stringify({
        addon_plan: addOnPlan,
    })
    makeAuthorizedServerRequest("/createrazorpayorder", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction, authToken)
}

export const verifyPaymentForAddOn = (orderId, paymentId, signature, successFunction, failureFunction, authToken) => {
    const requestBody = JSON.stringify({
        order_id: orderId,
        payment_id: paymentId,
        razorpay_signature: signature,
    })
    makeAuthorizedServerRequest("/verifyrazorpayaddonpayment", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction, authToken)
}

export const upgradeSubscriptionPlan = (plan, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        new_subscription_plan: plan
    })
    makeAuthorizedServerRequest("/updaterazorpaysubscriptionplan", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const cancelSubscription = (successFunction, failureFunction) => {
    makeAuthorizedServerRequest("/cancelrazorpaysubscription", REQUEST_METHOD.POST, {}, successFunction, failureFunction)
}

export const removeDeletedTheme = (imageUrl, userId, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        theme_image_url: imageUrl
    })
    makeAuthorizedServerRequest("/removedeletedtheme", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const getSubscriptionData = (successFunction, failureFunction) => {
    const requestBody = JSON.stringify({})
    makeAuthorizedServerRequest("/subscriptionplan", REQUEST_METHOD.GET, requestBody, successFunction, failureFunction)
}

export const downloadAwsFilesByRespondent = (downloadData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        download_data: downloadData
    })
    makeAuthorizedServerRequestForFiles("/downloadfiles", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const downloadAwsFilesByQuestions = (downloadData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        download_data: downloadData
    })
    makeAuthorizedServerRequestForFiles("/downloadfilesbyformquestions", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const downloadAwsFilesByForms = (downloadData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        form_ids: downloadData
    })
    makeAuthorizedServerRequestForFiles("/downloadfilesbyform", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const deleteMultipleAwsFiles = (deleteData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        delete_data: deleteData
    })
    makeAuthorizedServerRequest("/removefiles", REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction)
}

export const deleteMultipleAwsFilesByQuestions = (deleteData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        delete_data: deleteData
    })
    makeAuthorizedServerRequest("/removefilesbyquestion", REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction)
}

export const deleteMultipleAwsFilesByForms = (deleteData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        form_ids: deleteData
    })
    makeAuthorizedServerRequest("/removefilesbyforms", REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction)
}

export const removeDeletedQuestionAttachment = (imageUrl, userId, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        form_image_url: imageUrl
    })
    makeAuthorizedServerRequest("/removedeletedquestionsattachment", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const createGoogleAuthLink = (successFunction, failureFunction) => {
    const requestBody = JSON.stringify({})
    makeAuthorizedServerRequest("/creategooglesheetauthlink", REQUEST_METHOD.GET, requestBody, successFunction, failureFunction)
}

export const saveTokenData = (tokenData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        tokens: {
            access_token: tokenData.access_token,
            refresh_token: tokenData.refresh_token,
            scope: tokenData.scope,
            token_type: tokenData.token_type,
            id_token: tokenData.id_token,
            expiry_date: tokenData.expiry_date
        },
    })
    makeAuthorizedServerRequest("/savegooglesheettoken", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const createGoogleSheet = (formId, googleAccountId, timeZoneOffset, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        form_id: formId,
        google_account_id: googleAccountId,
        time_zone_offset: timeZoneOffset
    })
    makeAuthorizedServerRequest("/creategooglesheet", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const unlinkGoogleSheet = (formId, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        form_id: formId
    })
    makeAuthorizedServerRequest("/unlinkgooglesheet", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const checkGoogleSheetConnectionStatus = (formId, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        form_id: formId
    })
    makeAuthorizedServerRequest("/getgooglesheetconnectionstatus", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const getGoogleSheetAccounts = (userId, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        user_id: userId
    })
    makeAuthorizedServerRequest("/getgooglesheetaccounts", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

/**
 * @param requestBody
 * @param successFunction
 * @param failureFunction
 */
export const createWebSignUp = (requestBody, successFunction, failureFunction) => {
    makeAuthorizedServerRequest("/v2/web/signup", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

/**
 * @param id
 * @param successFunction
 * @param failureFunction
 */
export const getSharedForms = (id, successFunction, failureFunction) => {
    let requestBody = JSON.stringify({
        "user_id": id
    })

    makeAuthorizedServerRequest("/v2/form/collaboration/getsharedforms?is_starter=true", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const getFormCollaborator = (id, successFunction, failureFunction) => {
    let requestBody = JSON.stringify({});
    makeAuthorizedServerRequest("/v2/form/collaborators/get/" + id, REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const getFormResponsesByPage = (limit, formId, pageIndex, successFunction, failureFunction, token) => {
    const requestBody = JSON.stringify({
        limit: limit,
        form_id: formId,
        page_index: pageIndex
    });
    makeAuthorizedServerRequest("/getresponsesbypage", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction, token ? token : null)
}

export const addFormCollaborator = (requestBody, successFunction, failureFunction) => {
    makeAuthorizedServerRequest("/v2/form/collaborator/add", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const removeFormCollaborator = (userId, formId, collaboratorId, successFunction, failureFunction) => {
    let requestBody = JSON.stringify({
        "form_id": formId,
        "collaborator_id": collaboratorId
    });

    makeAuthorizedServerRequest("/v2/form/collaborator/remove", REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction)
}

export const changeFormCollaboratorRole = (userId, formId, collaboratorId, role, successFunction, failureFunction) => {
    let requestBody = JSON.stringify({
        "form_id": formId,
        "collaborator_id": collaboratorId,
        "role": role
    });
    makeAuthorizedServerRequest("/v2/form/collaborator/changerole", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

/**
 * @param formId
 * @param successFunction
 * @param failureFunction
 */
export const leaveFormCollaboration = (formId, successFunction, failureFunction) => {
    let requestBody = JSON.stringify({
        "form_id": formId,
    });
    makeAuthorizedServerRequest("/v2/form/collaboration/leave", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

/**
 *
 * @param requestBody
 * @param successFunction
 * @param failureFunction
 */
export const userSignInServerRequest = (requestBody, successFunction, failureFunction) => {
    makeAuthorizedServerRequest("/v2/web/signin", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const resendUserIDVerificationEmailRequest = (requestBody, successFunction, failureFunction) => {
    makeAuthorizedServerRequest("/v2/web/signup/email/resend", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const resetPasswordEmailRequest = (requestBody, successFunction, failureFunction) => {
    makeAuthorizedServerRequest("/v2/web/email/reset/password", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const updatePasswordRequest = (requestBody, successFunction, failureFunction, authToken) => {
    makeAuthorizedServerRequest("/v2/web/update/password", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction, authToken)
}

/**
 * @param successFunction
 * @param failureFunction
 */
export const getForms = (successFunction, failureFunction) => {
    makeAuthorizedServerRequest("/v2/getforms?is_starter=true", REQUEST_METHOD.POST, {}, successFunction, failureFunction)
}

/**
 *
 * @param formId
 * @param successFunction
 * @param failureFunction
 */
export function deleteForm(formId, successFunction, failureFunction) {
    makeAuthorizedServerRequest('/v2/form/' + formId, REQUEST_METHOD.DELETE, {}, successFunction, failureFunction);
}

/**
 *
 * @param formId
 * @param status
 * @param successFunction
 * @param failureFunction
 */
export function changeFormStatus(formId, status, successFunction, failureFunction) {
    const requestBody = JSON.stringify({
        formsAppData: {
            form_id: formId,
            status: status
        }
    });
    makeAuthorizedServerRequest('/v2/form', REQUEST_METHOD.PUT, requestBody, successFunction, failureFunction);
}

/**
 *
 * @param formId
 * @param successEvent
 * @param failureEvent
 */
export function getFormResponses(formId, successEvent, failureEvent) {
    if (formId !== null && formId !== undefined) {
        const requestBody = JSON.stringify({
            form_id: formId
        });
        makeAuthorizedServerRequest("/v2/getresponses?is_starter=true", REQUEST_METHOD.POST, requestBody, successEvent, failureEvent);
    }
}

export function getFormResponsesForResponders(formId, successEvent, failureEvent) {
    if (formId !== null && formId !== undefined) {
        const requestBody = JSON.stringify({
            form_id: formId
        });
        makeAuthorizedServerRequest("/v2/getresponses?is_starter=true", REQUEST_METHOD.POST, requestBody, successEvent, failureEvent);
    }
}

export function getPublicFormResponsesForResponders(formId, successEvent, failureEvent) {
    if (formId !== null && formId !== undefined) {
        const requestBody = JSON.stringify({
            form_id: formId
        });
        makeAuthorizedServerRequest("/public/getresponses", REQUEST_METHOD.POST, requestBody, successEvent, failureEvent);
    }
}

export function setFormFavouriteStatus(formId, dateFavoured, successEvent, failureEvent) {
    const requestBody = JSON.stringify({
        form_id: formId,
        date_favoured: dateFavoured
    })

    makeAuthorizedServerRequest("/v2/setformfavourite", REQUEST_METHOD.PUT, requestBody, successEvent, failureEvent);
}

export function setMultipleFormFavourite(formIds, userId, date, successEvent, failureEvent) {
    const requestBody = JSON.stringify({
        form_ids: formIds,
        date_favoured: date
    })
    makeAuthorizedServerRequest("/v2/setmultipleformsfavourite", REQUEST_METHOD.PUT, requestBody, successEvent, failureEvent);
}

/**
 *
 * @param formId
 * @param responseId
 * @param successFunction
 * @param failureFunction
 */
export function deleteResponse(formId, responseId, successFunction, failureFunction) {
    let requestBody = JSON.stringify({
        form_id: formId,
        response_id: responseId
    });
    makeAuthorizedServerRequest('/v2/response', REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction);
}

/**
 *
 * @param formId
 * @param responseIds
 * @param successFunction
 * @param failureFunction
 */
export function deleteMultipleResponsesInForm(formId, responseIds, successFunction, failureFunction) {
    let requestBody = {
        form_id: formId,
        response_ids: responseIds
    };
    makeAuthorizedServerRequest('/v2/form/delete/responses', REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction);
}

export const TYPE = {
    EXCEL: 'excel',
    CSV: 'csv',
    PDF: 'pdf'
}

/**
 *
 * @param fileType
 * @param formId
 * @param successFunction
 * @param failureFunction
 */
export function downloadResponsesAsFile(fileType, formId, successFunction, failureFunction) {
    const timeZoneOffset = new Date().getTimezoneOffset()
    const formExportUrl = {
        pdf: '/v2/pdf-export?id=' + formId + "&time_zone_offset=" + timeZoneOffset,
        xlsx: '/v2/excel?format=xlsx&token=TVqKj82cg4rDcY8yqAdxIxPihrlWiBbBQCET4YjBZGWp9ZtLeR&id=' + formId + "&time_zone_offset=" + timeZoneOffset,
        csv: '/v2/excel?format=csv&token=TVqKj82cg4rDcY8yqAdxIxPihrlWiBbBQCET4YjBZGWp9ZtLeR&id=' + formId + "&time_zone_offset=" + timeZoneOffset,
    }
    const url = (fileType === TYPE.EXCEL) ? formExportUrl.xlsx : (fileType === TYPE.CSV) ? formExportUrl.csv : formExportUrl.pdf;
    const options = {
        responseType: 'blob',
        headers: {
            'authorization': 'Bearer ' + getSASHWebToken()
        }
    }
    axios.get(url, options)
        .then(successFunction)
        .catch(failureFunction)
}

/**
 *
 * @param requestBody
 * @param successFunction
 * @param failureFunction
 */
export const validateUserIdByEmail = (requestBody, successFunction, failureFunction) => {
    makeAuthorizedServerRequest("/v2/web/userid/validation", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

/**
 *
 * @param formId
 * @param successFunction
 * @param failureFunction
 */
export function getSpecificFormData(formId, successFunction, failureFunction) {
    makeAuthorizedServerRequest('/v2/form/' + formId + "?is_starter=true", REQUEST_METHOD.POST, {}, successFunction, failureFunction)
}

/**
 *
 * @param formData
 * @param successFunction
 * @param failureFunction
 */
export function createNewForm(formData, successFunction, failureFunction) {
    const requestBody = {formsAppData: formData};
    makeAuthorizedServerRequest('/v2/form/?is_starter=true', REQUEST_METHOD.POST, requestBody, successFunction, failureFunction);
}

/**
 *
 * @param formId
 * @param formData
 * @param successFunction
 * @param failureFunction
 */
export function updateExistingForm(formId, formData, successFunction, failureFunction) {
    const requestBody = {formsAppData: formData};
    makeAuthorizedServerRequest('/v2/form/' + formId + "?is_starter=true", REQUEST_METHOD.PUT, requestBody, successFunction, failureFunction);
}

export function deleteManyForms(userId, formIds, successFunction, failureFunction) {
    let requestBody = {
        user_id: userId,
        form_ids: formIds
    };
    makeAuthorizedServerRequest('/v2/form/delete/many', REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction);
}

/**
 *
 * @param url
 * @param successFunction
 * @param failureFunction
 */
export function getOGDataFromUrl(url, successFunction, failureFunction) {
    const requestUrl = `/v2/linkpreview?url=` + url;
    makeAuthorizedServerRequest(requestUrl, REQUEST_METHOD.GET, {}, successFunction, failureFunction);
}

export const getNotifications = (successFunction, failureFunction) => {
    makeAuthorizedServerRequest('/getnotifications', REQUEST_METHOD.POST,{}, successFunction, failureFunction)
}

export const updateNotificationStatus = (successFunction, failureFunction) => {
    makeAuthorizedServerRequest('/notified', REQUEST_METHOD.POST,{}, successFunction, failureFunction)
}

export const deleteNotification = (requestBody, successFunction, failureFunction) => {
    makeAuthorizedServerRequest('/deletenotification', REQUEST_METHOD.DELETE, requestBody, successFunction, failureFunction)
}

export const clearAllNotifications = (successFunction, failureFunction) => {
    makeAuthorizedServerRequest('/clearallnotification', REQUEST_METHOD.DELETE, {}, successFunction, failureFunction)
}

/**
 *
 * @param requestUrl
 * @param successFunction
 * @param failureFunction
 */
export function getDataFromUrl(requestUrl, successFunction, failureFunction) {
    axios.get(requestUrl)
        .then(function (res) {
            successFunction(res)
        })
        .catch(function (err) {
            failureFunction(err)
        })
}

export function getTotalStorageUsed(successFunction, failureFunction) {
    makeAuthorizedServerRequest("/v2/gettotalstorageused", REQUEST_METHOD.POST,{}, successFunction, failureFunction)
}

export function getSearchQuestionData(requestBody, successFunction, failureFunction) {
    makeAuthorizedServerRequest("/searchquestiondata", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export function getTotalStorageUsedForq(successFunction, failureFunction) {
    makeAuthorizedServerRequest("/getusertotalstorage", REQUEST_METHOD.POST,{}, successFunction, failureFunction)
}

export function getTotalStorageUsed_UnauthorizedRequest(requestBody, successFunction, failureFunction) {
    axios.post(`${FORM_BASE_URL}/v2/gettotalstorageusedunauthorizedrequest`, requestBody)
        .then(successFunction)
        .catch(failureFunction)
}

/**
 * @param requestURL
 * @param requestMethod
 * @param requestBody
 * @param successCallBackFunction
 * @param failureCallBackFunction
 * @param authToken
 */

const makeAuthorizedServerRequest = (requestURL, requestMethod, requestBody, successCallBackFunction, failureCallBackFunction, authToken) => {
    const requestConfiguration = {
        method: requestMethod,
        url: requestURL,
        data: requestBody,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + (authToken ? authToken : getSASHWebToken())
        }
    };
    axios(requestConfiguration)
        .then(
            function (serverResponse) {
                successCallBackFunction(serverResponse.data, serverResponse.status)
            }
        )
        .catch(function (err) {
            console.log(err)
            failureCallBackFunction(err.response)
        })
};

/*server call for files blob type*/
const makeAuthorizedServerRequestForFiles = (requestURL, requestMethod, requestBody, successCallBackFunction, failureCallBackFunction) => {
    const requestConfiguration = {
        method: requestMethod,
        url: requestURL,
        data: requestBody,
        responseType: 'blob',
        headers: {
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + getSASHWebToken()
        }
    };
    axios(requestConfiguration)
        .then(
            function (serverResponse) {
                successCallBackFunction(serverResponse.data, serverResponse.status)
            }
        )
        .catch(function (err) {
            console.log(err)
            failureCallBackFunction(err.response)
        })
}
