import React, {useRef, useState} from 'react'
import HoverControls from "./HoverControls";
import {isMobileOnly} from "react-device-detect";
import {Pages} from "../../utils/pages";
import ModalDialog from "../../../shared/components/ModalDialog";
import {getBlockedWordsText} from "../../../shared/utils/get-blocked-words-found";
import moreIcon from '../../../../public/images/more_blue.svg'
import multiselectImg from '../../../../public/images/check_box_tick.svg'
import {deleteQuizNotification, QUIZ_BASE_URL} from "../../../shared/utils/quiz-api-services";
import {deleteNotification} from "../../../shared/utils/sash-v2-api";
import {useDispatch, useSelector} from "react-redux";
import {NOTIFICATION_ACTION_TYPE} from "../../utils/constants";
import collabIcon from '../../../../public/images/collaboration_grey.svg'
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {BUILDER_CREATION_SOURCE} from "../../../shared/utils/constants";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {FORMS_ACTION_TYPE} from "../../reducers/formsReducer";
import {QUIZZES_ACTION_TYPE} from "../../reducers/quizzesReducer";
import {t} from "i18next";
import Message from "../../../shared/containers/ConfirmMessage";
import warningIcon from "../../../../public/images/icons/warning_icon.svg";
import {isShowUnavailableFeature} from "../utils/versioning";
import {getDate} from "../utils/get-date";
import FormControlContainer from "./FormControlContainer";
import getPopUpElementPositionStyles from "../../../shared/utils/get-popup-element-positions";
import {handlePreviouslyOpenedSortCard} from "./AccInfoContainer";
import {PremiumJSON} from "../../../shared/utils/helper";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";

export const CARD_TYPE = {
    USER_FORM: 'user-form-card',
    TEMPLATE_FORM: 'template-card',
    DRAFT_FORM: 'draft-card'
};

const FormListCard = (props) => {
    const formData = JSON.parse(JSON.stringify(props.formData));
    const [showHoverMenu, setShowHoverMenu] = useState(false)
    const [showFormControl, setShowFormControl] = useState(false);
    const [showViewerPopUp, setShowViewerPopUp] = useState(false)
    const [showAcknowledgement, setShowAcknowledgement] = useState(false)
    const [showUnApprovedFormDialog, setShowUnApprovedFormDialog] = useState(false)
    const [showVersioningFormDialog, setShowVersioningFormDialog] = useState(false)
    const userId = useSelector(state => state.userReducer?.userId)
    const showQuiz = useSelector(state => state.appDashboardReducer.showQuiz)
    const showForms = useSelector(state => state.appDashboardReducer.showForms)
    const showShared = useSelector(state => state.appDashboardReducer.showShared)
    const newNotificationCount = useSelector(state => state.notificationReducer.count)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const notificationData = useSelector(state => state.notificationReducer.notifications)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const multipleFormsSelectedArray = useSelector(state => state.formsReducer.multipleFormsSelectedArray)
    const multipleQuizzesSelectedArray = useSelector(state => state.quizzesReducer.multipleQuizzesSelectedArray)
    const ref = useRef(null);
    const positionRef = useRef(null);
    const position = positionRef.current ? positionRef.current : undefined;
    const dispatch = useDispatch()

    const deleteNotificationHandler = () => {
        let notificationId
        let removeNotificationCount = 0
        let formNotificationsIds = []
        let quizNotificationsIds = []
        notificationData.map((notification) => {
            if (notification.form_id === formData._id) {
                notificationId = notification._id
                dispatch({type: NOTIFICATION_ACTION_TYPE.REMOVE_NOTIFICATION, payload: notificationId})
                if (!notification.is_notified) {
                    removeNotificationCount = removeNotificationCount + 1
                    dispatch({
                        type: NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_COUNT,
                        payload: newNotificationCount - removeNotificationCount
                    })
                }
                formNotificationsIds.push(notificationId)
            } else if (notification.quiz_id === formData._id) {
                notificationId = notification._id
                dispatch({type: NOTIFICATION_ACTION_TYPE.REMOVE_NOTIFICATION, payload: notificationId})
                if (!notification.is_notified) {
                    removeNotificationCount = removeNotificationCount + 1
                    dispatch({
                        type: NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_COUNT,
                        payload: newNotificationCount - removeNotificationCount
                    })
                }
                quizNotificationsIds.push(notificationId)
            }
        })
        if (formNotificationsIds.length > 0) {
            deleteNotification({notification_ids: formNotificationsIds}, () => {
            }, () => {
            })
        } else if (quizNotificationsIds.length > 0) {
            deleteQuizNotification({notification_ids: quizNotificationsIds}, () => {
            }, () => {
            })
        }
    }

    let formTheme = (formData.theme === 'classic_theme') ? 'classic_new.jpeg' : (formData.theme === 'dark_theme') ? 'dark.jpeg' : formData.theme;
    // Set background CSS value based on whether the Theme is an AWS URL
    let backgroundTheme = formTheme.startsWith('http') ? formTheme : isMobileOnly ?
        (props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) ? 'https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/images/thumbnail_web/' + formTheme :
            'https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/images/thumbnail_512_342/' + formTheme :
        'https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/images/thumbnail_web/' + formTheme
    const handleMultipleFormSelect = e => {
        e.stopPropagation();

        if (!formData.is_valid) {
            setShowAcknowledgement(true)
            return
        }

        if (showForms) {
            if (multipleFormsSelectedArray.indexOf(formData._id) !== -1) {
                // Remove the clicked Form
                let multipleForqSelectedArray = [...multipleFormsSelectedArray]

                multipleForqSelectedArray.splice(multipleForqSelectedArray.indexOf(formData._id), 1)
                dispatch({
                    type: FORMS_ACTION_TYPE.SET_MULTIPLE_FORMS_SELECTED_ARRAY,
                    payload: multipleForqSelectedArray
                })
            } else {
                // Add the clicked Form
                dispatch({
                    type: FORMS_ACTION_TYPE.SET_MULTIPLE_FORMS_SELECTED_ARRAY,
                    payload: [...multipleFormsSelectedArray, formData._id]
                })
            }
            dispatch({
                type: FORMS_ACTION_TYPE.SET_SHOW_MULTIPLE_DELETE_OPTION,
                payload: multipleFormsSelectedArray.length > 0
            })
        } else if (showQuiz) {
            if (multipleQuizzesSelectedArray.indexOf(formData._id) !== -1) {
                // Remove the clicked Quiz
                let multipleForqSelectedArray = [...multipleQuizzesSelectedArray]

                multipleForqSelectedArray.splice(multipleForqSelectedArray.indexOf(formData._id), 1)
                dispatch({
                    type: QUIZZES_ACTION_TYPE.SET_MULTIPLE_QUIZZES_SELECTED_ARRAY,
                    payload: multipleForqSelectedArray
                })
            } else {
                // Add the clicked Quiz
                dispatch({
                    type: QUIZZES_ACTION_TYPE.SET_MULTIPLE_QUIZZES_SELECTED_ARRAY,
                    payload: [...multipleQuizzesSelectedArray, formData._id]
                })
            }
            dispatch({
                type: QUIZZES_ACTION_TYPE.SET_SHOW_MULTIPLE_DELETE_OPTION,
                payload: multipleQuizzesSelectedArray.length > 0
            })
        }
    };

    const showMultipleDeleteOption = showForms ? multipleFormsSelectedArray?.length > 0 : showQuiz ? multipleQuizzesSelectedArray?.length > 0 : false
    const cardStyle = {
        justifyContent: "space-evenly"
    }
    const clearLongPressEvent = () => {
        if (props.timeOutForLongPress?.current) {
            clearTimeout(props.timeOutForLongPress.current);
            props.timeOutForLongPress.current = undefined;
            return false;
        }
    }

    const setLongPressEvent = (e) => {
        if (props.timeOutForLongPress) {
            // props.timeOutForLongPress.current = setTimeout(() => {
            //     handleMultipleFormSelect(e);
            // }, 350);
            return false;
        }
    }

    const handleCardClick = (e) => {
        e.stopPropagation()
        if ((multipleFormsSelectedArray?.length && showForms) || (multipleQuizzesSelectedArray?.length && showQuiz)) {
            return;
        }
        if (showShared) {
            deleteNotificationHandler()
            if (PremiumJSON[subscriptionData?.subscription_plan]?.rank < formData.subscription_data?.min_subscription_required?.rank) {
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_EDIT_PREMIUM_POP_UP, payload: true})
                return
            }
            if (formData.response_count === 0 && props.showEdit) {
                dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.SAVED})
                dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: formData.theme})
                dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
                dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: showShared})
                dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: formData.is_quiz})
                dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
            } else if (formData.response_count > 0) {
                dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHEET_CONNECTED, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SUMMARY_DATA_SET, payload: null})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA, payload: null})
                dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
                dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: formData.is_quiz})
                dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.responses})
            } else if (formData.response_count === 0) {
                setShowViewerPopUp(true)
            }
        } else {
            if (formData.response_count > 0) {
                dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHEET_CONNECTED, payload: false})
                dispatch({type: RESPONSES_ACTION_TYPE.SUMMARY_DATA_SET, payload: null})
                dispatch({type: RESPONSES_ACTION_TYPE.SET_FORM_RESPONSES_DATA, payload: null})
                dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
                dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: formData.is_quiz})
                dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.responses})
            } else {
                if (formData.response_count === 0) {
                    if (PremiumJSON[subscriptionData?.subscription_plan]?.rank < formData.subscription_data?.min_subscription_required?.rank) {
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_EDIT_PREMIUM_POP_UP, payload: true})
                        return
                    }
                    dispatch({
                        type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE,
                        payload: BUILDER_CREATION_SOURCE.SAVED
                    })
                    dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: formData.theme})
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
                    dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: showShared})
                    dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: formData.is_quiz})
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
                }
            }
        }
    };

    const handleOutSideClick = () => {
        document.onmousedown = e => {
            e.stopPropagation();
            if (ref.current && !ref.current.contains(e.target)) {
                document.onmousedown = null;
                setShowFormControl(false);
            }
        };
    }

    return (
        <>
            {(props.formData !== null) ?
                <div className={'form-list-card form-card-on-hover'}
                     id={'form-card-' + props.cardIndex}
                     onClick={handleCardClick}
                     onTouchStart={setLongPressEvent}
                     onMouseDown={setLongPressEvent}
                     onTouchMove={clearLongPressEvent}
                     onTouchEnd={clearLongPressEvent}
                     onMouseUp={clearLongPressEvent}
                     onMouseEnter={() => setShowHoverMenu(true)}
                     onMouseLeave={() => setShowHoverMenu(false)}
                     style={cardStyle}>

                    {isMobileOnly ? null : <img src={backgroundTheme} alt={"theme"}
                                                style={{
                                                    borderRadius: "100px",
                                                    margin: "10px",
                                                    width: "50px",
                                                    height: "50px"
                                                }}/>}

                    <div id={'list-card-title-' + props.cardIndex} className="list-card-title"
                         style={{width: isMobileOnly ? "65%" : formData?.is_quiz ? "30%" : null}}>
                        <p id={'card-form-title'} className="list-card-form-title"
                           style={(props.CARD_TYPE === CARD_TYPE.USER_FORM) ? null :
                               (props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) ? {width: '100%'} : null}>
                            {formData.welcome_screen.title}
                        </p>
                        {(props.CARD_TYPE === CARD_TYPE.USER_FORM) ?
                            <p id={'form-modified-date'} className='list-secondary-data'>
                                {isMobileOnly ?
                                    getResponseCountText(formData.response_count, formData.is_quiz) + ' - ' + getDate(formData.date_edited) : getDate(formData.date_edited)}
                            </p> :
                            (props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) ?
                                <p className='list-secondary-data'
                                   style={{width: '100%'}}>{formData.welcome_screen.description}</p>
                                : null}
                    </div>

                    {isMobileOnly ?
                        <div style={{width: "25%", display: "flex", flexDirection: "column", alignItems: "end"}}>
                            <div style={{display: "flex", height: "50%"}}>
                                {showForms && multipleFormsSelectedArray?.length > 0 && formData.is_valid ?
                                    <div
                                        className={multipleFormsSelectedArray.indexOf(formData._id) !== -1 ? 'multiple-select-tag-for-delete selected-for-delete' : 'multiple-select-tag-for-delete'}
                                        style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') && isMobileOnly ? {right: "90%"} : null}
                                        onClick={handleMultipleFormSelect}>
                                        {multipleFormsSelectedArray.indexOf(formData._id) !== -1 ?
                                            <img style={{width: '120%', height: '120%'}} alt='select'
                                                 src={multiselectImg}/> : null}
                                    </div>
                                    : null}
                                {showQuiz && multipleQuizzesSelectedArray?.length > 0 && formData.is_valid ?
                                    <div
                                        className={multipleQuizzesSelectedArray.indexOf(formData._id) !== -1 ? 'multiple-select-tag-for-delete selected-for-delete' : 'multiple-select-tag-for-delete'}
                                        style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') && isMobileOnly ? {right: "90%"} : null}
                                        onClick={handleMultipleFormSelect}>
                                        {multipleQuizzesSelectedArray.indexOf(formData._id) !== -1 ?
                                            <img style={{width: '120%', height: '120%'}} alt='select'
                                                 src={multiselectImg}/> : null}
                                    </div>
                                    : null}
                                {!formData?.setting.is_active ?
                                    <div id={'mobile-form-control-status'} className='status-text-box'
                                         style={{
                                             alignSelf: "center",
                                             border: "none",
                                             height: "fit-content",
                                             width: isMobileOnly ? "50px" : "80px"
                                         }}>
                                <span className='form-status-text' style={{color: 'red', fontSize: "10px"}}>
                                    {t(translationKeys.inactive)}
                                </span>
                                    </div> : null}
                                {showShared || !formData.date_favoured ? null : <div style={{alignSelf: "center"}}>
                                    <img id={'favourite' + props.cardIndex}
                                         className={'list-hover-image'}
                                         src={"images/favourite_blue.svg"}
                                         onClick={e => {
                                             e.stopPropagation();
                                             props.favouriteClicked(formData._id, formData.date_favoured);
                                         }}
                                         alt="favorite"
                                    />
                                </div>}
                            </div>
                            <div style={{display: "flex", height: "50%"}}>
                                {formData?.is_quiz ? <div style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    textAlign: "center"
                                }}>
                                    <img alt='publish'
                                         style={{margin: '0 4px'}}
                                         src={formData.is_publish ? '/images/cloud_success.svg' : '/images/cloud_error.svg'}
                                    />
                                    {isMobileOnly ?
                                        <span style={{whiteSpace: "nowrap", fontSize: "10px"}}>
                                            {formData.is_publish ? t(translationKeys.published) : t(translationKeys.unpublished)}
                                        </span> : null}
                                </div> : null}

                                {formData.collaborators?.length > 0 ? <div style={{
                                    alignSelf: "center",
                                    width: "5%",
                                    margin: "0 5px",
                                    textAlign: formData?.is_quiz ? "start" : "end"
                                }}>
                                    <img src={collabIcon} alt={'collab_icon'} style={{width: '25px'}}/>
                                </div> : null}
                            </div>
                        </div> :
                        <>
                            <div style={{
                                alignSelf: "center",
                                color: "#004BA0",
                                fontSize: "20px",
                                width: "10%",
                                textAlign: "center"
                            }}>
                                {formData.response_count}
                            </div>

                            <div id={'mobile-form-control-status'} className='status-text-box'
                                 style={{
                                     borderColor: formData?.setting.is_active ? 'green' : "red",
                                     alignSelf: "center",
                                     height: "fit-content",
                                     width: "80px"
                                 }}>
                                <span className='form-status-text' style={formData?.setting.is_active ? {color: 'green'}
                                    : {color: 'red'}}>{formData?.setting.is_active ? t(translationKeys.active).toUpperCase()
                                    : t(translationKeys.inactive).toUpperCase()}</span>
                            </div>

                            {formData?.is_quiz ? <div style={{
                                alignSelf: "center",
                                width: "10%",
                                textAlign: "center"
                            }}>
                                <img alt='publish'
                                     style={{margin: '0 4px'}}
                                     src={formData.is_publish ? '/images/cloud_success.svg' : '/images/cloud_error.svg'}
                                />
                            </div> : null}

                            <div style={{
                                alignSelf: "center",
                                width: "5%",
                                textAlign: formData?.is_quiz ? "start" : "end",
                                visibility: formData.collaborators?.length > 0 ? "visible" : "hidden"
                            }}>
                                <img src={collabIcon} alt={'collab_icon'} style={{width: '25px'}}/>
                            </div>

                            {/***top left and right tags***/}
                            <div style={isShowUnavailableFeature(formData) ? {display: "none"} : null}>
                                {(props.CARD_TYPE === CARD_TYPE.USER_FORM) ?
                                    <>
                                        {/*** Multiple Selection Tag ***/}
                                        {showForms && multipleFormsSelectedArray?.length > 0 && formData.is_valid ?
                                            <div
                                                className={multipleFormsSelectedArray.indexOf(formData._id) !== -1 ? 'multiple-select-tag-for-delete selected-for-delete' : 'multiple-select-tag-for-delete'}
                                                style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') && isMobileOnly ? {right: "90%"} : null}
                                                onClick={handleMultipleFormSelect}>
                                                {multipleFormsSelectedArray.indexOf(formData._id) !== -1 ?
                                                    <img style={{width: '120%', height: '120%'}} alt='select'
                                                         src={multiselectImg}/> : null}
                                            </div>
                                            : null}
                                        {showQuiz && multipleQuizzesSelectedArray?.length > 0 && formData.is_valid ?
                                            <div
                                                className={multipleQuizzesSelectedArray.indexOf(formData._id) !== -1 ? 'multiple-select-tag-for-delete selected-for-delete' : 'multiple-select-tag-for-delete'}
                                                style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') && isMobileOnly ? {right: "90%"} : null}
                                                onClick={handleMultipleFormSelect}>
                                                {multipleQuizzesSelectedArray.indexOf(formData._id) !== -1 ?
                                                    <img style={{width: '120%', height: '120%'}} alt='select'
                                                         src={multiselectImg}/> : null}
                                            </div>
                                            : null}
                                    </>
                                    : (props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) ?
                                        <div className="form-card-top-right-tag"
                                             style={isMobileOnly ? {fontSize: '12px'} : null}>
                                            <span>{formData.pages[0].questions.length ? formData.pages[0].questions.length : 0 + ''} </span>
                                            <span>{t(translationKeys.questions)}</span>
                                        </div> : null}
                            </div>

                            {/***on hover layer***/}
                            <div style={{
                                visibility: (props.CARD_TYPE === CARD_TYPE.USER_FORM && !isMobileOnly && !showMultipleDeleteOption && showHoverMenu && formData.is_valid && !isShowUnavailableFeature(formData)) ? "visible" : "hidden",
                                display: "contents"
                            }}>
                                <HoverControls {...props} formData={formData}
                                               listView={true}
                                               deleteNotificationHandler={deleteNotificationHandler}
                                               removeCollaborator={props.removeCollaborator}
                                               cardIndex={props.cardIndex}
                                               favouriteClicked={props.favouriteClicked}
                                               showEdit={props.showEdit}
                                               previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                               showMultipleDeleteOption={showMultipleDeleteOption}
                                               handleMultipleFormSelect={handleMultipleFormSelect}
                                               showDataEditWarning={props.showDataEditWarning}/>
                            </div>

                            {showShared ? null : <div style={{visibility: !formData.is_valid ? "hidden" : null, alignSelf: "center"}}>
                                <img id={'favourite' + props.cardIndex}
                                     className={props.listView ? 'list-hover-image' : 'hover-image'}
                                     src={formData.date_favoured ? "images/favourite_blue.svg" : "images/non_favourite.svg"}
                                     onClick={e => {
                                         e.stopPropagation();
                                         props.favouriteClicked(formData._id, formData.date_favoured);
                                     }}
                                     alt="favorite"
                                />
                            </div>}

                            <div style={{visibility: !formData.is_valid ? "hidden" : null, alignSelf: "center", width: "6%", textAlign: showShared ? "center" : "start"}}
                                 ref={ref}>
                                <img id={'more' + props.cardIndex}
                                     className={props.listView ? 'list-hover-image' : 'hover-image'}
                                     src={moreIcon}
                                     onClick={e => {
                                         positionRef.current = getPopUpElementPositionStyles(330, 330, e)
                                         if (props.previouslyOpenedDropDownRef.current && !showFormControl) {
                                             handlePreviouslyOpenedSortCard(props.previouslyOpenedDropDownRef.current);
                                         }
                                         props.previouslyOpenedDropDownRef.current = {
                                             element: ref.current,
                                         };
                                         showFormControl ? setShowFormControl(false) : setShowFormControl(true);
                                         showFormControl ? document.onmousedown = null : handleOutSideClick();
                                         e.stopPropagation();
                                     }}
                                     alt="more"
                                />
                                <div id={'form-control-drop-down-container'}
                                     className="form-control-drop-down-container"
                                     style={{
                                         height: "auto",
                                         top: position && position.top ? position.top : null,
                                         bottom: position && position.bottom ? position.bottom : null,
                                         right: "150px",
                                         left: position && position.left ? position.left : null,
                                         display: showFormControl ? 'inline-block' : 'none',
                                         width: selectedLanguage === "ml" || selectedLanguage === "ta" || selectedLanguage === "es" ? "330px" : null
                                     }}>
                                    {showFormControl ? <FormControlContainer formData={formData}
                                                                             cardIndex={props.cardIndex}
                                                                             showEdit={props.showEdit}
                                                                             favouriteClicked={props.favouriteClicked}
                                                                             previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                                                             setShowFormControl={setShowFormControl}
                                                                             showDataEditWarning={props.showDataEditWarning}
                                                                             updateStatus={props.updateStatus}
                                    /> : null}
                                </div>
                            </div>
                        </>}

                    {/*** Invalid form layer ***/}
                    {(props.CARD_TYPE === CARD_TYPE.USER_FORM) && !formData.is_valid ?
                        <div className='inValid-form-layer'
                             onClick={e => {
                                 e.stopPropagation();
                                 setShowUnApprovedFormDialog(true);
                             }}>
                        </div> : null}

                    {/*** Versioning form layer ***/}
                    {(props.CARD_TYPE === CARD_TYPE.USER_FORM) && isShowUnavailableFeature(formData) ?
                        <div className={'versioning-form-layer'}
                             onClick={e => {
                                 e.stopPropagation();
                                 setShowVersioningFormDialog(true);
                             }}>
                            <div className="form-card-top-left-tag-container"
                                 style={{
                                     top: "25px",
                                     left: "auto",
                                     right: "25px",
                                     background: "#1976d2",
                                     borderRadius: "50px",
                                     padding: "5px 10px"
                                 }}>
                                <img src={warningIcon} alt={"unavailable"} style={{width: "14px"}}/>
                                <span style={{
                                    paddingLeft: "2.5px",
                                    fontSize: "14px",
                                    color: "#FFFFFF"
                                }}>Unavailable</span>
                            </div>
                        </div> : null}

                    {/*** Multiple forms selection layer ***/}
                    {showMultipleDeleteOption ? <div className='multiple-select-layer'
                                                     onClick={handleMultipleFormSelect}/> : null}
                </div> : null}

            {/*** Toast message ***/}
            {showAcknowledgement ? <Message width={'250px'}
                                            message={formData.is_quiz ? t(translationKeys.invalidated_quiz_cannot_be_selected) : t(translationKeys.invalidated_form_cannot_be_selected)}
                                            close={() => setShowAcknowledgement(false)}
                                            setShowAcknowledgement={setShowAcknowledgement}/> : null}

            {/*** Unapproved form alert dialog ***/}
            {showUnApprovedFormDialog ?
                <ModalDialog
                    header={`${formData.is_quiz ? t(translationKeys.unapproved_quiz) : t(translationKeys.unapproved_form)}`}
                    body={[<span
                        style={{fontWeight: 'bold'}}>{'"' + (formData?.welcome_screen?.title ? formData.welcome_screen.title : '') + '"'}</span>,
                        <br/>, (formData.is_quiz ? t(translationKeys.unapproved_quiz_alert) : t(translationKeys.unapproved_form_alert))]}
                    buttons={
                        [
                            {
                                text: t(translationKeys.close),
                                action: () => setShowUnApprovedFormDialog(false)
                            },
                            {
                                text: t(translationKeys.provide_explanation),
                                action:
                                    () => {
                                        setShowUnApprovedFormDialog(false);
                                        const data = JSON.stringify([{
                                            qid: '5eb4e0c90be7456b687e61fd',
                                            data: formData?.welcome_screen?.title ? formData.welcome_screen.title : ''
                                        }, {
                                            qid: '5eb3d579c411ae0a298e7a0a',
                                            data: ((formData.is_quiz ? (QUIZ_BASE_URL + '/id/') : (location.origin + '/form/')) + (formData?._id ? formData._id : ''))
                                        }, {
                                            qid: '5eb3d579c411ae0a298e7a0b',
                                            data: userId
                                        }, {
                                            qid: '5ec4ec0cc9dff62eb822a839',
                                            data: getBlockedWordsText(formData.blocked_words_found)
                                        }]);
                                        const formId = formData.is_quiz ? '5f211698b770fe0f8c274122' : '5eb3d579c411ae0a298e7a08';
                                        const domainName = location.href.split('/').slice(0, 3).join('/');
                                        open(encodeURI(domainName + '/form/' + formId + '?prefilleddata=' + data + '#form/0'));
                                    }
                            }
                        ]
                    }
                /> : null}

            {/*** Versioning form alert dialog ***/}
            {showVersioningFormDialog ?
                <ModalDialog
                    header={t(translationKeys.unavailable)}
                    imageIcon={warningIcon}
                    body={formData.is_quiz ? [t(translationKeys.unavailable_quiz_features_description)] : [t(translationKeys.unavailable_features_description)]}
                    buttons={
                        [
                            {
                                text: t(translationKeys.ok),
                                action: () => setShowVersioningFormDialog(false)
                            }
                        ]
                    }
                /> : null}

            {showViewerPopUp ? <ModalDialog
                header={(formData.is_quiz ? t(translationKeys.quiz_builder_access_denied) : t(translationKeys.form_builder_access_denied))}
                body={[(formData.is_quiz ? t(translationKeys.you_only_have_viewer_rights_for_quiz) : t(translationKeys.you_only_have_viewer_rights_for_form)) + ' ' + (formData.is_quiz ? t(translationKeys.kindly_contact_the_quiz_owner_for_more_info) : t(translationKeys.kindly_contact_the_form_owner_for_more_info))]}
                buttons={[{
                    text: t(translationKeys.ok),
                    action: () => {
                        setShowViewerPopUp(false)
                    }
                }]}/> : null}

        </>
    )
}

export default FormListCard;

const getResponseCountText = (responseCount, isQuiz) => {
    return (
        responseCount ?
            (responseCount + (isQuiz ? responseCount > 1 ? ' ' + t(translationKeys.answers) : ' ' + t(translationKeys.answer_s) : responseCount > 1 ? ' ' + t(translationKeys.responses).toLowerCase() : ' ' + t(translationKeys.response))) : (isQuiz ? '0 ' + t(translationKeys.answer_s) : '0 ' + t(translationKeys.response))
    )
}