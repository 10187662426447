import React, {useState} from 'react';
import "../css/premium-plans-popup.css"
import close from "../../../../public/images/close_black.svg";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import {useDispatch, useSelector} from "react-redux";
import PremiumPlanDetail from "./PremiumPlanDetail";
import {PREMIUM_PLAN_KEYS, PremiumJSON} from "../../../shared/utils/helper";
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton";
import {cancelSubscription, getSubscriptionId, verifyPayment} from "../../../shared/utils/sash-v2-api";
import usePremiumMgr from "../../hooks/usePremiumMgr";
import ModalDialog from "../../../shared/components/ModalDialog";
import Loader from "../../../shared/components/Loader";
import PaymentSuccessPopUp from "./PaymentSuccessPopUp";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

const PremiumPlansPopUp = () => {
    const {t} = useTranslation()
    const {displayRazorpay} = usePremiumMgr()
    const [showLoading, setShowLoading] = useState()
    const [yearly, setYearly] = useState(true)
    const [showChangeUPI, setShowChangeUPI] = useState()
    const [showCancelPopUp, setShowCancelPopUp] = useState()
    const [showCanceledPlanPopUp, setShowCanceledPlanPopUp] = useState()
    const [showPaymentSuccessPopup, setShowPaymentSuccessPopup] = useState()
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const dispatch = useDispatch()

    const handleCloseClick = () => {
        dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP, payload: false})
    }

    const verifyRazorpayPayment = (res) => {
        const successFunction = (res) => {
            if (res?.result) {
                setShowLoading(false)
                setShowPaymentSuccessPopup(true)
            }
        }
        const failureFunction = (err) => {
            console.log(err)
            alert('Something went wrong!')
        }
        setShowLoading(true)
        if (res?.razorpay_payment_id && res?.razorpay_signature && res?.razorpay_subscription_id) {
            verifyPayment(res.razorpay_subscription_id, res.razorpay_payment_id, res.razorpay_signature, successFunction, failureFunction)
        }
    }

    const successFunction = (res) => {
        if (res?.result) {
            displayRazorpay(res.subscription_id, true, verifyRazorpayPayment)
        }
    }

    return (
        <div className={"premium-plans-popup"}>
            <div className={"premium-pop-up"}>
                <div className={"premium-pop-up-header"}>
                    <div style={{width: "100%", textAlign: "center", borderBottom: "1px solid #E0E0E0"}}>
                        <span>{t(translationKeys.premium_plans)}</span>
                        <p>{t(translationKeys.explore_text)}</p>
                    </div>
                    <img className={'localization-close-img'} style={{margin: "0 0 auto 0"}}
                         src={close} alt={close} onClick={handleCloseClick}/>
                </div>
                <div className={"yearly-monthly"}>
                    <span style={yearly ? {backgroundColor: "#1976D2", color: "#FFFFFF"} : null}
                          onClick={() => setYearly(true)}>{t(translationKeys.yearly)}</span>
                    <span style={!yearly ? {backgroundColor: "#1976D2", color: "#FFFFFF"} : null}
                          onClick={() => setYearly(false)}>{t(translationKeys.monthly)}</span>
                </div>
                <p className={"save-upto"} style={{visibility: !yearly ? "hidden" : null}}>
                    {t(translationKeys.save_upto_15)}
                </p>
                {subscriptionData.subscription_plan === "premium-on-hold" ?
                    <div style={{display: "flex", justifyContent: "end"}}>
                        <div style={{borderRight: "1px solid #8E8E8E", padding: "0 10px"}}>
                            <MaterialButton
                                data={{
                                    title: "UPDATE PAYMENT DETAILS",
                                    customButtonContainerStyle: {
                                        borderRadius: '50px',
                                        backgroundColor: '#004BA0',
                                        width: "300px",
                                        height: "40px"
                                    },
                                    customButtonTextStyle: {
                                        fontFamily: 'Nunito Sans, Sans-serif, serif',
                                        fontSize: '20px'
                                    }
                                }}
                                handleClick={() => {
                                    if (subscriptionData.subscription_platform === "WEBAPP") {
                                        getSubscriptionId(successFunction, (err) => console.log(err))
                                    } else {
                                        dispatch({
                                            type: PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP,
                                            payload: true
                                        })
                                    }
                                }}
                            />
                        </div>
                        <div style={{padding: "0 10px"}}>
                            <MaterialButton
                                data={{
                                    title: t(translationKeys.cancel_subscription).toUpperCase(),
                                    buttonType: ButtonTypes.OUTLINED,
                                    customButtonContainerStyle: {
                                        borderRadius: '50px',
                                        width: "300px",
                                        height: "40px"
                                    },
                                    customButtonTextStyle: {
                                        fontFamily: 'Nunito Sans, Sans-serif, serif',
                                        fontSize: '20px'
                                    }
                                }}
                                handleClick={() => {
                                    setShowCancelPopUp(true)
                                }}
                            />
                        </div>
                    </div> : null}
                <div className={"premium-pop-up-body"}>
                    <PremiumPlanDetail
                        premiumPlanData={yearly ? PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_YEARLY] : PremiumJSON[PREMIUM_PLAN_KEYS.STARTER_MONTHLY]}
                        premiumPlan={yearly ? PREMIUM_PLAN_KEYS.STARTER_YEARLY : PREMIUM_PLAN_KEYS.STARTER_MONTHLY} showCancelPopUp={showCancelPopUp}
                        setShowCancelPopUp={setShowCancelPopUp} setShowChangeUPI={setShowChangeUPI}
                        setShowLoading={setShowLoading} setShowCanceledPlanPopUp={setShowCanceledPlanPopUp}
                        yearly={yearly}/>
                    <PremiumPlanDetail
                        premiumPlanData={yearly ? PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_YEARLY] : PremiumJSON[PREMIUM_PLAN_KEYS.BRONZE_MONTHLY]}
                        premiumPlan={yearly ? PREMIUM_PLAN_KEYS.BRONZE_YEARLY : PREMIUM_PLAN_KEYS.BRONZE_MONTHLY} showCancelPopUp={showCancelPopUp}
                        setShowCancelPopUp={setShowCancelPopUp} setShowChangeUPI={setShowChangeUPI}
                        setShowLoading={setShowLoading} setShowCanceledPlanPopUp={setShowCanceledPlanPopUp}
                        yearly={yearly}/>
                    <PremiumPlanDetail
                        premiumPlanData={yearly ? PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_YEARLY] : PremiumJSON[PREMIUM_PLAN_KEYS.SILVER_MONTHLY]}
                        premiumPlan={yearly ? PREMIUM_PLAN_KEYS.SILVER_YEARLY : PREMIUM_PLAN_KEYS.SILVER_MONTHLY}
                        showCancelPopUp={showCancelPopUp} setShowCancelPopUp={setShowCancelPopUp}
                        setShowChangeUPI={setShowChangeUPI} setShowLoading={setShowLoading}
                        setShowCanceledPlanPopUp={setShowCanceledPlanPopUp} yearly={yearly}/>
                    <PremiumPlanDetail
                        premiumPlanData={yearly ? PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_YEARLY] : PremiumJSON[PREMIUM_PLAN_KEYS.GOLD_MONTHLY]}
                        premiumPlan={yearly ? PREMIUM_PLAN_KEYS.GOLD_YEARLY : PREMIUM_PLAN_KEYS.GOLD_MONTHLY}
                        showCancelPopUp={showCancelPopUp} setShowCancelPopUp={setShowCancelPopUp}
                        setShowChangeUPI={setShowChangeUPI} setShowLoading={setShowLoading}
                        setShowCanceledPlanPopUp={setShowCanceledPlanPopUp} yearly={yearly}/>
                </div>
            </div>
            {showLoading ? <Loader height='100%' width='100%' left={"0"} zIndex={"11"}/> : null}
            {showPaymentSuccessPopup ? <PaymentSuccessPopUp success={true}
                                                            title={subscriptionData.subscription_plan !== PREMIUM_PLAN_KEYS.FREE_USER ? t(translationKeys.card_details_updated_successfully) : t(translationKeys.payment_successful)}
                                                            description={subscriptionData.subscription_plan !== PREMIUM_PLAN_KEYS.FREE_USER ? t(translationKeys.card_details_updated_successfully_description) : t(translationKeys.payment_successful_description)}
                                                            handlePopUpClick={() => {
                                                                setShowPaymentSuccessPopup(false)
                                                                window.location.reload()
                                                            }}/> : null}
            {showChangeUPI ? <ModalDialog header={t(translationKeys.plan_change_restrictions).toUpperCase()}
                                          body={[t(translationKeys.plan_change_restriction_desc_1), t(translationKeys.plan_change_restriction_desc_2)]}
                                          buttons={[
                                              {
                                                  text: t(translationKeys.close).toUpperCase(),
                                                  action: () => {
                                                      setShowChangeUPI(false)
                                                  }
                                              },
                                              {
                                                  text: t(translationKeys.update_card_details).toUpperCase(),
                                                  action: () => {
                                                      setShowChangeUPI(false)
                                                      getSubscriptionId(successFunction, (err) => console.log(err))
                                                  }
                                              }
                                          ]}
            /> : null}
            {showCancelPopUp ? <ModalDialog header={t(translationKeys.cancel_subscription).toUpperCase()}
                                            body={[t(translationKeys.cancel_subscription_description)]}
                                            buttons={[
                                                {
                                                    text: t(translationKeys.no).toUpperCase(),
                                                    action: () => {
                                                        setShowCancelPopUp(false)
                                                    }
                                                },
                                                {
                                                    text: t(translationKeys.yes).toUpperCase(),
                                                    action: () => {
                                                        setShowLoading(true)
                                                        setShowCancelPopUp(false)
                                                        cancelSubscription((res) => {
                                                            if (res?.result) {
                                                                setShowCanceledPlanPopUp(true)
                                                                setShowLoading(false)
                                                            }
                                                        }, (err) => {
                                                            console.log(err)
                                                        })
                                                    }
                                                }
                                            ]}
            /> : null}
            {showCanceledPlanPopUp ? <ModalDialog header={t(translationKeys.cancel_plan).toUpperCase()}
                                                  body={[t(translationKeys.cancel_plan_desc)]}
                                                  buttons={[
                                                      {
                                                          text: t(translationKeys.done).toUpperCase(),
                                                          action: () => {
                                                              setShowCanceledPlanPopUp(false)
                                                              window.location.reload()
                                                          }
                                                      }
                                                  ]}
            /> : null}
        </div>
    )
}

export default PremiumPlansPopUp
