import React, {useState} from 'react'
import "../css/premium-plan-detail.css"
import cross from '../../../../public/images/cross_red.svg'
import goldBackground from '../../../../public/images/Gold_background.svg'
import bronzeBackground from '../../../../public/images/Bronze_background.svg'
import silverBackground from '../../../../public/images/Silver_background.svg'
import blueTick from "../../../../public/images/tick_blue.svg"
import whiteTick from "../../../../public/images/icons/tick_white_mark.svg";
import MaterialButton from "../../../shared/components/MaterialButton";
import {formatBytes, getPlanBasedOnRank, PREMIUM_PLAN_KEYS, PremiumJSON} from "../../../shared/utils/helper";
import PayNowPopUp from "./PayNowPopUp";
import {
    createSubscriptionId,
    getSubscriptionId,
    upgradeSubscriptionPlan
} from "../../../shared/utils/sash-v2-api";
import {useSelector} from "react-redux";
import ModalDialog from "../../../shared/components/ModalDialog";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

const PremiumPlanDetail = (props) => {
    const {t} = useTranslation()
    const [showPayNow, setShowPayNow] = useState()
    const [subscriptionId, setSubscriptionId] = useState()
    const [showUpgradePopUp, setShowUpgradePopUp] = useState()
    const [showUpgradedPlanPopUp, setShowUpgradedPlanPopUp] = useState()
    const [showCrossPlatformPopUp, setShowCrossPlatformPopUp] = useState()
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)

    const getSubscribeButtonText = () => {
        if (subscriptionData.subscription_plan === "premium-on-hold") {
            return t(translationKeys.subscribe).toUpperCase()
        } else if (PremiumJSON[subscriptionData.subscription_plan].rank === props.premiumPlanData.rank && subscriptionData.subscription_plan === props.premiumPlan && subscriptionData.is_canceled) {
            return t(translationKeys.expired_on_next_renewal)
        } else if (PremiumJSON[subscriptionData.subscription_plan].rank === props.premiumPlanData.rank && subscriptionData.subscription_plan === props.premiumPlan) {
            return t(translationKeys.cancel_subscription)
        } else if (subscriptionData.subscription_plan !== PREMIUM_PLAN_KEYS.FREE_USER && !subscriptionData.is_canceled) {
            if (props.premiumPlanData.rank >= PremiumJSON[subscriptionData.subscription_plan].rank) {
                return t(translationKeys.upgrade_plan)
            } else {
                return t(translationKeys.downgrade_plan)
            }
        } else {
            return t(translationKeys.subscribe).toUpperCase()
        }
    }

    const successFunction = (res) => {
        if (res?.result) {
            setSubscriptionId(res.subscription_id)
            setShowPayNow(true)
            props.setShowLoading(false)
        }
    }
    const failureFunction = (err) => {
        if (err?.data?.result === "subscriptions cannot be updated when payment mode is upi") {
            props.setShowLoading(false)
            props.setShowChangeUPI(true)
        } else {
            alert('Something went wrong!')
        }
    }

    const handleCardClick = () => {
        if (subscriptionData.subscription_plan === PREMIUM_PLAN_KEYS.FREE_USER || subscriptionData.subscription_platform === "WEBAPP") {
            if (props.premiumPlan === PREMIUM_PLAN_KEYS.FREE_USER) {
                return null
            } else if (subscriptionData.subscription_plan !== PREMIUM_PLAN_KEYS.FREE_USER && subscriptionData.is_canceled) {
                props.setShowCanceledPlanPopUp(true)
            } else if (!subscriptionData || subscriptionData.subscription_plan === PREMIUM_PLAN_KEYS.FREE_USER) {
                props.setShowLoading(true)
                createSubscriptionId(props.premiumPlan, successFunction, failureFunction)
            } else {
                getSubscriptionId(successFunction, failureFunction)
            }
        } else {
            setShowCrossPlatformPopUp(true)
        }
    }

    const handleButtonClick = (e) => {
        e.stopPropagation()
        if (subscriptionData.subscription_plan === PREMIUM_PLAN_KEYS.FREE_USER || subscriptionData.subscription_platform === "WEBAPP") {
            if (subscriptionData.subscription_plan === "premium-on-hold") {
                getSubscriptionId(successFunction, failureFunction)
            } else if (subscriptionData.subscription_plan === props.premiumPlan && subscriptionData.is_canceled) {
                props.setShowCanceledPlanPopUp(true)
            } else if (subscriptionData.subscription_plan === props.premiumPlan) {
                props.setShowCancelPopUp(true)
            } else if (subscriptionData.subscription_plan !== PREMIUM_PLAN_KEYS.FREE_USER) {
                setShowUpgradePopUp(true)
            } else {
                props.setShowLoading(true)
                createSubscriptionId(props.premiumPlan, successFunction, failureFunction)
            }
        } else {
            setShowCrossPlatformPopUp(true)
        }
    }

    const getHeaderBackgroundBasedOnRank = (rank) => {
        switch (rank) {
            case 0:
                return {backgroundColor: "#FFFFFF"}
            case 1:
                return {backgroundImage: `url(${bronzeBackground})`}
            case 2:
                return {backgroundImage: `url(${silverBackground})`}
            case 3:
                return {backgroundImage: `url(${goldBackground})`}
            default:
                return {backgroundColor: "#FFFFFF"}
        }
    }

    return (
        <>
            <div className={"premium-plan-detail"} onClick={handleCardClick}>
                <div className={"plan-detail"} style={{
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.25)",
                    cursor: props.premiumPlan === PREMIUM_PLAN_KEYS.FREE_USER ? "default" : null,
                    border: subscriptionData.subscription_plan === props.premiumPlan && props.premiumPlan !== PREMIUM_PLAN_KEYS.FREE_USER ? "1px solid #004BA0" : null
                }}>
                    <div className={"plan-detail-header"}
                         style={getHeaderBackgroundBasedOnRank(props.premiumPlanData.rank)}>
                        <h3 style={{color: props.premiumPlanData.rank >= 1 ? "#FFFFFF" : "#004BA0"}}>{props.premiumPlanData.price ? getPlanBasedOnRank(props.premiumPlanData.rank) : "Free"}</h3>
                        <span
                            style={{color: props.premiumPlanData.rank >= 1 ? "#FFFFFF" : "#004BA0"}}>{props.premiumPlanData.price ? "₹ " + props.premiumPlanData.price.toLocaleString() + ` / ${props.yearly ? "Year" : "Month"}` : "Lifetime"}</span>
                        {PremiumJSON[subscriptionData.subscription_plan].rank === props.premiumPlanData.rank && subscriptionData.subscription_plan === props.premiumPlan ?
                            <div className={"current-plan-tag"}>
                                <img src={whiteTick} alt={"current"}/>
                                <span>CURRENT PLAN</span>
                            </div> : null}
                        {subscriptionData.subscription_plan === "premium-on-hold" || (PremiumJSON[subscriptionData.subscription_plan].rank === props.premiumPlanData.rank && subscriptionData.subscription_plan === props.premiumPlan) || !props.premiumPlanData.rank ? null :
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                height: "50px",
                                alignItems: "center"
                            }}>
                                {props.premiumPlan === PREMIUM_PLAN_KEYS.FREE_USER || (subscriptionData.subscription_plan !== props.premiumPlan && subscriptionData.subscription_plan !== PREMIUM_PLAN_KEYS.FREE_USER && subscriptionData.is_canceled) ? null :
                                    <MaterialButton data={{
                                        title: getSubscribeButtonText(),
                                        customButtonContainerStyle: {
                                            width: "200px",
                                            borderRadius: '50px',
                                            backgroundColor: subscriptionData.subscription_plan === props.premiumPlan ? 'transparent' : '#FFFFFF',
                                            boxShadow: subscriptionData.subscription_plan === props.premiumPlan ? "none" : null
                                        },
                                        customButtonTextStyle: {
                                            fontFamily: 'Nunito Sans, Sans-serif, serif',
                                            fontSize: '14px',
                                            textDecoration: subscriptionData.subscription_plan === props.premiumPlan ? "underline" : null,
                                            color: subscriptionData.subscription_plan === props.premiumPlan ? "#4D4D4D" : "#000000"
                                        }
                                    }} handleClick={handleButtonClick}
                                    />
                                }
                            </div>
                        }
                    </div>
                    <div className={"plan-detail-body"} style={{backgroundColor: "#f5f5f5"}}>
                        <div className={"detail-row"}>
                            <h2>{formatBytes(props.premiumPlanData.storage, 0, true, "GB")}</h2>
                            <span>{t(translationKeys.storage)}</span>
                        </div>
                        <div className={"detail-row"}>
                            <h2>{props.premiumPlanData.response_limit.access.toLocaleString()}</h2>
                            <span>{t(translationKeys.response_count)} / {t(translationKeys.life_time)}</span>
                        </div>
                        <div className={"detail-row"}>
                            <div className={"tick-cross-image"}>
                                <img src={props.premiumPlanData.responses_add_ons ? blueTick : cross} alt={"tick/cross"}/>
                            </div>
                            <span>{t(translationKeys.responses_add_available)}</span>
                        </div>
                        <div className={"detail-row"}>
                            <h2>{props.premiumPlanData.question_attachments === Infinity ? t(translationKeys.unlimited) : props.premiumPlanData.question_attachments}</h2>
                            <span>{t(translationKeys.image_attachments)}</span>
                        </div>
                        <div className={"detail-row"}>
                            <h2>{props.premiumPlanData.theme_attachments === Infinity ? t(translationKeys.unlimited) : props.premiumPlanData.theme_attachments}</h2>
                            <span>{t(translationKeys.custom_themes)}</span>
                        </div>
                        <div className={"detail-row"}>
                            <h2>{props.premiumPlanData.file_upload_questions_per_form === Infinity ? t(translationKeys.unlimited) : props.premiumPlanData.file_upload_questions_per_form}</h2>
                            <span>{t(translationKeys.file_upload_questions)}</span>
                        </div>
                        <div className={"detail-row"}>
                            <div className={"tick-cross-image"}>
                                <img src={props.premiumPlanData.logic_jump ? blueTick : cross} alt={"tick/cross"}/>
                            </div>
                            <span>{t(translationKeys.plan_logic_jump)}</span>
                        </div>
                        <div className={"detail-row"}>
                            <div className={"tick-cross-image"}>
                                <img src={props.premiumPlanData.customizable_thank_you ? blueTick : cross}
                                     alt={"tick/cross"}/>
                            </div>
                            <span>{t(translationKeys.plan_custom_thank_you)}</span>
                        </div>
                        <div className={"detail-row"}>
                            <div className={"tick-cross-image"}>
                                <img src={props.premiumPlanData.response_edit ? blueTick : cross} alt={"tick/cross"}/>
                            </div>
                            <span>{t(translationKeys.plan_response_edit)}</span>
                        </div>
                        <div className={"detail-row"}>
                            <div className={"tick-cross-image"}>
                                <img src={props.premiumPlanData.email_support_rank ? blueTick : cross}
                                     alt={"tick/cross"}/>
                            </div>
                            <span>{t(translationKeys.email_support)}</span>
                        </div>
                        <div className={"detail-row"}>
                            <div className={"tick-cross-image"}>
                                <img src={props.premiumPlanData.on_call_support ? blueTick : cross} alt={"tick/cross"}/>
                            </div>
                            <span>{t(translationKeys.call_support)}</span>
                        </div>
                        <div className={"detail-row"}>
                            <div className={"tick-cross-image"}>
                                <img src={props.premiumPlanData.advertising ? cross : blueTick} alt={"tick/cross"}/>
                            </div>
                            <span>{t(translationKeys.plan_remove_ads)}</span>
                        </div>
                        <div className={"detail-row"}>
                            <div className={"tick-cross-image"}>
                                <img src={props.premiumPlanData.remove_branding ? blueTick : cross} alt={"tick/cross"}/>
                            </div>
                            <span>{t(translationKeys.plan_remove_branding)}</span>
                        </div>
                    </div>
                </div>
                {subscriptionData.subscription_plan === "premium-on-hold" || !props.premiumPlanData.rank ? null :
                    <div style={{display: "flex", justifyContent: "center", height: "70px", alignItems: "center"}}>
                        {props.premiumPlan === PREMIUM_PLAN_KEYS.FREE_USER || (subscriptionData.subscription_plan !== props.premiumPlan && subscriptionData.subscription_plan !== PREMIUM_PLAN_KEYS.FREE_USER && subscriptionData.is_canceled) ? null :
                            <MaterialButton data={{
                                title: getSubscribeButtonText(),
                                customButtonContainerStyle: {
                                    width: "300px",
                                    height: "50px",
                                    borderRadius: '50px',
                                    backgroundColor: subscriptionData.subscription_plan === props.premiumPlan ? 'transparent' : '#004BA0',
                                    boxShadow: subscriptionData.subscription_plan === props.premiumPlan ? "none" : null
                                },
                                customButtonTextStyle: {
                                    fontFamily: 'Nunito Sans, Sans-serif, serif',
                                    fontSize: '20px',
                                    textDecoration: subscriptionData.subscription_plan === props.premiumPlan ? "underline" : null,
                                    color: subscriptionData.subscription_plan === props.premiumPlan ? "#4D4D4D" : "#FFFFFF"
                                }
                            }} handleClick={handleButtonClick}
                            />
                        }
                    </div>
                }
            </div>
            {showPayNow ? <PayNowPopUp setShowPayNow={setShowPayNow} subscriptionId={subscriptionId}
                                       yearly={props.yearly}
                                       setShowUpgradePopUp={setShowUpgradePopUp}
                                       setShowLoading={props.setShowLoading}
                                       premiumPlanData={props.premiumPlanData}/> : null}
            {showUpgradedPlanPopUp ? <ModalDialog
                header={`${props.premiumPlanData.rank >= PremiumJSON[subscriptionData.subscription_plan].rank ? t(translationKeys.upgraded_plan).toUpperCase() : t(translationKeys.downgraded_plan).toUpperCase()}`}
                body={[`${props.premiumPlanData.rank >= PremiumJSON[subscriptionData.subscription_plan].rank ? t(translationKeys.upgraded_plan_desc) : t(translationKeys.downgraded_plan_desc)}`]}
                buttons={[
                    {
                        text: t(translationKeys.done).toUpperCase(),
                        action: () => {
                            setShowUpgradedPlanPopUp(false)
                        }
                    }
                ]}
            /> : null}
            {showUpgradePopUp ? <ModalDialog
                header={`${props.premiumPlanData.rank >= PremiumJSON[subscriptionData.subscription_plan].rank ? t(translationKeys.upgrade_plan).toUpperCase() : t(translationKeys.downgrade_plan).toUpperCase()}`}
                body={[`${props.premiumPlanData.rank >= PremiumJSON[subscriptionData.subscription_plan].rank ? t(translationKeys.upgrade_plan_desc1) : t(translationKeys.downgrade_plan_desc1)} ${getPlanBasedOnRank(props.premiumPlanData.rank)}. ${t(translationKeys.downgrade_plan_desc2)}`]}
                buttons={[
                    {
                        text: t(translationKeys.no).toUpperCase(),
                        action: () => {
                            setShowUpgradePopUp(false)
                        }
                    },
                    {
                        text: t(translationKeys.yes).toUpperCase(),
                        action: () => {
                            props.setShowLoading(true)
                            setShowUpgradePopUp(false)
                            upgradeSubscriptionPlan(props.premiumPlan, (res) => {
                                if (res?.result) {
                                    props.setShowLoading(false)
                                    setShowUpgradedPlanPopUp(true)
                                }
                            }, failureFunction)
                        }
                    }
                ]}
            /> : null}
            {showCrossPlatformPopUp ? <ModalDialog header={t(translationKeys.cant_process).toUpperCase()}
                                                   body={[t(translationKeys.you_have_subscribed_through_android)]}
                                                   buttons={[
                                                       {
                                                           text: t(translationKeys.ok).toUpperCase(),
                                                           action: () => {
                                                               setShowCrossPlatformPopUp(false)
                                                           }
                                                       }
                                                   ]}
            /> : null}
        </>
    )
}

export default PremiumPlanDetail
