export const translationsTamil = {
    all: 'அனைத்து',
    ago: "முன்பு",
    now: "இப்போது",
    start: "தொடங்கு",
    account_blocked: "கணக்கு தடுக்கப்பட்டது",
    account_blocked_alert: "எங்கள் விதிமுறைகளையும் நிபந்தனைகளையும் மீறியதற்காக இந்த கணக்கு தடுக்கப்பட்டுள்ளது",
    sign_out: "வெளியேறு",
    short_answer: "குறுகிய பதில்",
    long_answer: "நீண்ட பதில்",
    email: "ஈமெயில்",
    number: "எண்கள்",
    file: "கோப்பு",
    files: "கோப்புகள்",
    faq_videos: "அடிக்கடி கேட்கப்படும் கேள்விகள் வீடியோக்கள்",
    file_upload: "கோப்பு பதிவேற்றம்",
    question_error: "கேள்வியைச் சேர்க்க முடியாது",
    storage: "சேமிப்பு",
    form: "படிவம்",
    quiz: "தேர்வு",
    fup_desc: "உங்கள் படிவங்கள் மற்றும் வினாடி வினாக்களுக்குப் பதிலளிப்பவர்கள் கோப்புகளைப் பதிவேற்ற நீங்கள் இப்போது அனுமதிக்கலாம்!",
    new_fup_feature_title: "புதிய கோப்பு பதிவேற்ற அம்சம்!",
    multiple_choice: "பல தேர்வு",
    date_text: "தேதி",
    time_text: "நேரம்",
    uploading: "பதிவேற்றுகிறது...",
    add_question: "கேள்வியை  சேர்க்கவும்",
    add_question_alert: "குறைந்தபட்சம் ஒரு கேள்வியைச் சேர்க்கவும்",
    add_question_alert_quiz: "வினாடி வினாவை சமர்ப்பிக்கும் முன் தயவுசெய்து ஒரு கேள்வியையாவது சேர்க்கவும்",
    ok: "சரி",
    title: "தலைப்பு",
    title_required: "தலைப்பு தேவைப்படுகிறது",
    form_name_alert: "தயவுசெய்து படிவத்தை(Form) வெளியிடுவதற்கு முன்பு பெயரிடுங்கள்.",
    quiz_name_alert: "வினாடி வினாவை வெளியிடுவதற்கு முன்பு தயவுசெய்து பெயரிடுங்கள்",
    no_connection: "இணைப்பு இல்லை",
    check_connection: "தயவுசெய்து உங்கள் இணைப்பைச் சரிபார்க்கவும்",
    finish_incomplete_question: "படிவத்தில் முழுமையற்ற கேள்விகளை தயவுசெய்து முடிக்கவும்",
    draft: "வரைவு",
    save_to_draft_alert: "இந்த படிவத்தை வரைவாக சேமிக்க விரும்புகிறீர்களா?",
    save_to_draft_quiz_alert: "உங்கள் மாற்றங்களை இழப்பதைத் தவிர்க்க தேர்வை  வரைவாக சேமிக்க விரும்புகிறீர்களா?",
    yes: "ஆம்",
    no: "இல்லை",
    cancel: "ரத்துசெய்",
    live_preview: "நேரடி முன்னோட்டம்",
    themes: "தீம்கள்",
    tap_to_set_theme: "படிவத்திற்கான தீம் அமைக்க இங்கே தொடவும்",
    select_image: "படத்தைத் தேர்ந்தெடுக்கவும்",
    created: "உருவாக்கப்பட்டது",
    form_created_successfully: "படிவம் வெற்றிகரமாக உருவாக்கப்பட்டது",
    warning: "எச்சரிக்கை",
    form_edit_alert: "இந்த படிவம் ஏற்கனவே வெளியிடப்பட்டது மற்றும் பதில்கள் சேகரிக்கப்பட்டிருக்கலாம். படிவத்தைத் திருத்துவது ஏற்கனவே சேகரிக்கப்பட்ட பதில்களை பாதிக்கலாம்.",
    quiz_edit_alert: 'இந்த வினாடி வினா ஏற்கனவே சமர்ப்பிக்கப்பட்டு பதில்கள் சேகரிக்கப்பட்டுள்ளன. வினாடி வினாவைத் திருத்துவது முந்தைய பதில்களைப் பாதிக்கலாம்',
    edit: "திருத்து",
    download_as: "பதிவிறக்கவும்",
    delete_form: "படிவத்தை நீக்கு",
    delete_form_alert: "இந்த படிவத்தை நீக்க விரும்புகிறீர்களா?",
    delete_quizzes_alert: "இந்த வினாடி வினாக்களை நீக்க விரும்புகிறீர்களா? இந்த செயலைச் செயல்தவிர்க்க முடியாது.",
    delete_forms_alert: "இந்த படிவங்களை நீக்க விரும்புகிறீர்களா?",
    delete_quiz_alert: "இந்த வினாடி வினாவை நீக்க விரும்புகிறீர்களா? இந்த செயலைச் செயல்தவிர்க்க முடியாது.",
    delete_answers_alert: "இந்த பதில்களை நீக்க வேண்டுமா? இந்தச் செயலைச் செயல்தவிர்க்க முடியாது.",
    delete_answer_alert: "இந்த பதிலை நீக்க வேண்டுமா? இந்தச் செயலைச் செயல்தவிர்க்க முடியாது.",
    delete: "நீக்கு",
    delete_all: "அனைத்தையும் நீக்கு",
    section: "பகுதி",
    worst: "மோசமானது",
    server_error_alert: "சிறிது நேரம் கழித்து மீண்டும் முயற்சிக்கவும் !!",
    signed_in_as: "உள்நுழைகிறது",
    signing_out: "வெளியேறுகிறது...",
    create_form: "படிவத்தை உருவாக்கு",
    create_blank_form: "வெற்று படிவத்தை உருவாக்கு",
    create_blank_quiz: "வெற்று வினாடி வினாவை உருவாக்கு",
    close: "மறைக்கவும்",
    response_collection_exit_alert: "பதிலைச் சேகரிப்பதில் இருந்து வெளியேற விரும்புகிறீர்களா?",
    form_builder: "ஆய்வுகள்/கணக்கெடுப்பு/படிவங்கள்",
    form_builder_promo: "உங்கள் ஆய்வுகளுக்கான உடனடி முன்னோட்டம் மற்றும் தீம்கள்",
    results: "முடிவுகள்",
    result_feature_promo: "உங்கள் கணக்கெடுப்பு முடிவுகளை பகுப்பாய்வு செய்து பதிவிறக்கம் செய்யுங்கள்",
    notification: "அறிவிப்புகள்",
    delete_option_alert: "இந்த ஆப்ஷனை நீக்க விரும்புகிறீர்களா?",
    question_type: "கேள்வி வகைகள்",
    change_question_type_alert: "கேள்வி வகையை மாற்ற இங்கே தொடவும்",
    preview_not_available: "முன்னோட்டம் கிடைக்கவில்லை",
    delete_question: "கேள்வியை நீக்கு",
    delete_response_collected_question_alert: "இந்த கேள்வியை நீக்குவது நிச்சயமாக சேகரிக்கப்பட்ட பதில்களை பாதிக்கும்.",
    delete_question_alert: "நிச்சயமாக, இந்த கேள்வியை நீக்க விரும்புகிறீர்களா?",
    options: "ஆப்ஷன்ஸ்",
    option: 'ஆப்ஷன்',
    no_answer: "பதில் இல்லை",
    no_response: "பதில்கள் இல்லை",
    responses: "பதில்கள்",
    response: "பதில்",
    serial_number: "வ.எண்",
    submit_time: "சமர்ப்பிக்கப்பட்ட நேரம்",
    general_form_type_description: "புதிய படிவம் / கணக்கெடுப்பை உருவாக்கவும்",
    quiz_type_description: "புதிய தேர்வை உருவாக்கவும்",
    sheet_disconnected: 'Google தாள் துண்டிக்கப்பட்டது',
    sheet_not_connected: 'தாள் இணைக்கப்படவில்லை',
    sheet_not_connected_desc: 'Google தாளை இணைக்க உரிமையாளரைத் தொடர்பு கொள்ளவும்.',
    export_success: "வெற்றிகரமாக பதிவிறக்கம் செய்யப்பட்டது",
    answer: "பதில்",
    answer_with_colan: "பதில்:",
    others: "மற்றவைகள்",
    delete_response: "பதிலை நீக்கு",
    deleted: "நீக்கப்பட்டது",
    my_themes: "எனது தீம்கள்",
    limit_reached: "வரம்பை எட்டியது",
    limit_reached_description: "படம் பதிவேற்றிய வரம்பை அடைந்துவிட்டீர்கள்.",
    modified: "திருத்தப்பட்ட நாள்",
    delete_image: "படத்தை நீக்கு",
    delete_image_alert: "இந்த படத்தை நீக்க விரும்புகிறீர்களா?",
    delete_theme_image_desc: "இந்தப் படத்தை நீக்க வேண்டுமா? நீங்கள் தொடர்ந்தால், இந்தப் படத்தைப் பயன்படுத்தும் எந்தப் படிவங்களுக்கும் அல்லது வினாடி வினாக்களுக்கும் இந்தப் படம் கிடைக்காது. இந்தச் செயலைச் செயல்தவிர்க்க முடியாது.",
    section_title: "பகுதியின் தலைப்பு",
    section_description: "பகுதியின் விளக்கம்",
    show_scale: 'அளவைக் காட்டு',
    show_options: "ஆப்ஷன்களை  காட்டு",
    options_required: "ஆப்ஷன்கள் தேவை",
    link_copied: "இணைப்பு நகலெடுக்கப்பட்டது",
    app_not_found: "காணப்படவில்லை",
    default_text: "பொதுவான தீம்",
    dark_mode: "டார்க் தீம்",
    done: "சரி",
    upload_image: "படத்தை பதிவேற்றம் செய்யவும்",
    question: "கேள்வி",
    required: "தேவை",
    share: "பகிர்",
    form_link: "படிவத்தின் இணைப்பு",
    copy_link: "இணைப்பு நகல்",
    view_form: "படிவத்தைக் காண்க",
    menu: "பட்டியல்",
    view_all: "அனைத்து",
    settings: "அமைப்பு",
    submit: "சமர்ப்பிக்கவும்",
    change_theme: "தீம் மாற்ற",
    tap_here_to_choose_theme: "தீம் தேர்வு செய்ய இங்கே தொடவும்",
    show_logo: "லோகோவைக் காட்டு",
    show_logo_alert: "லோகோவை படிவத்தில் காட்ட விரும்புகிறீர்களா?",
    welcome_page: "வரவேற்பு பக்கம்",
    show_welcome_page_alert: "வரவேற்பு பக்கத்தைக் காட்ட விரும்புகிறீர்களா?",
    start_button: "தொடங்கும் பட்டன்",
    allow_multiple_responses: "பல பதில்களை அனுமதிக்கவும்",
    allow_more_response_alert: "பயனருக்கு ஒன்றுக்கு மேற்பட்ட முறை பதிலளிக்க அனுமதிக்க விரும்புகிறீர்களா?",
    allow_more_quiz_attempt_alert: "பயனரை ஒன்றுக்கு மேற்பட்ட முறை முயற்சிக்க அனுமதிக்க விரும்புகிறீர்களா?",
    show_summary: "பதில்களின் சுருக்கம்",
    show_results: "முடிவுகளை காட்டு",
    allow_responders_view_summary_alert: "சமர்ப்பித்தபின் அனைத்து பதில்களின் சுருக்கத்தையும் காண பதிலளிப்பவர்களை அனுமதிக்க விரும்புகிறீர்களா?",
    allow_result_view_quiz_alert: "முடிவுகளைக் காண பயனரை அனுமதிக்க விரும்புகிறீர்களா?",
    show_question_number: "கேள்வி எண்ணைக் காட்டு",
    show_question_number_in_form: "படிவத்தில் கேள்வி எண்களைக் காட்ட விரும்புகிறீர்களா?",
    show_question_number_in_quiz: "வினாடி வினாவில் கேள்வி எண்களைக் காட்ட விரும்புகிறீர்களா?",
    shuffle_questions: "கேள்விகளை கலக்கு",
    shuffle_questions_alert: "படிவ கேள்விகளை கலக்கு விரும்புகிறீர்களா?",
    shuffle_quiz_questions_alert: "தேர்வு கேள்விகளை மாற்ற விரும்புகிறீர்களா?",
    description: "விளக்கம்",
    builder: "பில்டர்",
    preview: "முற்காட்சி",
    form_status: "படிவ நிலை",
    active: "செயலில் உள்ளது",
    inactive: "செயலற்றது",
    import_questions: 'கேள்விகளை இறக்குமதி செய்யவும்',
    search_by_question_title_from_forms: 'ஏற்கனவே உள்ள படிவங்களிலிருந்து கேள்வித் தலைப்பின் அடிப்படையில் தேடவும்',
    search_by_question_title_from_quizzes: 'ஏற்கனவே உள்ள வினாடி வினாக்களில் இருந்து கேள்வி தலைப்பு மூலம் தேடவும்',
    view_responses: "பதில்களைக் காண்க",
    edit_form: "படிவத்தைத் திருத்து",
    duplicate_form: "படிவத்தை நகலெடு",
    drafts: "வரைவுகள்",
    no_form_available: "படிவம் எதுவும் இல்லை!",
    notifications: "அறிவிப்புகள்",
    feedback: "கருத்தளிப்பு",
    privacy_policy: "தனியுரிமைக் கொள்கை",
    open: 'திறக்கவும்',
    open_individual_responses: "தனிப்பட்ட பதில்களைத் திறக்கவும்",
    bar_chart: "பார் விளக்கப்படம்",
    pie_chart: "பை விளக்கப்படம்",
    summary: "சுருக்கம்",
    invalidated_form_cannot_be_selected: "செல்லாத படிவத்தைத் தேர்ந்தெடுக்க முடியாது.",
    invalidated_quiz_cannot_be_selected: "செல்லாத வினாடி வினாவைத் தேர்ந்தெடுக்க முடியாது.",
    individual: "தனிப்பட்ட",
    search: "தேடு…",
    image: "படம்",
    video: "வீடியோ",
    website: "இணையதளம்",
    exit: "வெளியேறு",
    response_limit_reached: "பதில்கள் வரம்பை அடைந்தது",
    response_limit_reached_alert: "இந்த படிவம் பதில் தரவுக்கான அனுமதிக்கப்பட்ட வரம்பை மீறிவிட்டது; எனவே, இந்த படிவத்தை மேலும் பதில்களை ஏற்றுக்கொள்வதைத் தடுத்தோம்.\n\nதற்போதுள்ள கணக்கெடுப்பை நீங்கள் தொடர வேண்டுமானால், அதை நகலெடுத்து இணைப்பைப் பகிருமாறு நாங்கள் பரிந்துரைக்கிறோம்.",
    unapproved_form: "அங்கீகரிக்கப்படாத படிவம்",
    unapproved_form_alert: "இந்த படிவம் எங்கள் கொள்கைகளை மீறும் சொற்களைப் பயன்படுத்துகிறது.\n\nஇந்த படிவம் பிழையாக தடுக்கப்பட்டுள்ளது என்று நீங்கள் நம்பினால், தயவுசெய்து கீழே உள்ள படிவ இணைப்பைத் திறந்து படிவத்தில் கோரப்பட்ட தகவல்களை வழங்கவும்.",
    choose_your_language: "உங்கள் மொழியைத் தேர்வுசெய்க",
    provide_explanation: "விளக்கம் வழங்கவும்",
    selected: "தேர்ந்தெடுக்கப்பட்டது",
    review_button: "மதிப்பீடு",
    not_specified: "குறிப்பிட வில்லை",
    quiz_control: "தேர்வுக் கட்டுப்பாடு",
    allow_multiple_attempts: "பல முயற்சிகளை அனுமதிக்கவும்",
    user_info: "பயனர் தகவல்",
    name_s: "பெயர்",
    duration: "காலம்",
    set_duration_quiz_alert: "தேர்வுக்கு ஒரு கால அளவை அமைக்க விரும்புகிறீர்களா?",
    user_information_description: "பங்கேற்பாளர் தகவலைப் பெற விரும்புகிறீர்களா?",
    set_duration: "கால அளவை அமைக்கவும் (மணி: நிமிடங்கள்)",
    minutes: "நிமிடங்கள்",
    hours: "மணி",
    minute: "நிமிடம்",
    hour: "மணி",
    correct: "சரி",
    wrong: "தவறு",
    skipped: "தவிர்த்தது",
    score: "ஸ்கோர்",
    forms: "படிவங்கள்",
    quizzes: "தேர்வுகள்",
    more: "மேலும்",
    quiz_status: "தேர்வு நிலை",
    view_answers: "பதில்களைக் காண்க",
    view_quiz: "வினாக்களை காண்க",
    duplicate_quiz: "தேர்வை நகல் செய்",
    no_quiz_available: "தேர்வு இல்லை!",
    quiz_link: "தேர்வின் இணைப்பு",
    unapproved_quiz: "அங்கீகரிக்கப்படாத தேர்வு",
    unapproved_quiz_alert: "இந்த வினாடி வினா எங்கள் கொள்கைகளை மீறக்கூடிய வார்த்தைகளைப் பயன்படுத்துகிறது. இந்த வினாடி வினா தவறுதலாக தடுக்கப்பட்டதாக நீங்கள் நம்பினால், கீழே உள்ள படிவ இணைப்பைத் திறந்து, படிவத்தில் கோரப்பட்ட தகவலை வழங்கவும்.",
    do_you_want_to_show_all_correct_answers_to_users: "பயனர்களுக்கு சரியான பதிலைக் காட்ட விரும்புகிறீர்களா?",
    results_publish_date: "முடிவுகள் வெளியிடும் தேதி",
    disconnect: 'துண்டிக்கவும்',
    do_you_want_to_set_date_to_publish_results: "முடிவுகளை வெளியிட தேதி அமைக்க விரும்புகிறீர்களா?",
    quiz_result: "தேர்வு முடிவு",
    enter_answer: "பதில்",
    manage: "நிர்வகிக்க",
    manage_storage: 'சேமிப்பகத்தை நிர்வகிக்கவும்',
    select_all: 'அனைத்தையும் தெரிவுசெய்',
    shuffle_options: 'ஷஃபிள் விருப்பங்கள்',
    shuffle_options_enabled: 'ஷஃபிள் விருப்பங்கள் இயக்கப்பட்டன',
    shuffle_options_disabled: 'ஷஃபிள் விருப்பங்கள் முடக்கப்பட்டன',
    add_options_to_enable_this_feature: 'இந்த அம்சத்தை இயக்க விருப்பங்களைச் சேர்க்கவும்',
    attach: 'இணைக்கவும்',
    shuffle: 'கலக்கு',
    marks: "மதிப்பெண்கள்",
    mark: "குறி",
    correction_status: "நிலை:",
    status_validated: "சரிபார்க்கப்பட்டது",
    pending: "நிலுவையில் உள்ளது",
    results_publish: "வெளியிடு",
    publish: "வெளியிடு",
    publish_description: "தேர்வு முடிவுகளை வெளியிட விரும்புகிறீர்களா?",
    publish_description_note: "(மதிப்பெண்கள் ஏற்கனவே பகிரப்பட்ட  URL இல் வெளியிடப்படும்)",
    published_successfully: "வெற்றிகரமாக வெளியிடப்பட்டது",
    choice_type_validation_note: "*சரியான விடை தேர்வு தானாக சரிபார்க்கப்பட்டது",
    short_answer_type_validation_note: "*குறுகிய பதில் தானாக சரிபார்க்கப்பட்டது",
    long_answer_type_validation_note: "*நீண்ட பதில் தானாக சரிபார்க்கப்படவில்லை",
    fileupload_answer_type_validation_note: "*கோப்பு பதிவேற்றம் தானாகவே சரிபார்க்கப்படவில்லை",
    dropdown_answer_type_validation_note: "*கீழ்தோன்றும் தேர்வு தானாகவே சரிபார்க்கப்பட்டது",
    save: "சேமி",
    questions: "கேள்விகள்",
    show_correct_answer: "சரியான பதிலைக் காட்டு",
    show_answer: "பதிலைக் காட்டு",
    hide_answer: "பதிலை மறை",
    no_marks: "மார்க் இல்லை",
    question_is_required: "கேள்வி தலைப்பு தேவை",
    marks_is_required: "மதிப்பெண்கள் தேவை",
    options_are_required: "விருப்பங்கள் தேவை",
    options_limit_reached: 'விருப்பங்கள் வரம்பை அடைந்தது',
    options_limit_reached_description: 'ஒரு கேள்விக்கு அனுமதிக்கப்பட்ட விருப்பங்களின் வரம்பை அடைந்துவிட்டீர்கள்.',
    correct_answer_required: "சரியான பதில் தேவை",
    enter_valid_mark: "சரியான அடையாளத்தை உள்ளிடவும்",
    evaluated: "மதிப்பீடு செய்யப்பட்டது",
    saved: "சேமிக்கப்பட்டது",
    pending_evaluation: "மதிப்பீடு நிலுவையில் உள்ளது",
    alphabet: "எழுத்துக்கள்",
    not_evaluated: "மதிப்பீடு செய்யப்படவில்லை",
    percentage_ascending: "சதவீதம் - ஏறுதல்",
    percentage_descending: "சதவீதம் - இறங்கு",
    pending_evaluation_alert_message: "வெளியிடுவதற்கு முன் அனைத்து பதில்களின் மதிப்பீட்டையும் நீங்கள் முடிக்க வேண்டும்.",
    show_unevaluated: "மதிப்பிடப்படாததைக் காட்டு",
    publish_success_alert_message: "பதிலளிப்பவர்கள் அவற்றின் முடிவுகளை அவற்றின் தொடர்புடைய விடைத்தாள்களில் காணலாம்.",
    publishing: "வெளியிடுகிறது",
    quiz_published: "தேர்வு வெளியிடப்பட்டது",
    quiz_published_alert_before_edit_message: "இந்த தேர்வு வெளியிடப்பட்டது, நீங்கள் மாற்றங்களைச் செய்ய வேண்டுமானால், நீங்கள் வினாடி வினாவை நகலெடுத்து மீண்டும் பகிர வேண்டும்.",
    quiz_has_answers: "தேர்வில் பதில்கள் உள்ளன",
    quiz_has_answer_alert_message: "இந்த தேர்வை திருத்த, நீங்கள் இருக்கும் எல்லா பதில்களையும் நீக்க வேண்டும்.",
    unavailable_features_description: 'இந்தப் படிவத்தில் இணைய பயன்பாட்டில் இன்னும் ஆதரிக்கப்படாத அம்சங்கள் உள்ளன',
    unavailable_quiz_features_description: 'இந்த வினாடி வினாவில் இணைய பயன்பாட்டில் இன்னும் ஆதரிக்கப்படாத அம்சங்கள் உள்ளன',
    unavailable: 'கிடைக்கவில்லை',
    unpublished: "வெளியிடப்படாதது",
    published: "வெளியிடப்பட்டது",
    setting_updated: "அமைத்தல் புதுப்பிக்கப்பட்டது",
    account: "கணக்கு",
    on: "இயக்கப்பட்டது",
    off: "முடக்கப்பட்டுள்ளது",
    scan_qr_code: "QR குறியீட்டை ஸ்கேன் செய்யுங்கள்",
    answers: "பதில்கள்",
    answer_s: "பதில்",
    question_limit_reached: "கேள்வி வரம்பு அடைந்தது",
    reached_limit_for_number_of_questions: "ஒரு படிவத்தில் அனுமதிக்கப்பட்ட கேள்விகளின் வரம்பை நீங்கள் அடைந்துவிட்டீர்கள். எனவே, புதிய கேள்விகளைச் சேர்க்க முடியாது",
    life_time: "வாழ்நாள் முழுவதும்",
    last_year: "சென்ற வருடம்",
    last_30_days: "கடந்த 30 நாட்கள்",
    last_7_days: "கடந்த 7 நாட்கள்",
    last_24_hours: "கடைசி 24 மணி நேரம்",
    custom_date: "தனிப்பயன் தேதி",
    filter_by_date: "தேதியின்படி வடிகட்டவும்",
    filter_by_question: "கேள்வி மூலம் வடிகட்டவும்",
    filter_text: "உரையை வடிகட்டவும்",
    add_filter: "வடிகட்டியைச் சேர்க்கவும்",
    apply: "சமர்ப்பிக்கவும்",
    from_date: "தேதியிலிருந்து",
    today: 'இன்று',
    to_date: "இன்றுவரை",
    contains: "கொண்டுள்ளது",
    equal_to: "சமமாக",
    not_equal_to: "சமமாக இல்லை",
    from_date_required: "தேதி தேவை",
    to_date_required: "தேதி தேவை",
    webapp_promo_text: "உங்கள் படிவங்கள், ஆய்வுகள், வாக்கெடுப்புகள் மற்றும் வினாடி வினாக்களை ஆன்லைனில் அணுகவும்!",
    question_title: "கேள்வி",
    invalid_date: "தவறான தேதி",
    invalid_url: "தவறான வலைதள முகவரி",
    invalid_url_description: "URL சரியானதாகத் தெரியவில்லை.",
    delete_response_alert: "நிச்சயமாக, இந்த பதிலை நீக்க விரும்புகிறீர்களா?",
    delete_responses_alert: "இந்த பதில்களை நீக்க விரும்புகிறீர்களா? இந்தச் செயலைச் செயல்தவிர்க்க முடியாது.",
    overview: "கண்ணோட்டம்",
    no_results_found: "முடிவுகள் எதுவும் இல்லை",
    date_format: "dd-mm-yyyy",
    title_dashboard: "டாஷ்போர்டு",
    title_notifications: "அறிவிப்புகள்",
    select_one_response: "நீக்குவதற்கு குறைந்தது ஒரு பதிலையாவது தேர்ந்தெடுக்கவும்",

//Untranslated strings

    your_account: "உங்கள் கணக்கு",
    app_name: "SurveyHeart",
    quizzory: "Quizzory",
    powered_by_surveyheart: "Powered by 'SurveyHeart'",
    feature_spotlight: 'அம்சம் ஸ்பாட்லைட்',
    favourites: "பிடித்தவை",
    shared: "பகிரப்பட்டது",
    hide: "மறை",
    use_templates: "டெம்ப்ளேட்களைப் பயன்படுத்தவும்",
    quick_settings: "விரைவு அமைப்புகள்",
    leave_collaboration: "ஒத்துழைப்பை விடுங்கள்",
    templates: "டெம்ப்ளேட்கள்",
    collapse: "சுருக்கு",
    start_scale_from_zero: '0 இலிருந்து தொடக்க அளவை',
    center_label_optional: 'மைய லேபிள் (விரும்பினால்)',
    left_label_optional: 'இடது லேபிள் (விரும்பினால்)',
    right_label_optional: 'வலது லேபிள் (விரும்பினால்)',
    duplicate: "நகல்",
    row: "வரிசை",
    column: "நெடுவரிசை",
    rows: "வரிசைகள்",
    add_column: 'நெடுவரிசையைச் சேர்க்கவும்',
    add_row: 'வரிசையைச் சேர்க்கவும்',
    user_info_for_file_upload: "கோப்பு பதிவேற்றங்களுக்கான பயனர் தகவல்",
    user_info_for_file_upload_desc1: "நீங்கள் கோப்பு பதிவேற்ற கேள்வியைச் சேர்த்துள்ளதால், பயனர் தகவலைச் சேகரிப்பதற்கான விருப்பம் இயக்கப்பட்டுள்ளது. இது பதிவிறக்கம் செய்யப்பட்ட கோப்புகளை எளிதாக அடையாளம் காண உதவும். பதிலளிப்பவர்கள் தங்கள் தகவலை உள்ளிட ஒரு உரையாடலைப் பார்ப்பார்கள்.",
    user_info_for_file_upload_desc2: "உரையாடல் காட்டப்படுவதை நீங்கள் விரும்பவில்லை என்றால், அமைப்புகளில் இந்த விருப்பத்தை மாற்றலாம்.",
    show_rows_and_columns: "வரிசைகளைக் காட்டு & நெடுவரிசைகள்",
    scale_down: 'அளவைக் குறைக்கவும்',
    scale_up: 'அளவிடவும்',
    dislike: 'வெறுப்பு',
    like: 'பிடிக்கும்',
    best: 'சிறந்த',
    remove_favourite: "பிடித்ததை அகற்று",
    add_favourite: "பிடித்ததைச் சேர்க்கவும்",
    collaborator: "கூட்டுப்பணியாளர்",
    collaborators: "கூட்டுப்பணியாளர்கள்",
    show_more: "மேலும் காட்ட",
    create_quiz: "வினாடி வினாவை உருவாக்கவும்",
    collaborate: "ஒத்துழைக்க",
    share_QR_code: "QR குறியீட்டைப் பகிரவும்",
    add_image: "படத்தைச் சேர்க்கவும்",
    invalid_file_type: "தவறான கோப்பு வகை",
    drag_drop: "இழு போடு",
    here: "இங்கே",
    choose: "தேர்வு செய்யவும்",
    add_account: 'கணக்கு சேர்க்க',
    choose_account: 'கணக்கைத் தேர்ந்தெடுக்கவும்',
    maximum_allowed_file_size: "அனுமதிக்கப்பட்ட அதிகபட்ச கோப்பு அளவு",
    or: "அல்லது",
    next: "அடுத்தது",
    change_image: "படத்தை மாற்றவும்",
    upload: "பதிவேற்றவும்",
    view: "காண்க",
    collaboration: "இணைந்து",
    share_collaboration_form: "இந்தப் படிவத்தை மற்றவர்களுடன் பகிர விரும்புகிறீர்களா?",
    share_collaboration_quiz: "இந்த வினாடி வினாவை மற்றவர்களுடன் பகிர விரும்புகிறீர்களா?",
    multiple_responses_summary: "பல பதில்கள், சுருக்கம்",
    question_number_shuffle: "கேள்வி எண், கேள்வி குறி, கலக்கு",
    general: "பொது",
    browse: "உலாவவும்",
    owner: "உரிமையாளர்",
    something_went_wrong: "ஏதோ தவறு நடைபெற்றிருக்கிறது!",
    forget_your_password: "உங்கள் கடவுச்சொல்லை மறந்து விடுங்கள்?",
    sign_in: "உள்நுழைக",
    sign_up: "பதிவு செய்யவும்",
    using_email: "மின்னஞ்சல் பயன்படுத்தி",
    dont_have_account: "கணக்கு இல்லை?",
    on_signing_up_agree_to_surveyhearts: "பதிவு செய்வதன் மூலம், SurveyHeart's ஒப்புக்கொள்கிறீர்கள்", //
    and: "மற்றும்",
    terms_of_use: "பயன்பாட்டு விதிமுறைகளை",
    already_have_an_account: "ஏற்கனவே ஒரு கணக்கு உள்ளதா?",
    signed_in_previously_as_a_google_user: "நீங்கள் முன்பு Google பயனராக உள்நுழைந்துள்ளீர்கள்.",
    sign_in_google_to_continue: "தொடர Google மூலம் உள்நுழையவும்.",
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: "குறிப்பு: இந்தத் தகவலை மீண்டும் பார்க்க, இடது பக்கத்தில் உள்ள மெனுவில் 'மேலும்' என்பதைக் கிளிக் செய்யவும்",
    no_account_found_email_address: "இந்த மின்னஞ்சல் முகவரியுடன் கணக்கு எதுவும் இல்லை",
    account_already_exist_email: "இந்த மின்னஞ்சலுக்கு ஏற்கனவே கணக்கு உள்ளது.",
    install_surveyheart_app: "SurveyHeart பயன்பாட்டை நிறுவவும்",
    easy_login_via_app: "ஆப் மூலம் எளிதான உள்நுழைவு",
    get_notification_instantly: "உடனடியாக அறிவிப்பைப் பெறுங்கள்",
    create_form_quiz_on_the_go: "பயணத்தின்போது படிவம்/வினாடி வினாவை உருவாக்கவும்", //
    check_your_inbox_to_activate_your_account: "உங்கள் கணக்கைச் செயல்படுத்த உங்கள் இன்பாக்ஸைச் சரிபார்க்கவும்",
    select_questions: "கேள்விகளைத் தேர்ந்தெடுக்கவும்",
    percentage: "சதவிதம்",
    free_App_to_create_forms: "படிவங்களை உருவாக்க இலவச பயன்பாட்டைப் பெறவும்",
    we_cant_find_the_page_that_you_are_looking_for: "நீங்கள் தேடும் பக்கத்தை எங்களால் கண்டுபிடிக்க முடியவில்லை",
    answered: "பதிலளித்தார்",
    completing_this_quiz: "இந்த வினாடி வினாவை முடித்ததற்கு நன்றி",
    connected_successfully: 'வெற்றிகரமாக இணைக்கப்பட்டது',
    seconds: "நொடிகள்",
    enter_your_details: "உங்கள் விவரங்களை உள்ளிடவும்",
    enter_all_required_information: "தேவையான அனைத்து தகவல்களையும் உள்ளிடவும்",
    answer_data_not_found: "பதில் தரவு கிடைக்கவில்லை",
    results_published_soon: "முடிவுகள் விரைவில் வெளியிடப்படும்",
    previous: 'முந்தைய',
    save_link_for_future_reference: "எதிர்கால குறிப்புக்காக இந்த இணைப்பைச் சேமிக்கவும்",
    choice_questions_only: "தேர்வு கேள்விகள் மட்டுமே",
    score_is_not_final: "குறிப்பு: இந்த மதிப்பெண் இறுதியானது அல்ல",
    tabular: "அட்டவணை",
    text: "உரை",
    answer_response_alert: "இந்தக் கேள்விக்கு பதில் தேவை",
    invalid_email_address: "தவறான மின்னஞ்சல் முகவரி",
    answer_not_available: "பதில் கிடைக்கவில்லை",
    validated_automatically: "* தானாக சரிபார்க்கப்பட்டது",
    answer_link: "பதில் இணைப்பு",
    embed_code: 'உட்பொதி குறியீடு',
    copy_code: 'குறியீட்டை நகலெடுக்கவும்',
    code_copied: 'குறியீடு நகலெடுக்கப்பட்டது',
    share_via: "மூலம் பகிரவும்",
    print: "அச்சிடுக",
    columns: 'நெடுவரிசைகள்',
    columns_all: "நெடுவரிசைகள் (அனைத்தும்)",
    submitted_time: "நேரம் சமர்ப்பிக்கவும்",
    unable_to_submit: 'சமர்ப்பிக்க முடியவில்லை',
    unable_to_submit_alert_line1: 'இந்த வினாடி வினா விடைகளைப் பெற்றுள்ளதால், மாற்றங்களைச் செய்ய உங்களுக்கு வரம்புக்குட்பட்ட அணுகல் உள்ளது.',
    unable_to_submit_alert_line2: 'இந்த வினாடி வினாவைத் திருத்துவதற்கான முழு அணுகலைப் பெற, ஏற்கனவே உள்ள எல்லா பதில்களையும் நீக்க வேண்டும்.',
    action_blocked: 'செயல் தடுக்கப்பட்டது',
    action_blocked_alert: 'இந்த வினாடி வினாவிற்கு ஏற்கனவே பதில்கள் இருப்பதால் செயல் தடுக்கப்பட்டது. இதை அனுமதிப்பது முந்தைய பதில்களை பாதிக்கலாம்.',
    quiz_edit_submit_alert: 'வினாடி வினாவைத் திருத்துவது மதிப்பெண்கள், சதவீதம் ஆகியவற்றைப் பாதிக்கலாம் மற்றும் சில கேள்விகளை மறுமதிப்பீடு செய்ய வேண்டியிருக்கும்.',
    continue: 'தொடரவும்',
    submitted: "சமர்ப்பிக்கப்பட்டது",
    submitting: "சமர்ப்பிக்கிறது",
    filter: "வடிகட்டி",
    exit_screen: "திரையிலிருந்து வெளியேறு",
    full_screen: "முழு திரை",
    tabular_view: "அட்டவணை பார்வை",
    clear_all: "அனைத்தையும் அழி",
    you_are_all_caught_up: "நீங்கள் அனைத்தையும் படித்துவிட்டீர்கள்!",
    check_back_later_for_new_notifications: "புதிய அறிவிப்புகளுக்கு பிறகு பார்க்கவும்.",
    requires_answer: "பதில் தேவை",
    enter_member_id: "உறுப்பினர் ஐடியை உள்ளிடவும்",
    viewers_can_read_form_responses: "பார்வையாளர்கள் படிவத்தைப் படித்து பதில்களைப் பார்க்கலாம்",
    editors_edit_form_view_responses: "தொகுப்பாளர்கள் படிவத்தைத் திருத்தலாம் மற்றும் பதில்களைப் பார்க்கலாம்",
    admin_edit_form_add_collaborator: "நிர்வாகி படிவத்தைத் திருத்தலாம் மற்றும் கூட்டுப்பணியாளரைச் சேர்க்கலாம்",
    invalid_member_id: "தவறான உறுப்பினர் ஐடி",
    already_member_added: "ஏற்கனவே சேர்க்கப்பட்டது",
    add_collaborator: "கூட்டுப்பணியாளரைச் சேர்க்க",
    admin: "நிர்வாகம்",
    editor: "ஆசிரியர்",
    viewer: "பார்வையாளர்",
    searched_form_not_found: "தேடிய படிவம் கிடைக்கவில்லை!",
    searched_quiz_not_found: "தேடிய வினாடி வினா கிடைக்கவில்லை!",
    no_shared_data_available: "பகிரப்பட்ட தரவு எதுவும் கிடைக்கவில்லை!",
    forms_server_is_not_available: "படிவ சேவையகம் கிடைக்கவில்லை",
    quiz_server_is_not_available: "வினாடி வினா சேவையகம் கிடைக்கவில்லை",
    leave: 'விடு',
    password: 'கடவுச்சொல்',
    new_password: 'புதிய கடவுச்சொல்',
    retype_password: 'கடவுச்சொல்லை மீண்டும் தட்டச்சு செய்யவும்',
    create_password: 'கடவுச்சொல்லை உருவாக்கு',
    view_results: 'முடிவுகளைக் காண்க',
    control: 'கட்டுப்பாடு',
    q_control: 'வினாடி வினா கட்டுப்பாடு',
    q_result: 'வினாடி வினா முடிவு',
    no_matches_found: "பொருத்தங்கள் எதுவும் இல்லை",
    dropdown: "டராப்டவுன்", //
    checkboxes: "தேர்வுப்பெட்டிகள் ",
    star_rating: 'நட்சத்திர மதிப்பீடு',
    linear_scale: 'நேரியல் அளவுகோல்',
    multiple_choice_grid: 'பல தேர்வு கட்டம்',
    checkboxes_grid: 'தேர்வுப்பெட்டி கட்டம்',
    smile: 'புன்னகை',
    info_1: "தகவல் 1",
    info_2: "தகவல் 2",
    info_3: "தகவல் 3",
    new: 'புதிய',
    download_all: 'அனைத்தையும் பதிவிறக்கவும்',
    download: 'பதிவிறக்க ',
    sorting: 'வரிசைப்படுத்துதல்',
    sort_by: 'வரிசைப்படுத்து',
    refresh: 'புதுப்பிப்பு',
    add: 'கூட்டு',
    refresh_preview_description: "முன்னோட்டத்தைப் புதுப்பிக்க, 'புதுப்பித்தல்' பொத்தானைக் கிளிக் செய்யவும்",
    enter_users_email_address: 'மின் அஞ்சல் உள்ளிடு',
    do_you_want_to_delete_these: 'இவற்றை நீக்க வேண்டுமா',
    do_you_want_to_delete_this_form: 'இந்தப் படிவத்தை நீக்க விரும்புகிறீர்களா',
    all_responses_will_also_be_deleted: 'அனைத்து பதில்களும் நீக்கப்படும்',
    this_action_cannot_be_undone: 'இந்தச் செயலைச் செயல்தவிர்க்க முடியாது.',
    do_you_want_to_delete_this_quiz: 'இந்த வினாடி வினாவை நீக்க விரும்புகிறீர்களா',
    all_answer_sheets_will_also_be_unavailable: 'அனைத்து விடைத்தாள்களும் கிடைக்காது.',
    change: 'மாற்றம்',
    roles_rights: 'பாத்திரங்கள் மற்றும் உரிமைகள்',
    learn_more: 'மேலும் அறிக',
    viewers_can_read_quiz_and_view_answers: 'பார்வையாளர்கள் வினாடி வினாவைப் படித்து பதில்களைப் பார்க்கலாம்',
    editors_can_edit_quiz_view_answers: 'தொகுப்பாளர்கள் வினாடி வினாவைத் திருத்தலாம் மற்றும் பதில்களைப் பார்க்கலாம்',
    admin_can_edit_quiz_add_collaborator: 'நிர்வாகி வினாடி வினாவைத் திருத்தலாம் மற்றும் கூட்டுப்பணியாளரைச் சேர்க்கலாம்',
    change_roles: 'பாத்திரங்களை மாற்றவும்',
    remove: 'அகற்று',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: 'உங்கள் மாற்றங்களைச் சமர்ப்பிக்கவில்லை. சமர்ப்பிக்காமல் வெளியேறுவது சரியா?',
    select_language: 'மொழியை தேர்ந்தெடுங்கள்',
    are_you_sure_you_want_delete_this_collaborator: 'இந்தக் கூட்டுப்பணியாளரை நிச்சயமாக நீக்க விரும்புகிறீர்களா?',
    are_you_sure_you_want_to_delete_selected_files: 'தேர்ந்தெடுத்த கோப்பை நிரந்தரமாக நீக்க விரும்புகிறீர்களா?',
    please_download_before_deleting: 'நீக்குவதற்கு முன் பதிவிறக்கவும். ஒருமுறை நீக்கப்பட்ட கோப்புகளை மீட்டெடுக்க முடியாது!',
    role_has_been_changed_successfully: 'பாத்திரம் வெற்றிகரமாக மாற்றப்பட்டது',
    do_you_want_to_leave_collaboration: 'ஒத்துழைப்பிலிருந்து விலக விரும்புகிறீர்களா?',
    form_builder_access_denied: 'படிவம் பில்டர் அணுகல் மறுக்கப்பட்டது',
    quiz_builder_access_denied: 'வினாடி வினா பில்டர் அணுகல் மறுக்கப்பட்டது',
    you_only_have_viewer_rights_for_quiz: 'இந்த வினாடி வினாவிற்கு உங்களுக்கு பார்வையாளர் உரிமைகள் மட்டுமே உள்ளன.',
    you_only_have_viewer_rights_for_form: 'இந்தப் படிவத்திற்கான பார்வையாளர் உரிமைகள் மட்டுமே உங்களிடம் உள்ளன.',
    kindly_contact_the_form_owner_for_more_info: 'மேலும் தகவலுக்கு படிவ உரிமையாளரைத் தொடர்பு கொள்ளவும்.',
    kindly_contact_the_quiz_owner_for_more_info: 'மேலும் தகவலுக்கு வினாடி வினா உரிமையாளரைத் தொடர்பு கொள்ளவும்.',
    incomplete_questions: 'முழுமையற்ற கேள்விகள்:',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'உங்களிடம் சேமிக்கப்படாத மாற்றங்கள் உள்ளன - நிச்சயமாக இந்தப் பக்கத்தை விட்டு வெளியேற விரும்புகிறீர்களா?',
    languages: 'மொழி',
    copy: "நகல்",
    not_good: 'நன்றாக இல்லை',
    not_allowed: 'அனுமதி இல்லை',
    not_allowed_description: 'பதில்களைச் சேகரித்த பிறகு தேர்வு கேள்வி வகையை மாற்ற முடியாது.',
    neutral: 'நடுநிலை',
    good: 'நல்ல',
    very_good: 'மிகவும் நல்லது',
    edit_rights_removed: 'திருத்த உரிமைகள் அகற்றப்பட்டன',
    your_rights_to_edit_this_form_have_been_removed: "இந்தப் படிவத்தைத் திருத்துவதற்கான உங்கள் உரிமைகள் அகற்றப்பட்டன.",
    your_rights_to_edit_this_quiz_have_been_removed: "இந்த வினாடி வினாவைத் திருத்துவதற்கான உங்கள் உரிமைகள் அகற்றப்பட்டன.",
    please_contact_quiz_owner_for_more_info: "மேலும் தகவலுக்கு படிவ உரிமையாளரைத் தொடர்பு கொள்ளவும்.",
    please_contact_form_owner_for_more_info: "மேலும் தகவலுக்கு வினாடி வினா உரிமையாளரைத் தொடர்பு கொள்ளவும்.",
    last_edited_on: 'கடைசியாக திருத்தப்பட்டது',
    last_edited: 'கடைசியாக திருத்தப்பட்டது',
    by: 'மூலம்',
    you: "நீங்கள்",
    add_option: 'ஆப்ஷனை சேர்க்கவும்',
    add_other_option: "'பிற' விருப்பத்தைச் சேர்க்கவும்",
    estimated_time: "கணிக்கப்பட்ட நேரம்",
    do_you_want_to_show_estimated_time_taking_quiz: "வினாடி வினா எடுப்பதற்கான மதிப்பிடப்பட்ட நேரத்தைக் காட்ட விரும்புகிறீர்களா ?",
    do_you_want_to_show_estimated_time_taking_form: "படிவத்தை எடுப்பதற்கான மதிப்பிடப்பட்ட நேரத்தைக் காட்ட விரும்புகிறீர்களா ?",
    show_question_count: "கேள்வி எண்ணிக்கை காட்டு",
    do_you_want_to_show_total_number_questions_quiz: "வினாடி வினாவில் மொத்த கேள்விகளின் எண்ணிக்கை காட்ட விரும்புகிறீர்களா ?",
    do_you_want_to_show_total_number_questions_form: "படிவத்தில் மொத்தக் கேள்விகளைக் காட்ட விரும்புகிறீர்களா?",
    show_question_marks: "மதிப்பெண்களை காட்டு",
    show_question_marks_quiz: "வினாடி வினாவில் மதிப்பெண்களை காட்டு",
    you_dont_have_add_collaborator_permission: "கூட்டுப்பணியாளரைச் சேர்ப்பதற்கான அனுமதி உங்களிடம் இல்லை",
    modified_date: "திருத்தப்பட்ட தேதி",
    created_date: "உருவாக்கப்பட்ட தேதி",
    question_cant_be_added: "கேள்வியைச் சேர்க்க முடியாது",
    another_file_upload_question_cant_added: "மற்றொரு கோப்பு பதிவேற்றக் கேள்வியைச் சேர்க்க முடியாது",
    you_can_have_only: "உங்களால் ",
    file_upload_questions_in_form: "கோப்பு பதிவேற்றம் மட்டுமே சேர்க்கப்படலாம்",
    file_upload_questions_in_quiz: "கோப்பு பதிவேற்றம் மட்டுமே சேர்க்கப்படலாம்",
    storage_capacity_exceeded: "சேமிப்பகத் திறன் அதிகமாகிவிட்டது",
    kindly_inform_form_builder_that: "தயவுசெய்து படிவத்தை உருவாக்குபவருக்கு தெரிவிக்கவும்",
    kindly_inform_quiz_builder_that: "என்பதை வினாடி வினா அமைப்பாளருக்கு தயவு செய்து தெரிவிக்கவும்",
    their_storage_capacity_file_uploads: "கோப்பு பதிவேற்றங்களுக்கான அவற்றின் சேமிப்பக திறன்",
    has_been_exceeded: "மீறப்பட்டுள்ளது",
    view_details: "விபரங்களை பார்",
    paste_your_video_link_here: "உங்கள் வீடியோ இணைப்பை இங்கே பதிவு செய்யவும்",
    paste_your_website_link_here: "உங்கள் இணையதள இணைப்பை இங்கே பதிவு செய்யவும்",
    view_answer_sheet: "விடைத்தாள் பார்க்கவும்",
    response_count: "பதில் எண்ணிக்கை",
    count: "எண்ணிக்கை",
    password_least_8_characters: "கடவுச்சொல் குறைந்தது 8 - 50 எழுத்துகளாக இருக்க வேண்டும்",
    password_contain_least_1_number: "கடவுச்சொல்லில் குறைந்தது 1 எண்ணாவது இருக்க வேண்டும்",
    password_contain_least_1_capital_letter: "கடவுச்சொல்லில் குறைந்தது 1 பெரிய எழுத்தாவது இருக்க வேண்டும்",
    password_contain_least_1_special_character: "கடவுச்சொல்லில் குறைந்தது 1 சிறப்பு எழுத்து இருக்க வேண்டும்",
    please_fill_all_required_fields: "தேவையான அனைத்து புலங்களையும் நிரப்பவும்",
    passwords_dont_match: "கடவுச்சொற்கள் பொருந்தவில்லை",
    incorrect_password: "தவறான கடவுச்சொல்",
    click_or_change_current_theme: "வேறு தீம் ஒன்றைத் தேர்வுசெய்ய, தற்போதைய தீம் அல்லது கீழே உள்ள 'மாற்று' என்பதைக் கிளிக் செய்யவும்",
    select: "தேர்ந்தெடு",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "இந்தப் படத்தை நீக்க வேண்டுமா? நீங்கள் தொடர்ந்தால், இந்தப் படத்தைப் பயன்படுத்தும் எந்தப் படிவங்களுக்கும் அல்லது வினாடி வினாக்களுக்கும் இந்தப் படம் கிடைக்காது. இந்தச் செயலைச் செயல்தவிர்க்க முடியாது.",
    url_does_not_seem_to_valid: "URL சரியானதாகத் தெரியவில்லை.",
    you_have_reached_image_uploaded_limit: "படம் பதிவேற்றிய வரம்பை அடைந்துவிட்டீர்கள்.",
    undo: "செயல்தவிர்",
    response_deleted: "பதில் நீக்கப்பட்டது",
    question_deleted: "கேள்வி நீக்கப்பட்டது",
    answer_deleted: "பதில் நீக்கப்பட்டது",
    responses_deleted: "பதில்கள் நீக்கப்பட்டன",
    questions_deleted: "கேள்விகள் நீக்கப்பட்டன",
    answers_deleted: "பதில்கள் நீக்கப்பட்டன",
    collect_email_header: "மின்னஞ்சலைச் சேகரிக்கவும்",
    collect_email_alert_message: "இந்த அம்சத்தை இயக்குவது, படிவத்தை நிரப்ப பதிலளிப்பவரின் ஜிமெயில் முகவரியுடன் உள்நுழைய கட்டாயப்படுத்தும்.",
    collect_email_alert_note: "குறிப்பு : Google கணக்கு மட்டுமே ஆதரிக்கப்படுகிறது",
    allow_response_edit: "பதிலைத் திருத்த அனுமதி",
    allow_response_edit_description: "பதிலளிப்பவர்களை சமர்ப்பித்த பிறகு பதில்களைத் திருத்த அனுமதிக்க விரும்புகிறீர்களா?",
    enter_desc_here: "விளக்கத்தை இங்கே உள்ளிடவும்",
    passcode_protection: "கடவுக்குறியீடு பாதுகாப்பு",
    passcode_protection_description_quiz: "உங்கள் வினாடி வினா கடவுக்குறியீடு பாதுகாக்கப்பட வேண்டுமா?",
    passcode_protection_description: "உங்கள் படிவம் கடவுக்குறியீடு பாதுகாக்கப்பட வேண்டுமா?",
    crop_image: "படத்தை வெட்டு",
    crop: "பயிர்",
    original: "அசல்",
    collect_email: "மின்னஞ்சல் முகவரியை சேகரிக்கவும்",
    collect_email_description: "சாத்தியமான எதிர்கால குறிப்பு அல்லது பயன்பாட்டிற்காக பதிலளித்தவர்களின் மின்னஞ்சல் முகவரிகளை சேகரிக்க விரும்புகிறீர்களா?",
    opening_date: 'திறக்கும் தேதி',
    collect_email_description_quiz: "இந்த அம்சத்தை இயக்குவது வினாடி வினாவை நிரப்ப பதிலளிப்பவர்கள் தங்கள் ஜிமெயில் முகவரியுடன் உள்நுழைய கட்டாயப்படுத்தும்.",
    collect_email_description_form: "இந்த அம்சத்தை இயக்குவது, படிவத்தை நிரப்ப பதிலளிப்பவர்கள் தங்கள் ஜிமெயில் முகவரியுடன் உள்நுழைய கட்டாயப்படுத்தும்.",
    closing_date: 'கடைசி நாள்',
    schedule_form_status: 'அட்டவணை படிவத்தின் நிலை',
    schedule_quiz_status: 'அட்டவணை வினாடி வினா நிலை',
    schedule_opening_date_and_time: "திட்டமிடல் திறக்கும் தேதி மற்றும் நேரம்",
    schedule_closing_date_and_time: "இறுதி தேதி மற்றும் நேரத்தை திட்டமிடுங்கள்",
    schedule_opening_date_and_time_description: "குறிப்பிட்ட தேதி மற்றும் நேரத்தில் படிவத்தை செயல்படுத்தவும்",
    schedule_opening_date_and_time_description_quiz: "குறிப்பிடப்பட்ட தேதி மற்றும் நேரத்தில் வினாடி வினாவை செயல்படுத்தவும்",
    schedule_closing_date_and_time_description: "குறிப்பிட்ட தேதி மற்றும் நேரத்தில் படிவத்தை செயலிழக்கச் செய்யவும்",
    schedule_closing_date_and_time_description_quiz: "குறிப்பிடப்பட்ட தேதி மற்றும் நேரத்தில் வினாடி வினாவை செயலிழக்கச் செய்யவும்",
    upgrade: "மேம்படுத்தல்",
    gold_plan: "தங்க திட்டம்",
    view_plans: "திட்டங்களைக் காண்க",
    premium_plans: "பிரீமியம் திட்டங்கள்",
    plan_duration: "/ மாதம்",
    plan_logic_jump: "லாஜிக் ஜம்ப்",
    plan_remove_ads: "விளம்பரங்களை அகற்று",
    cancel_subscription: "சந்தாவை ரத்து செய்",
    quiz_has_answer_edit_message: "இந்த வினாடி வினா ஏற்கனவே சமர்ப்பிக்கப்பட்டு பதில்கள் சேகரிக்கப்பட்டுள்ளன. வினாடி வினாவைத் திருத்துவது முந்தைய பதில்களைப் பாதிக்கலாம்.",
    quiz_published_alert_edit_message: "இந்த வினாடி வினா வெளியிடப்பட்டது, நீங்கள் திருத்தினால் இந்த வினாடி வினா வெளியிடப்படாது.",
    quiz_submit_after_edit_message: "வினாடி வினாவைத் திருத்துவது மதிப்பெண்கள், சதவீதத்தைப் பாதிக்கலாம் மற்றும் சில கேள்விகளை மறுமதிப்பீடு செய்ய வேண்டியிருக்கும்.",
    title_activity_privacy_policy: "தனியுரிமைக் கொள்கை செயல்பாடு",
    action_blocked_message: "இந்த வினாடி வினாவிற்கு ஏற்கனவே உள்ள பதில்களின் காரணமாக செயல் தடுக்கப்பட்டது. இதை அனுமதிப்பது முந்தைய பதில்களை பாதிக்கலாம்.",
    email_addresses: "மின்னஞ்சல் முகவரிகள்",
    confirmation_message: "உறுதிப்படுத்தல் செய்தி",
    confirmation_message_description: "படிவத்தைச் சமர்ப்பித்த பிறகு உறுதிப்படுத்தல் செய்தியைக் காட்ட விரும்புகிறீர்களா?",
    confirmation_message_description_quiz: "வினாடி வினாவுக்குப் பதிலளித்த பிறகு உறுதிப்படுத்தல் செய்தியைக் காட்ட விரும்புகிறீர்களா?",
    default_custom_thank_message: "இந்த படிவத்தை பூர்த்தி செய்ததற்கு நன்றி.",
    default_custom_thank_message_quiz: "இந்த வினாடி வினாவை முடித்ததற்கு நன்றி.",
    upgrade_your_plan: "உங்கள் திட்டத்தை மேம்படுத்தவும்!",
    upgrade_your_plan_1: "உங்கள் திட்டத்தை மேம்படுத்தவும்",
    upgrade_your_plan_content: "இந்த அம்சத்தை அணுகவும், சேமிப்பக அளவை அதிகரிக்கவும், பிரீமியம் பதிப்பை மேம்படுத்தவும்",
    alert_icon: "எச்சரிக்கை ஐகான்",
    usage_details: "பயன்பாட்டு விவரங்கள்",
    current_plan: "தற்போதைய திட்டம்",
    total_submission: "மொத்த சமர்ப்பிப்பு",
    image_attachments: "பட இணைப்புகள்",
    custom_themes: "தனிப்பயன் தீம்கள்",
    free_limit_reached: "நீங்கள் இலவச வரம்பை அடைந்துவிட்டீர்கள் -",
    payment_done_but_server_error: "உங்கள் கட்டணம் வெற்றிகரமாகக் கழிக்கப்பட்டது. உங்கள் கட்டணத்தைச் சரிபார்க்க எங்களை அனுமதிக்க சிறிது நேரம் கழித்து பயன்பாட்டைத் திறக்கவும்.",
    response_quota_reached: "நீங்கள் இலவச வரம்பை அடைந்துவிட்டீர்கள், அனைத்து பதில்களையும் அணுக உங்கள் திட்டத்தை மேம்படுத்தவும்.",
    answers_quota_reached: "நீங்கள் இலவச வரம்பை அடைந்துவிட்டீர்கள், எல்லா பதில்களையும் அணுக உங்கள் திட்டத்தை மேம்படுத்தவும்.",
    logic_jump_enabled: "லாஜிக் ஜம்ப் இயக்கப்பட்டது.",
    logic_jump_disabled: "லாஜிக் ஜம்ப் முடக்கப்பட்டுள்ளது.",
    cancel_subscription_message: "உங்கள் சந்தாவை நிச்சயமாக ரத்துசெய்ய விரும்புகிறீர்களா? சந்தாவை ரத்துசெய்வதால் அனைத்து பிரீமியம் அம்சங்களுக்கான அணுகலை இழக்க நேரிடும்.",
    plan_expired: "உங்கள் திட்டம் காலாவதியாகிவிட்டது. இந்த வரைவைப் பயன்படுத்த உங்கள் திட்டத்தை மேம்படுத்தவும்.",
    plan_expired_edit_form: "உங்கள் திட்டம் காலாவதியாகிவிட்டது. இந்தப் படிவத்தைத் திருத்த உங்கள் திட்டத்தை மேம்படுத்தவும்",
    plan_expired_edit_quiz: "உங்கள் திட்டம் காலாவதியாகிவிட்டது. இந்த வினாடி வினாவைத் திருத்த உங்கள் திட்டத்தை மேம்படுத்தவும்",
    plan_expired_duplicate_form: "உங்கள் திட்டம் காலாவதியாகிவிட்டது. இந்தப் படிவத்தை நகலெடுக்க உங்கள் திட்டத்தை மேம்படுத்தவும்",
    plan_expired_duplicate_quiz: "உங்கள் திட்டம் காலாவதியாகிவிட்டது. இந்த வினாடி வினாவை நகலெடுக்க உங்கள் திட்டத்தை மேம்படுத்தவும்",
    premium_features: "பிரீமியம் அம்சம்",
    plan_file_upload: "வரம்பற்ற தனிப்பயன் தீம் படங்கள்",
    plan_custom_theme: "வரம்பற்ற தனிப்பயன் தீம் படங்கள்",
    plan_call_support: "அதிக முன்னுரிமை - அழைப்பு ஆதரவு",
    plan_email_support: "உயர் முன்னுரிமை மின்னஞ்சல் ஆதரவு",
    plan_custom_domain: "தனிப்பயன் டொமைன்",
    subscribe: "பதிவு",
    plan_remove_branding: "பிராண்டிங்கை அகற்று",
    plan_on_call_support: "அழைப்பு ஆதரவில்",
    plan_image_attachment: "வரம்பற்ற படங்கள் இணைப்புகள்",
    plan_custom_thank_you: "தனிப்பயனாக்கக்கூடிய நன்றி பக்கங்கள்",
    plan_allowed_responses: "அனுமதிக்கப்பட்ட பதில் எண்ணிக்கை 50000",
    trusted_by_number_of_user: "5 மில்லியனுக்கும் அதிகமான பயனர்களால் நம்பப்படுகிறது",
    email_support: "மின்னஞ்சல் ஆதரவு",
    call_support: "அழைப்பு ஆதரவு",
    keyboard_shortcuts: "விசைப்பலகை குறுக்குவழிகள்",
    premium_limit_reached: "திட்ட வரம்பை அடைந்துவிட்டீர்கள். அனைத்து பிரீமியம் அம்சங்களையும் அணுக உங்கள் திட்டத்தை மேம்படுத்தவும்.",
    premium_limit_reached_mobile: "திட்ட வரம்பை அடைந்துவிட்டீர்கள்.",
    unlimited: "வரம்பற்ற",
    expired_on_next_renewal: "இது உங்கள் அடுத்த புதுப்பித்தல் தேதியில் காலாவதியாகிவிடும்",
    upgrade_plan: "மேம்படுத்தல் திட்டம்",
    downgrade_plan: "தரமிறக்க திட்டம்",
    file_upload_questions: "கோப்பு பதிவேற்ற கேள்விகள்",
    plan_response_edit: "பதில் திருத்து",
    cant_process: "செயலாக்க முடியாது",
    you_have_subscribed_through_android: "எங்களின் ஆண்ட்ராய்டு ஆப்ஸ் மூலம் நீங்கள் குழுசேர்ந்துள்ளீர்கள். பணம் செலுத்துதல் தொடர்பான அறிவிப்புகளுக்கு SurveyHeart பயன்பாட்டைப் பயன்படுத்தவும்.",
    pay_now: "இப்போது செலுத்த",
    update_payment_details: "கட்டண விவரங்களைப் புதுப்பிக்கவும்",
    limit_reached_alert_message: "இலவச வரம்பை அடைந்துவிட்டீர்கள், அனைத்து பதில்களையும் அணுக உங்கள் திட்டத்தை மேம்படுத்தவும்.",
    limit_reached_alert_message_quiz: "இலவச வரம்பை அடைந்துவிட்டீர்கள், எல்லா பதில்களையும் அணுக உங்கள் திட்டத்தை மேம்படுத்தவும்.",
    limit_reached_UI_message: "உங்களின் தற்போதைய திட்டத்திற்கான பதில்கள் கிடைக்காது, எல்லா பதில்களையும் காண உங்கள் திட்டத்தை மேம்படுத்தவும்.",
    limit_reached_UI_message_quiz: "உங்களின் தற்போதைய திட்டத்திற்கான பதில்கள் கிடைக்காது, எல்லா பதில்களையும் காண உங்கள் திட்டத்தை மேம்படுத்தவும்.",
    owner_is_not_premium_user: "உரிமையாளர் பிரீமியம் பயனர் அல்ல",
    cancel_subscription_description: "உங்கள் சந்தாவை நிச்சயமாக ரத்துசெய்ய விரும்புகிறீர்களா? ரத்துசெய்வதால் அனைத்து பிரீமியம் அம்சங்களுக்கான அணுகலை இழக்க நேரிடும்.",
    starter_storage_description: "சிரமமில்லாத தரவு மேலாண்மைக்கு 5GB சேமிப்பிடத்தைப் பெறுங்கள்.",
    bronze_storage_description: "சிரமமில்லாத தரவு மேலாண்மைக்கு 25GB சேமிப்பிடத்தைப் பெறுங்கள்.",
    silver_storage_description: "சிரமமில்லாத தரவு நிர்வாகத்திற்கு 50GB சேமிப்பிடத்தைப் பெறுங்கள்.",
    gold_storage_description: "சிரமமில்லாத தரவு நிர்வாகத்திற்கு 150GB சேமிப்பிடத்தைப் பெறுங்கள்.",
    starter_responses_description: "சக்திவாய்ந்த கணக்கெடுப்பு நுண்ணறிவுகளைப் பெற 10,000 பதில்களைப் பயன்படுத்தவும்.",
    bronze_responses_description: "சக்திவாய்ந்த கணக்கெடுப்பு நுண்ணறிவுகளைப் பெற 35,000 பதில்களைப் பயன்படுத்தவும்.",
    silver_responses_description: "சக்திவாய்ந்த கருத்துக்கணிப்பு நுண்ணறிவுகளைப் பெற 75,000 பதில்களைப் பயன்படுத்தவும்.",
    gold_responses_description: "சக்திவாய்ந்த கணக்கெடுப்பு நுண்ணறிவுகளைப் பெற 1,50,000 பதில்களைப் பயன்படுத்தவும்.",
    starter_image_description: "காட்சி முறையீட்டிற்காக 100 படங்கள் வரை உங்கள் ஆய்வுகளை மேம்படுத்தவும்.",
    bronze_image_description: "காட்சி முறையீட்டிற்காக 200 படங்கள் வரை உங்கள் ஆய்வுகளை மேம்படுத்தவும்.",
    silver_image_description: "காட்சி முறையீட்டிற்காக 500 படங்கள் வரை உங்கள் கருத்துக்கணிப்புகளை மேம்படுத்தவும்.",
    gold_image_description: "காட்சி முறையீட்டிற்காக வரம்பற்ற படங்களுடன் உங்கள் ஆய்வுகளை மேம்படுத்தவும்.",
    starter_theme_description: "உங்கள் பிராண்டிற்கான உங்கள் கருத்துக்கணிப்புகளைத் தனிப்பயனாக்க 100 தனிப்பயன் படங்களைச் சேர்க்கவும்.",
    bronze_theme_description: "உங்கள் பிராண்டிற்கான உங்கள் கருத்துக்கணிப்புகளைத் தனிப்பயனாக்க 200 தனிப்பயன் படங்களைச் சேர்க்கவும்.",
    silver_theme_description: "உங்கள் பிராண்டிற்கான உங்கள் கருத்துக்கணிப்புகளைத் தனிப்பயனாக்க 500 தனிப்பயன் படங்களைச் சேர்க்கவும்.",
    gold_theme_description: "உங்கள் பிராண்டிற்கான உங்கள் கருத்துக்கணிப்புகளைத் தனிப்பயனாக்க வரம்பற்ற தனிப்பயன் படங்களைச் சேர்க்கவும்.",
    custom_theme_image: "தனிப்பயன் தீம் படம்",
    starter_upload_description: "4 கோப்பு பதிவேற்ற கேள்விகளை தடையின்றி ஒருங்கிணைக்கவும்",
    file_upload_description: "வரம்பற்ற கோப்பு பதிவேற்ற கேள்விகளை தடையின்றி ஒருங்கிணைக்கவும்.",
    logic_jump_description: "ஒவ்வொரு பதிலளிப்பவரின் அனுபவத்திற்கும் கருத்துக்கணிப்பு ஓட்டத்தைத் தனிப்பயனாக்க லாஜிக் ஜம்ப்களைப் பயன்படுத்தவும்",
    thank_you_message_description: "பதிலளிப்பவர்களை ஈர்க்க நன்றி பக்கங்களைத் தனிப்பயனாக்குங்கள்.",
    response_edit_description: "Response Edit வசதியைப் பயன்படுத்தி பதில்களை எளிதாக செம்மைப்படுத்தலாம்.",
    remove_branding_description: "SurveyHeart பிராண்டிங்கை அகற்றுவதன் மூலம் உங்கள் பிராண்ட் இருப்பை உயர்த்தவும்.",
    bronze_remove_ads_description: "எங்கள் வெண்கலத் திட்டத்துடன் விளம்பரம் இல்லாமல் சென்று உங்கள் கருத்துக்கணிப்புகளில் கவனம் செலுத்துங்கள்.",
    silver_remove_ads_description: "எங்கள் சில்வர் திட்டத்துடன் விளம்பரம் இல்லாமல் சென்று உங்கள் கருத்துக்கணிப்புகளில் கவனம் செலுத்துங்கள்.",
    gold_remove_ads_description: "எங்கள் தங்கத் திட்டத்துடன் விளம்பரம் இல்லாமல் சென்று உங்கள் கருத்துக்கணிப்புகளில் கவனம் செலுத்துங்கள்.",
    bronze_email_support_description: "குறைந்த முன்னுரிமைக்கான மின்னஞ்சல் ஆதரவை அணுகவும், சரியான நேரத்தில் உதவியை உறுதி செய்யவும்.",
    silver_email_support_description: "நடுத்தர முன்னுரிமைக்கான மின்னஞ்சல் ஆதரவை அணுகவும், சரியான நேரத்தில் உதவியை உறுதி செய்யவும்.",
    gold_email_support_description: "உயர் முன்னுரிமைக்கான மின்னஞ்சல் ஆதரவை அணுகவும், சரியான நேரத்தில் உதவியை உறுதி செய்யவும்.",
    bronze_email_support_title: "குறைந்த முன்னுரிமை மின்னஞ்சல் ஆதரவு",
    silver_email_support_title: "நடுத்தர முன்னுரிமை மின்னஞ்சல் ஆதரவு",
    gold_email_support_title: "அதிக முன்னுரிமை மின்னஞ்சல் ஆதரவு",
    update_your_payment_details_mobile: "உங்கள் கட்டண விவரங்களைப் புதுப்பிக்கவும்.",
    update_your_payment_details_pc: "இந்தக் கணக்கைப் புதுப்பிப்பதில் சில சிக்கல்களை எதிர்கொள்கிறோம். உங்கள் கட்டண விவரங்களைப் புதுப்பிக்கவும்.",
    card_details_updated_successfully: "அட்டை விவரங்கள் வெற்றிகரமாக புதுப்பிக்கப்பட்டன",
    card_details_updated_successfully_description: "உங்கள் கார்டு விவரங்கள் வெற்றிகரமாக புதுப்பிக்கப்பட்டன. இப்போது உங்கள் புதுப்பிக்கப்பட்ட கட்டணத் தகவலைப் பயன்படுத்துவதைத் தொடரலாம்.",
    payment_successful: "பணம் செலுத்துதல் வெற்றிகரமானது",
    payment_successful_description: "உங்கள் கட்டணம் வெற்றிகரமாக உள்ளது. நீங்கள் இப்போது SurveyHeart இன் பிரீமியம் திட்டத்திற்கு குழுசேர்ந்துள்ளீர்கள். வசதியாக இருங்கள் மற்றும் உங்கள் வழியில் வரும் அனைத்து அற்புதமான அம்சங்களையும் அனுபவிக்கவும்.",
    pay_failed: "பணம் செலுத்த முடியவில்லை!",
    payment_failed_description: "துரதிர்ஷ்டவசமாக. SurveyHeart இன் பிரீமியம் திட்டத்திற்கான உங்கள் சந்தாவை எங்களால் செயல்படுத்த முடியவில்லை. மீண்டும் முயற்சிக்கவும்.",
    note_for_five_rupees: "குறிப்பு: உங்கள் கார்டு விவரங்களைப் புதுப்பிக்க ரூ. 5 கட்டணம் வசூலிக்கப்படுகிறது, அது \n அதே கணக்கில் 3 முதல் 4 வணிக நாட்களுக்குள் திரும்பப் பெறப்படும்.",
    logic: "லாஜிக்",
    re_generate_code: "குறியீட்டை மீண்டும் உருவாக்கு",
    plan_change_restrictions: "திட்ட மாற்ற கட்டுப்பாடுகள்",
    plan_change_restriction_desc_1: "UPI ஐப் பயன்படுத்தி உங்கள் திட்டத்தை மாற்ற முடியாது.",
    plan_change_restriction_desc_2: "மேம்படுத்தல்கள் மற்றும் தரமிறக்குதல்களுக்கு கார்டுக்கு மாறவும் அல்லது நீங்கள் விரும்பிய திட்டத்தை ரத்துசெய்து மீண்டும் குழுசேரவும்.",
    update_card_details: "அட்டை விவரங்களைப் புதுப்பிக்கவும்",
    features_and_plans: "அம்சங்கள் மற்றும் திட்டங்கள்",
    cancel_plan: "திட்டத்தை ரத்து செய்",
    cancel_plan_desc: "நீங்கள் திட்டத்தை ரத்து செய்துவிட்டீர்கள். உங்கள் புதுப்பித்தல் தேதி வரை கட்டண அம்சங்களுக்கான அணுகல் உங்களுக்கு இருக்கும்.",
    explore_text: "இன்றே எங்களின் பிரீமியம் சந்தா திட்டங்களில் விதிவிலக்கான அம்சங்களை ஆராய்ந்து திறக்கவும்",
    cant_edit_this_form: "இந்தப் படிவத்தைத் திருத்த முடியாது",
    cant_edit_this_quiz: "இந்த வினாடி வினாவைத் திருத்த முடியாது",
    cant_duplicate_this_form: "இந்தப் படிவத்தை நகலெடுக்க முடியாது",
    cant_duplicate_this_quiz: "இந்த வினாடி வினாவை நகலெடுக்க முடியாது",
    kindly_upgrade_the_premium: "இந்த அம்சத்தை அணுக பிரீமியம் பதிப்பை மேம்படுத்தவும்.",
    check_box: 'செக் பாக்ஸ்',
    continue_to_next_question: "அடுத்த கேள்விக்கு தொடரவும்",
    add_logic: "லாஜிக்கை சேர்",
    submit_form: "படிவம் சமர்ப்பிக்கவும்",
    save_logic: "லாஜிக்கை சேமி",
    remove_logic: "தர்க்கத்தை அகற்று",
    responses_add_ons: "பதில்கள் துணை நிரல்",
    responses_add_available: "பதில்களுக்கான துணை நிரல் கிடைக்கிறது",
    additional_submissions: " கூடுதல் சமர்ப்பிப்புகள்",
    validity: " செல்லுபடியாகும்",
    up_to_your_current_plan: " உங்கள் தற்போதைய திட்டம் வரை",
    responses_add_ons_description: " உங்கள் தற்போதைய திட்டத்தில் இந்த பதில் எண்ணிக்கை சேர்க்கப்படும்.",
    redirect_url_title: " விரும்பிய இணைப்பிற்கு திருப்பி விடவும்",
    redirect_url_description: " உங்கள் பயனர்களை வெளிப்புற வலைப்பக்கத்திற்கு திருப்பிவிட விரும்புகிறீர்களா?",
    enter_weblink: " இணைய இணைப்பை உள்ளிடவும்",
    get: "கிடைக்கும்",
    jump_to: "தாவி",
    action_cannot_be_performed: "செயலைச் செய்ய முடியாது",
    upgrade_plan_desc1: "உங்கள் திட்டத்தை மேம்படுத்த விரும்புகிறீர்களா",
    downgrade_plan_desc1: "உங்கள் திட்டத்தை தரமிறக்க விரும்புகிறீர்களா",
    downgrade_plan_desc2: "இந்த மாற்றம் உங்களின் அடுத்த புதுப்பித்தல் தேதியில் அமலுக்கு வரும்.",
    upgraded_plan: "மேம்படுத்தப்பட்ட திட்டம்",
    downgraded_plan: "தரமிறக்கப்பட்ட திட்டம்",
    upgraded_plan_desc: "உங்கள் திட்டத்தை மேம்படுத்தியுள்ளீர்கள். இந்த மாற்றம் உங்களின் அடுத்த புதுப்பித்தல் தேதியில் அமலுக்கு வரும்.",
    downgraded_plan_desc: "உங்கள் திட்டத்தைத் தரமிறக்கிவிட்டீர்கள். இந்த மாற்றம் உங்களின் அடுத்த புதுப்பித்தல் தேதியில் அமலுக்கு வரும்.",
    buy_responses: "பதில்களை வாங்கவும்",
    passcode: "கடவுக்குறியீடு",
    share_link_with_passcode: "கடவுக்குறியீட்டுடன் இணைப்பைப் பகிரவும்",
    contact_us: "எங்களை தொடர்பு கொள்ளவும்",
    premium_limit_reached_for_gold: "திட்ட வரம்பை அடைந்துவிட்டீர்கள். அனைத்து பிரீமியம் அம்சங்களையும் அணுக எங்களைத் தொடர்பு கொள்ளவும்.",
    follow_us: "எங்களைப் பின்தொடரவும்",
    billing_history: "பில்லிங் வரலாறு",
    your_current_plan: "உங்கள் தற்போதைய திட்டம்",
    next_billing_date: "அடுத்த பில்லிங் தேதி",
    transaction_id: "பரிவர்த்தனை ஐடி",
    plan: "திட்டம்",
    price: "விலை",
    status: "நிலை",
    follow_us_desc: "புதுப்பித்த நிலையில் இருங்கள், இணைந்திருங்கள் மற்றும் எங்களுடன் ஈடுபடுங்கள்",
    ranking_choice: 'தரவரிசை தேர்வு',
    ranking: 'தரவரிசை',
    picture_choice: 'படத் தேர்வு',
    agreement: 'ஒப்பந்தம்',
    signature: "கையெழுத்து",
    slider: 'ஸ்லைடர்',
    min: 'குறைந்தபட்சம்',
    max: 'அதிகபட்சம்',
    step_value: "படி மதிப்பு",
    scale_range_error: "அளவீடு வரம்பு -10,000 மற்றும் 10,000 க்கு இடையில் இருக்க வேண்டும்.",
    scale_range_error2: "அதிகபட்ச அளவின் மதிப்பு ஏற்றுக்கொள்ளக்கூடிய மதிப்பை விட குறைவாக உள்ளது.",
    max_min_error: "குறைந்தபட்ச அளவின் மதிப்பு அதிகபட்ச அளவை விட குறைவாக இருக்க வேண்டும்.",
    step_error: "படி அளவு இடையே ஒரு மதிப்பாக இருக்க வேண்டும்",
    add_picture: 'படத்தைச் சேர்க்கவும்',
    terms_and_conditions: "விதிமுறைகள் & நிபந்தனைகள்",
    terms_and_conditions_required: "விதிமுறைகள் மற்றும் நிபந்தனைகள் தேவை",
    i_agree: "நான் ஒப்புக்கொள்கிறேன்",
    i_disagree: "நான் உடன்படவில்லை",
    yearly: "ஆண்டுதோறும்",
    monthly: "மாதாந்திர",
    save_upto_15: "வருடாந்திர திட்டங்களுடன் 15% வரை சேமிக்கவும்",
    paid: "செலுத்தப்பட்டது",
    cancelled: "ரத்து செய்யப்பட்டது",
}